<script>
export default {
  name: "MessageBox",


}
</script>

<template>
  <div class="flex-box message-box">
    <slot></slot>
  </div>
</template>


<style scoped>
.message-box {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  /* font-weight: bold; */
  font-size: small;
  width: 100%;
  gap: 10px;
  border: solid 1px var(--border-color);
}

.message-box .icon {
  font-size: xx-large;
}
</style>