<script>
import TheNotification from '../Notification/TheNotificationModal.vue'
import Logo from './Logo.vue';
import UserOptions from './UserOptions.vue';

export default {
  name: "TheHorizontalNavbar",
  components: { TheNotification, Logo, UserOptions },

  emits: ['toggleVerticalNav'],

  methods: {
    ExpandVerticalNavbar() {
      this.$emit("toggleVerticalNav");
    }
  }
}
</script>

<template>
<nav class="navbar horizontal-navbar">
  <div class="left-side">
    <logo></logo>
    <icon-button @click="ExpandVerticalNavbar" class="expand-navbar-btn">
      menu
    </icon-button> 
    <icon-button @click="$router.go(-1)">
      arrow_back
    </icon-button>

  </div>

  <div v-if="false" class="search-box">
    <input class="search-input" placeholder="Search..." />
  </div>

  <div class="right-side">
    <button @click="$pop.openTaskForm($session.user.data)" class="btn blue-btn blue-fill new-task-btn" v-if="!$utils.isMobile()">
      <icon>add_task</icon>
      <label>Task nou</label>
    </button>

    <icon-button @click="$pop.openTaskForm($session.user.data)" v-else>
      add_task
    </icon-button>

    <TheNotification>
      <button v-tooltip="'Notificari'" class="icon-button material-icons">notifications</button>
    </TheNotification>

    <div class="profile-preview">
      <user-options></user-options>
    </div>
  </div>

</nav>
</template>

<style scoped>
.horizontal-navbar {
  top: 0;
  right: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid var(--nav-border) 1px;
}

.right-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 10px;
}

.right-side button {
  margin-right: 10px;
}

.right-side .user-pic {
  width: 50px;
  height: 50px;
}

.profile-preview {
  padding-right: 10px;
  margin-left: 10px;
}

.left-side {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.expand-navbar-btn {
  float: left;
  padding: 12px;
}

.left-side .icon-button {
  display: none;
}

.search-box {
  display: flex;
  align-items: center;
}

.search-box::before {
  font-family: "Material Icons";
  position: relative;
  left: 30px;
  content: "\e8b6";
  font-size: 25px;
}

.search-input { 
  text-indent: 25px;
  width: 200px;
  transition-duration: 0.3s;
}

.search-input:focus {
  width: 400px;
}

.new-task-btn .icon {
  font-size: 15px;
}


@media only screen and (max-width: 700px) 
{
  .horizontal-navbar {
    width: 100%;
    left: 0px;
  }

  .horizontal-navbar .app-logo {
    display: none;
  }

  .left-side .icon-button {
    display: block;
  }

  .new-task-btn label {
    display: none;
  }

  .new-task-btn .icon {
    font-size: 18px;
  }
}
</style>