
export default {
  beforeUnmount()
  {
    let toolTip = document.getElementById("toolTip");
    if(toolTip) {
      document.documentElement.removeChild(toolTip);
    }
  },

  mounted(el, binding) 
  {
    // no tooltip on small devices
    if(window.innerWidth <= 500) {
      return;
    }
    
    el.addEventListener('mouseenter', (event) => {

      // firefox fix :( why?
      if(document.getElementById("toolTip")) {
        document.documentElement.removeChild(document.getElementById("toolTip"));
      }

      var target = event.target;

      var targetRect = target.getBoundingClientRect();
      var tooltip = document.createElement('div');
      var arrow = document.createElement('div');

      tooltip.id = 'toolTip'; 
      tooltip.innerHTML = binding.value;
      document.documentElement.appendChild(tooltip);
      
      if(binding.arg == "top") {
        tooltip.style.top = targetRect.top - tooltip.getBoundingClientRect().height - 40 + 'px';
        arrow.className = 'arrow-down';
      }
      else {
        tooltip.style.top = targetRect.top + targetRect.height + 5 + 'px';
        arrow.className = 'arrow-up';
      }
      
      tooltip.style.left = targetRect.left + (targetRect.width / 2) - (tooltip.getBoundingClientRect().width / 0.6) + 'px';

      tooltip.appendChild(arrow);

      // console.log(elRect.left + (elRect.width / 2) - (tooltip.getBoundingClientRect().width / 2));
    });

    el.addEventListener('mouseleave', () => {
      let toolTip = document.getElementById("toolTip");
      if(toolTip) {
        document.documentElement.removeChild(toolTip);
      }
    });
  }
}
