import { reactive } from "vue";

const state = {
  connectionTimeout: false,
  darkMode: false,
  config: {},
  showLoadingScreen: true
}

export let globalState = reactive(state);

export default {
  install(app)
  {
    let props = app.config.globalProperties;
    props.$global = globalState;
    props.$dev = globalState.config.dev;
  },
}
