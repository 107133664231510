import BaseModel from './BaseModel';
import { TaskStatus } from '@/enums';

class Task extends BaseModel {
  __sortable_fields__ = ["info", "deadline", "created", "status", "author_id", "project_id"]
  __info_max_length__ = 50000
  __title_max_length__ = 200
  __minimum_deadline__ = 60 * 60

  id = 0
  user_id = 0
  deadline = 0
  created = 0
  info = ""
  status = TaskStatus.ASSIGNED
  archived = false
  author_id = 0
  shared_id = 0
  shared_task = false
  project_id = 0

  isCompleted()
  {
    return this.status == TaskStatus.COMPLETED;
  }

  isLocked()
  {
    return this.isExpired() || Boolean(this.archived);
  }

  canEdit()
  {
    return !this.isExpired()
  }

  isExpired() 
  {
    return this.timeRemained() <= 0 && !this.isCompleted() && !this.archived;
  }

  timeRemained()
  {
    return Math.floor(this.deadline - (Date.now() / 1000));
  }
}

export default Task;
