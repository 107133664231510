<script>
import TheHorizontalNavbar from './TheHorizontalNavbar.vue'
import TheVerticalNavbar from './VerticalNavBar/TheVerticalNavbar.vue'

export default { 
  name: 'TheNabar',
  components: {
    "TheHorizontalNavbar": TheHorizontalNavbar,
    "TheVerticalNavbar": TheVerticalNavbar,
  },

  data() { return {
    verticalNavbarExpanded: false,
  }},

  methods: {
    ToggleVerticalNavbar() {
      this.verticalNavbarExpanded = !this.verticalNavbarExpanded;
    }
  },

}
</script>

<template>
  <nav>
    <TheHorizontalNavbar @toggle-vertical-nav="ToggleVerticalNavbar">
    </TheHorizontalNavbar>

    <TheVerticalNavbar :isExpanded="verticalNavbarExpanded" @toggle-vertical-nav="ToggleVerticalNavbar">
    </TheVerticalNavbar>
  </nav>
</template>

<style>
.navbar {
  position: fixed;
  z-index: 5;
  background-color: var(--nav-color);
}
</style>

