
<script>
export default 
{
  name: 'ThePopupMessage',
}
</script>

<template>
  <div class="the-popup-message">
    <div v-for="message in $msg.messages" :key="message" :class="['message-row', message.class ]">
      {{ message.text }}
    </div>
  </div>
</template>

<style scoped>
.the-popup-message {
  position: fixed;
  width: 350px;
  height: fit-content;
  z-index: 1999;
  bottom: 20px;
  right: 15px;
}

.message-row {
  position: relative;
  color: #005C05;
  /* border: solid 1px rgb(4, 128, 0); */
  background-color: #7DB980;
  border-radius: 15px;
  width: 100%;
  padding: 15px;
  margin-block: 10px;
  font-weight: bold;
  animation: fade-in 0.3s ease-in-out;

  word-wrap: break-word;
}

.error {
  color: #5c0000;
  border: solid 1px rgb(128, 0, 0);
  background-color: #b97d7d;
}

.warning {
  color: #5c5300;
  border: solid 1px rgb(128, 115, 0);
  background-color: #b9b57d;
}

@media only screen and (max-width: 600px) {
  .the-popup-message {
    left: calc(50% - 175px);
  }
}
</style>
