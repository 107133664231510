<script>

export default {
  name: "GroupPermission",

  emits: ['toggle'],

  props: {
    groupId: [Number, String],
    value: [Number, String, Boolean],
    name: [String],
    editable: [Boolean, Number]

  },

  data() { return  {
    userTargets: null,
  }},

  computed: {
    targetsLength() {
      return Object.keys(this.userTargets).length;
    },

    canAddNewUser() {
      return this.value;
    }
  },

  methods: {
    TogglePermission()
    {
      this.$api.post({ path: `groups/${this.groupId}/permissions/${this.name}:toggle` }, this.OnToggleResponse);
    },

    OnToggleResponse({response})
    {
      if(response.status != "success") {
        return;
      }

      const { permission } = response.data;
      this.EmitToggle(permission.value);
    },

    EmitToggle(newValue)
    {
      this.$emit('toggle', this.name, newValue);
    },

    ToggleTargets()
    {
      if(this.userTargets) {
        this.userTargets = null;
        return;
      }
  
      this.RequestTargets();
    },

    RequestTargets()
    {
      this.$api.get({ path: `groups/${this.groupId}/permissions/${this.name}/user-targets` }, this.OnTargetsLoad);
    },

    OnTargetsLoad({response}) 
    {
      const { user_target_permissions } = response.data;
      this.userTargets = user_target_permissions;
    },

    AppendUserTarget(user)
    {
      const params = {
        "user-id": user.id
      };

      this.$api.post({ path: `groups/${this.groupId}/permissions/${this.name}/user-targets`, params }, this.OnTargetPost);
    },

    OnTargetPost({response})
    {
      if(response.status != "success") {
        this.$msg.push("Acest utilizator nu a putut fi adaugat.", "error");
        return;
      }

      const { user_profile } = response.data;

      this.userTargets[user_profile.id] = user_profile;
      // this.userTargets = null;
      // this.RequestTargets();
    },

    RemoveUserTarget(id)
    {
      const params = {
        "user-id": id
      };

      this.$api.delete({ path: `groups/${this.groupId}/permissions/${this.name}/user-targets`, params }, this.OnTargetDelete);
    },

    OnTargetDelete({response})
    {
      if(response.status != "success") {
        this.$msg.push("Acest utilizator nu a putut fi sters.", "error");
        return;
      }

      delete this.userTargets[response.data.user_id];
    }
  }
}
</script>

<template>
<div class="permission">
  <toggle-button :disabled="!editable" :toggled="value" @toggle="TogglePermission(name)">
    {{ $lan(`group_perm_${name}`)}} 
  </toggle-button>

  <icon-button class="targets-btn" @click="ToggleTargets" v-if="value">
    group
  </icon-button>

  <div class="targets" :disabled="!value" v-if="userTargets">
    <hr />

    <input-user-tag :tags="[]" @append-tag="AppendUserTarget" v-if="canAddNewUser">
    </input-user-tag>

    <div v-if="targetsLength == 0" class="user-row">
      Nu exista utilizatori inca
    </div>

    <div v-for="(data, id) in userTargets" :key="id" class="user-row">
      <span>
        <icon>person</icon> 
        {{data.f_name}} {{data.s_name}}
      </span>

      <icon-button class="no-bg red" @click="RemoveUserTarget(id)" v-if="canAddNewUser">
        close
      </icon-button>
    </div>
  </div>
</div>
</template>


<style scoped>
.permission {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  background-color: var(--box-color);
  border-bottom: var(--solid-border);
  /* border-radius: 15px; */
  margin: 3px;
}

.permission:last-child {
  border: none;
}

.targets {
  width: 100%;
  flex-grow: 1;
}

.targets[disabled='true'] {
  opacity: .6;
  cursor: not-allowed;
}

.targets[disabled='true'] * {
  cursor: not-allowed;
}

.targets-btn {
  visibility: hidden;
}

.permission:hover .targets-btn {
  visibility: visible;
}

.new-person {
  padding: 5px;
}

.new-person .icon {
  font-size: medium;
}

.user-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin: 5px;
  margin-left: 30px;
  padding: 5px;
  background-color: var(--dark-box-color);
  border-radius: 5px;
  font-size: small;
}

.user-row span {
  display: flex;
  align-items: center;
}

.user-row .icon {
  font-size: medium;
}

.user-row .icon-button {
  font-size: small;

  margin: 0;
  padding: 0;
}
</style>