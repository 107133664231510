
<script>
export default {
  name: "CollapaseBox",

  emits: ['toggle'],

  props: {
    isExpanded: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isExpanded(newValue) {
      this.expanded = newValue;
    }
  },

  computed: {
    expandedAttr() {
      return this.expanded ? "": null;
    }
  },

  data() {return {
    expanded: false
  }},

  methods: {
    ToggleBox()
    {
      this.expanded = !this.expanded;
      this.EmitExpand();
    },

    EmitExpand()
    {
      this.$emit('toggle', this.expanded);
    }
  }
}
</script>

<template>
  <div class="collapse-box" :expanded="expandedAttr">
    <div class="toggle-button click-area" @click="ToggleBox">
      <slot name="header"></slot>
    </div>

    <div class="collapse-box-body" v-show="expanded">
      <div class="toggle-button body-header click-area" @click="ToggleBox">
        <slot name="header"></slot>
      </div>

      <slot></slot>
    </div>
  </div>
</template>


<style scoped>
.collapse-box {
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
}

.toggle-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  /* border-radius: var(--border-radius); */
  /* overflow: hidden; */
  /* padding: 20px; */
  /* background-color: var(--box-color); */
}

.toggle-button::after {
  font-family: "Material Icons";
  position: absolute;
  content: "\e5cf";
  font-size: 30px;
  right: 30px;
  transition-duration: 0.5s;
}

.collapse-box[expanded] .toggle-button::after {
  transform: rotate(180deg);
}

/* .collapse-box[expanded] .toggle-button {
  box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.4);
} */

.collapse-box .collapse-box-body {
  width: inherit;
  height: fit-content;
}

.body-header {
  border-radius: 0;
  display: none;
}

.body-header::after {
  content: "close";
  font-size: 20px;
  color: red;
}

@media (max-width: 700px)
{
  .collapse-box .collapse-box-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--box-color2);
    overscroll-behavior: contain;
    animation: slide-down 0.2s;
    z-index: 100;
  }

  .body-header {
    display: flex;
  }
}
</style>