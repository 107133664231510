<script>
export default {
  name: "Modal",
  emits: ['hide'],

  props: {
    title: {
      type: String,
      default: null
    }
  },

  methods: {
    Hide()
    {
      this.$emit('hide');
      this.$modal.Hide();
    }
  }

}
</script>

<template>
<div class="popup-box mobile-popup">
  <div class="header title-bar">
    <h3>{{title}}</h3>

    <div class="buttons">
      <icon-button class="red" @click="Hide">
        close
      </icon-button>
      <slot name="buttons"></slot>
    </div>
  </div>

  <div class="body">
    <slot></slot>
  </div>

  <div class="footer" v-if="$slots.footer">
    <slot name="footer"></slot>
  </div>
</div>
</template>


<style scoped>
.title-bar {
  display: flex;
  background-color: var(--box-color);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  user-select: none;
}

.title-bar .buttons {
  display: flex;
  gap: 5px;
  align-items: center;
}

.popup-box {
  background-color: var(--box-color2);
}
</style>
