
<script>
export default {
  name: "ThePopup"
}
</script>


<template>
  <div v-if="$pop.isVisible" class="the-popup" @click.self="$pop.Hide">
    <component v-bind:is="$pop.popSelected"></component>
  </div>
</template>

<style scoped>
.the-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.747);
  overflow-y: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}
</style>


