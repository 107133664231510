<script>
import PriorityTask from './PriorityTask/index.vue';
import DailyTask from './DailyTask.vue';
import RecurringTasks from './RecurringTasks.vue';
import AssignedTasks from './PriorityTask/AssignedTasks.vue';

import CollapseBox from '../Common/CollapseBox.vue';

export default {
  name: 'UserTaskView',
  props: ['userID'],

  components: { PriorityTask, DailyTask, RecurringTasks, AssignedTasks, CollapseBox },

  data() { return {
    selectedCategory: -1,

    taskCategories: [
      {
        title: "Task-uri programate",
        component: 'priorityTask',
      },
      {
        title: "Task-uri delegate",
        component: 'assignedTasks',
      },
      {
        title: "Task-uri neprogramate",
        component: 'dailyTask',
      },
      {
        title: "Task-uri zilnice și periodice",
        component: 'recurringTasks',
      },
    ],
  }}, 

  mounted()
  {
    this.CheckTargetPriorityTask();
  },

  methods: {
    CheckTargetPriorityTask()
    {
      if(this.$route.query.task_id == undefined) {
        return;
      }

      this.OnToggleCategory(0, true);
    },

    OnToggleCategory(index, status)
    {
      this.selectedCategory = status ? index: -1;
    },

    IsCategoryExpanded(index)
    {
      return (this.selectedCategory == index);
    }
  }
}
</script>

<template>
  <div class="task-page">
    <collapse-box v-for="(category, index) in taskCategories" :key="index" class="collapse-box" @toggle="(status) => OnToggleCategory(index, status)" 
      :is-expanded="IsCategoryExpanded(index)"
    >
      <template #header>
        <div class="toggle-button">
          <div class="box-title">{{category.title}}</div>
        </div>
      </template>

      <div class="category-body">
        <component :is="category.component" :selected="IsCategoryExpanded(index)" :user-target="userID" />
      </div>
    </collapse-box>
  </div>
</template>

<style scoped>
.task-page {
  width: 100%;
  animation: slide-down 0.2s;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.collapse-box {
  width: 100%;
  position: relative;
  border-radius: 20px;
  transition-duration: 0.2s;
}


.collapse-box .toggle-button {
  border: solid 1px var(--border-color);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.box-title {
  padding: 20px;
  width: 100%;
  background-color: var(--box-color);
  font-size: var(--large-text);
  font-weight: bold;
}

.box-title:hover {
  background-color: var(--box-color-blue);
}

.category-body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  /* padding: 10px; */
}

@media only screen and (max-width: 700px) {
  .category-body {
    height: calc(100% - 60px);
  }
}
</style>

