<script>
import Task from '@/Models/Task';
import { TaskService } from '@/Services/TaskService';

import TaskCreatedList from './TaskCreatedList.vue';

export default {
  name: "TaskRedelegateModal",

  components: { TaskCreatedList },

  computed: {
    taskId() {
      return this.$modal.params.taskId;
    },
    
    usersIds() {
      return this.users.map(user => user.id);
    },

    tasksCreatedSuccess() {
      return this.newTasks.length != 0;
    }
  },

  data() { return {
    waitResponse: false,
    isDataMutated: false,
    task: null,
    users: [],
    newTasks: []
  }},

  // beforeUnmount(e)
  // {
  //   if(this.isDataMutated)
  //   {
  //     e.preventDefault();
  //   }
  // },

  mounted() 
  {
    this.RequestTask();
  },

  methods: {
    RequestTask()
    {
      TaskService.getTask(this.taskId, (taskData) => {
        this.task = new Task().applyData(taskData);
      })
    },

    DelegateTask()
    {
      this.waitResponse = true;
   
      TaskService.delegateTask(this.taskId, this.usersIds, this.task.info, this.task.deadline, this.task.project_id, (tasks) => {
        this.waitResponse = false;
        this.newTasks = tasks;
      });
    },

    AppendUserTag(user)
    {
      this.users.push(user);
    },

    RemoveUserTag(index)
    {
      this.users.splice(index, 1);
    },

    OpenTask()
    {
      this.$modal.Open("TaskPreviewModal", 
        { 
          context: this, 
          params: { 
            taskId: this.taskId 
          }
        }
      );
    },

    Close()
    {
      this.$modal.Hide();
    }
  }

}
</script>

<template>
<div class="box task-redelegate-modal">
  <div class="task-header">
    <loading size="smallest" v-if="waitResponse">
    </loading>
    <icon-btn class="no-bg" @click="OpenTask()">
      arrow_back_ios_new
    </icon-btn>
    <icon-btn class="no-bg" @click="Close()">
      clear
    </icon-btn>
  </div>

  <TaskCreatedList :tasks="newTasks" v-if="tasksCreatedSuccess">
  </TaskCreatedList>


  <div class="content" v-if="task && !tasksCreatedSuccess">
    <div class="row">
      <div class="col">
        <icon>alarm</icon>
        {{ $lan("task_modal_deadline_label") }}:
      </div>
      
      <div class="col">
        <date-input class="task-deadline" min="now" v-model:value="task.deadline">
        </date-input>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <icon>person</icon>
        {{ $lan("task_modal_owner_label") }}:
      </div>

      <div class="col">
        <input-user-tag
          :tags="users" 
          @append-tag="AppendUserTag" 
          @remove-tag="RemoveUserTag" 
        />
      </div>

    </div>

    <div class="row">
      <rich-text-area 
        :placeholder="$lan('task_header_info_title')" 
        v-model:value="task.info" 
        @input="isDataMutated = true" 
        class="info-textarea">
      </rich-text-area>
    </div>

    <div class="row actions-row">

      <button class="no-bg send-btn" @click.left="DelegateTask" :disabled="waitResponse">
        <icon>send</icon> 
        {{ $lan("task_send_btn") }}
      </button>

    </div>

  </div>
</div>
</template>


<style scoped>
.task-redelegate-modal {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: 600px;
  overflow: hidden;
  margin-top: 50px;
  padding: 20px;
  animation: pulse 0.3s;
}


.task-redelegate-modal > .content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 700px;
}

.task-redelegate-modal .row {
  display: flex;
  width: 100%;
  gap: 10px;

  font-size: var(--normal-text);
}

.task-redelegate-modal .col {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.task-redelegate-modal .col:hover {
  background-color: var(--box-color-blue);
}

.task-redelegate-modal .col:nth-child(1) {
  width: 160px;
  gap: 10px;
}

.task-redelegate-modal .col:nth-child(2) {
  flex: 1;
}


.task-redelegate-modal .row .icon {
  font-size: var(--normal-icon);
}

.actions-row {
  justify-content: flex-end;
}

.input-user-tag {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}


.task-header {
  display: flex;
  justify-content: right;
  align-items: center;
}

.task-header .icon-button {
  font-size: larger;
}

.task-deadline {
  border: none;
  padding: 0;
  background: none;
  border-radius: 0;
}

.info-textarea {
  min-height: 100px;
  width: 100%;
  margin-block: 20px;
  /* border: none; */
  /* background-color: var(--dark-box-color); */
}

.info-input[focused] {
  max-height: none;
}

.send-btn {
  padding: 15px;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .task-redelegate-modal {
    width: 100%;
    max-height: 100%;
    margin-top: 0;
  }

  .task-redelegate-modal > .content {
    height: fit-content;
  }
}
</style>