<script>
export default {
  name: "ResultBox",
  emits: ['close', 'confirm'],

  props: {
    success: {
      type: Boolean,
      default: false,
      required: true
    },

    loading: {
      type: Boolean,
      default: false,
      required: false
    },

    successMessage: {
      type: String,
      default: "Success",
      required: false
    },

    failMessage: {
      type: String,
      default: "Fail",
      required: false
    },

    okButtonText: {
      type: String,
      default: "Continua",
      required: false
    }
  },

  computed: {
    message() {
      return this.success ? this.successMessage: this.failMessage;
    },

    iconName() {
      return this.success ? 'check': 'close';
    }
  }
}
</script>

<template>
  <div class="result-box">
    <loading v-if="loading"></loading>

    <div v-else>
      <div class="message blue">
        <icon>{{iconName}}</icon>
        <h3>
          {{message}}
        </h3>
      </div>


      <div class="buttons">
        <button class="blue border-btn" @click="$emit('confirm')">
          <icon>arrow_forward</icon>{{okButtonText}}
        </button>

        <button class="red no-bg" @click="$emit('close')">
          <icon>close</icon>{{$lan('close')}}
        </button>
      </div>
    </div>
  </div>
</template>



<style scoped>
.result-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 50px;
}

.result-box .message .material-icons {
  font-size: 40px;
  animation: zoom-in 0.5s;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
}

.buttons button {
  padding: 20px;
}
</style>