
<script>

export default {
  name: "TaskCreatedList",

  props: {
    tasks: [Array, undefined, null],
  },

  methods: {
    OpenTask(taskId)
    {
      this.$modal.Open("TaskPreviewModal", { context: this, params: { taskId } });
    }
  }
}
</script>

<template>
<div class="task-created-list">

  <div class="task-row" v-for="(task, index) in tasks" :key="index" @click="OpenTask(task.id)">

    <div class="task-user">
      <avatar :url="task.user_data.picture" :f-name="task.user_data.f_name" :s-name="task.user_data.s_name">
      </avatar>
      {{ task.user_data.f_name }} {{ task.user_data.s_name }}
    </div>

    <div class="task-title">
      {{ task.title }}
    </div>

    <icon>open_in_new</icon>
  </div>
</div>
</template>

<style scoped>
.task-created-list {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  width: 100%;
  overflow: auto;
  padding-block: 10px;
  gap: 5px;
}

.task-row {
  width: 100%;
  position: relative;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dark-box-color);

  gap: 25px;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.task-row:hover > .icon{
  display: flex;
}

.task-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  min-width: 150px;
}

.task-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.task-row > .icon {
  background-color: var(--box-color);
  font-size: large;
  position: absolute;
  right: 0px;
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
  display: none;
}

.task-user .avatar-container {
  width: 35px;
  height: 35px;
}
</style>