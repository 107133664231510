import { modalManager } from "./script/Popup/ModalManager";

export function SanitizeHtml(htmlText)
{
  return htmlText;
}

export function OnClickOutsideEvent(element, event)
{
  let onClickDocument = (e) => {
    if(e.target == element || element.contains(e.target)) {
      return;
    }

    event();
    document.removeEventListener("mousedown", onClickDocument);
  };

  document.addEventListener("mousedown", onClickDocument);
}

export const bodyScroll = {
  disabled: false,

  Disable()
  {
    document.body.setAttribute('no-scroll', '');
    this.disabled = true;
  },

  Enable(force = false)
  {
    if(modalManager.isVisible && !force) {
      return;
    }

    document.body.removeAttribute('no-scroll');
    this.disabled = false;
  },

  Toggle()
  {
    if(this.disabled) {
      this.Enable();
      return;
    }

    this.Disable();
  },

  Set(value) 
  {
    if(value == 'disabled') {
      this.Disable();
      return;
    }

    this.Enable();
  }
}

export const Flag = {
  IsFlag(flags, flag) 
  {
    return flags.split(',').find((tmpFlag) => flag == tmpFlag) != undefined;
  },
  
  SetFlag(flags, flag)
  {
    if(this.IsFlag(flags, flag)) {
      return flags;
    }

    return flags.concat(flag, ",");
  },
  
  RemoveFlag(flags, flag)
  {
    return flags.replace(`${flag},`,"");
  }
}

export const time = {
  timeStampToString(time)
  {
    const now = this.timeStamp();
    const dateStruct = new Date(time * 1000);
    const difference = now - time;

    if(difference < 60)
    {
      return "Acum câteva secunde"
    }
    else if(difference < 3600)
    {
      return `Acum ${ Math.floor(difference/60)} minut(e)`;
    }
    else if(difference < 86400 && dateStruct.getDate() == new Date().getDate())
    {
      return `Astăzi la ${dateStruct.getHours()}:${dateStruct.getMinutes()}`;
    }
    else if(dateStruct.getDate() == (new Date().getDate() - 1))
    {
      return `Ieri la ${dateStruct.getHours()}:${dateStruct.getMinutes()}`;
    }

    return `${dateStruct.getDate()}-${dateStruct.getMonth()+1}-${dateStruct.getFullYear()} ${dateStruct.getHours()}:${dateStruct.getMinutes()} `;
  },

  timeStampToInputString(time)
  {
    try {
      // Convert unix timestamp format for input value format
      const dateStruct = time != "now" ? new Date(time * 1000): new Date();
      const year = dateStruct.getFullYear();
      const month =  (dateStruct.getMonth()+1) < 10 ? `0${dateStruct.getMonth()+1}` : dateStruct.getMonth()+1;
      const day = dateStruct.getDate() < 10 ? `0${dateStruct.getDate()}` : dateStruct.getDate();

      return `${year}-${month}-${day}`;
    }
    catch {
      throw `Couldn't convert timestamp value ${time} to input value`;
    }
  },

  inputStringToTimeStamp(value)
  {
    return (new Date(value).getTime());
  },

  timeStamp()
  {
    return (new Date().getTime() / 1000);
  },

  mTimeStamp()
  {
    return new Date().getTime();
  }
}
