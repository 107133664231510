


export default {
  FormatSelection(nameTag)
  {
    if(!this.APISupported()) {
      return;
    }

    let selection = window.getSelection();

    let range = selection.getRangeAt(0);

    if(range.getString()) {
      this.ReplaceSelection()
    }
  },

  InsertHtmlAtCartel(htmlContent)
  {
    let selection = window.getSelection();

    let range = selection.getRangeAt(0);
    range.deleteContents();

    let el = document.createElement("div");
    el.innerHTML = htmlContent;

    let frag = document.createDocumentFragment(), node, lastNode;

    while ( (node = el.firstChild) ) 
    {
      lastNode = frag.appendChild(node);
    }

    range.insertNode(frag);
    
    // Preserve the selection
    if (lastNode) 
    {
      range = range.cloneRange();
      range.setStartAfter(lastNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  },

  InsertList()
  {
    
  },

  APISupported()
  {
    return window.getSelection && window.getSelection()?.getRangeAt;
  }

};


