<script>
export default {
  name: "TaskLog",

  props: {
    id: [Number, String],
    author: [Object, null],
    action: [String],
    created: [Number, String],
    detail: [Object, null]
  }
}
</script>

<template>
<div class="task-log">
  <div class="details">
    <div class="author-container" v-if="author">
      <avatar :size="null" :url="author.picture" :f-name="author.f_name" :s-name="author.s_name">
      </avatar>

      <span class="author-name">{{ author.f_name }} {{ author.s_name }}</span>
    </div>

    <div class="author-container" v-else>
      <avatar :size="null" url="./img/icons/icon-192x192.png" f-name="NA">
      </avatar>
      <span class="author-name"> 
        {{ $lan("user_deleted") }}
      </span>
    </div>

    <span>
      {{ $lan(`task_log_${action}`) }}
    </span>
  </div>

  
  <div class="timestamp">
    {{ $utils.TimeStampToString(created) }}
  </div>

  <!-- <div class="log-details">
    aaaaaaa
  </div> -->
</div>
</template>

<style scoped>
.task-log {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  font-size: var(--text-normal);

  border-radius: 10px;
  gap: 5px;
}

.task-log:hover {
  background-color: var(--dark-box-color);
}

.task-log .details {
  display: flex;
  align-items: center;
  gap: 5px;

}

.task-log .details .avatar-container {
  width: 25px;
  height: 25px;
  border: none;
}

.task-log .details .avatar-img {
  width: 25px;
  height: 25px;
  padding: 0;
}

.task-log .timestamp {
  align-self: right;
  font-size: var(--small-text);
}

.log-details {
  flex: 1;
  width: 100%;
}

.author-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.author-name {
  font-weight: bold;
}
</style>