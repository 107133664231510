<script>
import MarkDownContainer from './MarkDownContainer.vue'
export default {
  components: { MarkDownContainer },
  name: "RichtextPreview"
}
</script>

<template>
  <modal>
    <mark-down-container :content="this.$modal.params.text"></mark-down-container>
  </modal>
</template>



<style>

</style>