
class BaseModel {
  id = 0

  applyData(data)
  {
    Object.assign(this, data);
    return this;
  }
}

export default BaseModel;
