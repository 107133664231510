
<script>
import Avatar from './Avatar.vue'

export default {
  components: { Avatar },
  name: "Comment",
  emits: ['remove', 'update'],

  props: {
    id: {
      type: [Number, String],
      required: true
    },

    data: {
      type: [Object, null],
      required: true,
      default: null
    },

    author: {
      type: [Object, null],
      required: true,
    }
  },

  computed: {
    timestamp() {
      return this.data.created;
    }
  }
}
</script>

<template>
  <div class="flex-box comment" v-if="data">
    <div class="details-container">
      <div class="author">
        <profile-preview :user-target="data.u_id">
          <avatar :url="author.picture" :f-name="author.f_name" :s-name="author.s_name" :active="author.is_active"></avatar>
        </profile-preview>
        {{author.f_name}} {{author.s_name}}
      </div>

      <div class="time">
        {{$utils.TimeStampToString(this.timestamp)}}
      </div>
    </div>

    <div v-html="data.text" class="comment-text">
    </div>
  </div>
</template>

<style scoped>
.comment {
  flex-direction: column;
  height: fit-content;
  width: 100%;
  padding: 10px;
  border-radius: var(--border-radius);
  /* transition: box-shadow 0.3s; */
  /* margin-left: 2px; */
}

.comment:hover {
  background-color: var(--dark-box-color);
  /* box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.100); */

}

.details-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.author {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: medium;
  font-weight: bold;
}

.comment:hover .time {
  visibility: visible;
}

.time {
  padding-left: 10px;
  font-size: smaller;
  visibility: hidden;
}

.comment-text {
  position: relative;
  width: fit-content;
  max-width: calc(100% - 50px);
  padding: 10px;
  border-radius:  5px 20px 20px 20px;
  color: var(--text-color-strong);
  word-wrap: break-word;
  white-space: pre-wrap;
  left: 20px;
  top: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: var(--box-color);
}

.avatar-container {
  width: 40px;
  height: 40px;
}
</style>