<script>
export default {
  name: "LoadingBox"
}
</script>

<template>
  <div class="loading-box">
    <loading></loading>
  </div>
</template>


<style scoped>
.loading-box {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  padding: 50px;
}
</style>