import { reactive } from "vue";

import router from '../router';
import { CreateUser } from "./User";
import { apiManager } from "../ApiManager";


class Session
{
  constructor()
  {
    this.InitProps();
  }

  InitProps()
  {
    this.user = null;
    this.loading = false;
  }

  LoadSession(callback = null)
  {
    this.loading = true;

    if(!this.GetSessionToken()) {
      this.loading = false;
      return false;
    }

    apiManager.get({ path: "user/session" }, ({response}) => this.OnSessionLoaded(response, callback));
    return true;
  }

  OnSessionLoaded(response, callback)
  {
    const { user_session } = response.data;

    if(response.status == "fail") {
      this.Logout();
      return;
    }

    this.CreateUserSession(user_session);
    this.loading = false;

    if(callback) {
      callback();
    }
  }

  CreateUserSession({user, group})
  {
    this.user = CreateUser(user, group)
  }

  Logout()
  {
    this.RemoveSessionToken();
    this.InitProps();
    router.push('/login');
  }

  RemoveSessionToken()
  {
    window.$cookies.remove("SSID");
  }

  SetSessionToken(token)
  {
    window.$cookies.set("SSID", token, "90d");
  }

  GetSessionToken()
  {
    return window.$cookies.get('SSID');
  }

  HasAccess(permissions)
  {
    for(let i in permissions) 
    {
      const permissionName = permissions[i];
      if(!session.user.can(permissionName)) {
        return false;
      }
    }

    return true;
  }

  IsUser(userId)
  {
    return userId == this.userId;
  }

  UpdateUserData(userData) 
  {
    this.user.SetUserData(userData);
  }

  UpdateGroupData(groupData)
  {
    this.user.SetGroupData(groupData);
  }

  get userId()
  {
    return this.user?.id || -1;
  }

  get active()
  {
    return this.user != null;
  }

  get accessLevel()
  {
    return this.user.privilege;
  }

  get rootAccess() 
  {
    return this.user.rootAccess;
  }
}


export const session = reactive(new Session());


export default {
  install(app)
  {
    app.config.globalProperties.$session = session;
  }
}

