import axios from "axios";

import { constants } from './constants.js';
import { HttpStatus } from "@/enums.js";

import { popupMessageManager } from './Popup/PopupMessageManager.js';
import { globalState } from './GlobalState.js';

import { session } from './User/Session.js';

const axiosInstance = axios.create({
  baseURL: constants.api,
  timeout: constants.api_timeout,
  withCredentials: true,
});



class APIManager {

  get({ path, params, context, headers }, callback)
  {
    axiosInstance.get(path, { params, headers })
    .then(response => this.handleResponse({ response, callback, context }))
    .catch(error => this.handleRequestError(error));
  }

  post({ path, params, context, data, headers }, callback)
  {
    axiosInstance.post(path, data, { params, headers })
    .then(response => this.handleResponse({ response, callback, context }))
    .catch(error => this.handleRequestError(error));
  }

  delete({ path, params, context, headers }, callback)
  {
    axiosInstance.delete(path, { params, headers })
    .then(response => this.handleResponse({ response, callback, context }))
    .catch(error => this.handleRequestError(error));
  }

  put({ path, params, context, data, headers }, callback)
  {
    axiosInstance.put(path, data, { params, headers })
    .then(response => this.handleResponse({ response, callback, context }))
    .catch(error => this.handleRequestError(error));
  }

  patch({ path, params, context, data, headers }, callback)
  {
    axiosInstance.patch(path, data, { params, headers })
    .then(response => this.handleResponse({ response, callback, context }))
    .catch(error => this.handleRequestError(error));
  }

  handleResponse({ response, callback, context })
  {

    const api_response = response.data;

    if(globalState.connectionTimeout) {
      globalState.connectionTimeout = false; 
    }

    const processResponse = this.checkApiResponseMessage(api_response);

    if(!processResponse || !callback) {
      return;
    }

    const callbackData = {
      context, 
      response: api_response,
    };

    callback(callbackData);
  }

  checkApiResponseMessage(apiResponse) 
  {
    const { status, message, details } = apiResponse;

    if(status == HttpStatus.OK) {
      return true;
    }

    switch(message)
    {
      case 'invalid_session':
      {
        popupMessageManager.tpush("session_expired", "error");
        setTimeout(() => { session.Logout() }, 1000);
        return false;
      }

      case 'access_denied':
      {
        popupMessageManager.tpush("access_denied", "error");
        return false;
      }

      case 'disabled_service':
      {
        popupMessageManager.tpush("disabled_service", "error");
        return false;
      }

      default:
      {
        console.warn(`[ApiManager]: Server responded with an error message. Response status: ${status}, message: ${message}, details ${details}`);
        return true;
      }
    }
  }

  handleRequestError(error)
  {
    console.error("There was an error while trying to make a request:");
    console.error(error);
  }
}

export let apiManager = new APIManager();

export default {
  install(app) 
  {
    app.config.globalProperties.$api = apiManager;
  }
}
