<script>
export default {
  name: "TimestampDateInput",

  emits: ['update:value', 'change', 'blur'],

  props: {
    min: {
      type: [Number, String],
      default: null
    },

    max: {
      type: [Number, String],
      default: null
    },

    value: {
      type: [Number, String],
      default: null
    }
  },

  data() { return {
    stringDateTime: "",
  }},

  watch: {
    value: {
      immediate: true, 
      handler() {
        this.UpdateStringDateTime();
      }
    }
  },

  mounted() {
    this.UpdateStringDateTime();
  },

  methods: {
    OnChange(e)
    {
      this.$emit('update:value', this.timeStampValue);
      this.$emit('change', e);
    },

    OnBlur(e)
    {
      this.$emit('blur', e);
    },

    UpdateStringDateTime()
    {
      if(!this.value) {
        return;
      }

      this.stringDateTime = this.$utils.TimeStampToInputVal(this.value);
    }
  },

  computed: {
    minValue() 
    {
      if(!this.min) {
        return null;
      }

      return this.$utils.TimeStampToInputVal(this.min);
    },

    maxValue() 
    {
      if(!this.max) {
        return null;
      }

      return this.$utils.TimeStampToInputVal(this.max);
    },

    timeStampValue()
    {
      return this.$utils.InputTimeToStamp(this.stringDateTime) / 1000;
    },
  },

}
</script>

<template>
  <input type="date" @change="OnChange" @blur="OnBlur"
    v-model="stringDateTime"
    :min="minValue"
    :max="maxValue"
  />
</template>
