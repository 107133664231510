<script>
import DarkMode from '../../../../script/DarkMode';

export default {
  name: "NavFooter",

  computed: {
    darkModeAttr()
    {
      return this.$global.darkMode ? '': null;
    }
  },
  
  methods: {
    ToggleDarkMode()
    {
      DarkMode.ToggleDarkMode();
    }
  }
}
</script>

<template>
  <footer>
    <div class="links-container">
      <a v-for="link in $const.footer_links" :key="link" :href="link.href" target="_blank">
        {{link.label}}
      </a>
    </div>
    <div class="copyright-container">
      <div>Version: {{$const.ver}}</div>
      <div v-html="$const.copyright" />
    </div>

    <div>
    <button class="no-bg dark-btn"  @click="ToggleDarkMode" v-tooltip:top="'Dark Mode'" :dark-mode="darkModeAttr">
      <icon class="dark">dark_mode</icon>
      <icon class="light">light_mode</icon>
    </button>
    </div>
  </footer>
</template>


<style scoped>
footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  width: 100%;
  text-align: left;
  background-color: inherit;
  padding: 10px;
  /* border-top: solid 1px var(--border-color); */
  color: var(--text-color);
  font-size: 14px;
  padding-left: 20px;
}

.copyright-container {
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.links-container {
  width: 70%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: left;
}

.dark-btn {
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.dark-btn .icon {
  transition-duration: 1s;
}

.dark-btn[dark-mode] .dark {
  transform: translateX(70%) rotate(0);
}

.dark-btn[dark-mode] .light {
  transform: translateX(70%) rotate(0);
}

.dark {
  transform: translateX(-70%) rotate(-180deg);
}

.light {
  transform: translateX(-70%) rotate(-180deg);
}
</style>