<script>
import { TaskOptionCommand } from '@/enums';

import { TaskService } from '@/Services/TaskService';
import Task from '@/Models/Task';

import TaskActivity from './TaskActivity';
import TaskStatus from '@/components/Task/TaskStatus';
import TaskOptions from '@/components/Task/TaskOptions';
import TaskNotificationButton from '@/components/Task/TaskNotificationButton';
import TaskMembersDropdown from '@/components/Task/TaskMembersDropdown';


export default {
  name: "TaskPreviewModal",

  components: { TaskStatus, TaskActivity, TaskOptions, TaskNotificationButton, TaskMembersDropdown },

  computed: {
    taskId() {
      return this.$modal.params.taskId;
    },

    nextTask() {
      return this.$modal.params.nextTask;
    },

    taskEvents() {
      return this.$modal.events;
    },

    task() {
      return new Task().applyData(this.taskData);
    },

    isLocked() {
      return this.task?.isLocked();
    },

    isExpired() {
      return this.task?.isExpired();
    },

    author() {
      return this.taskData?.author_data;
    },

    owner() {
      return this.taskData?.user_data;
    },

    project() {
      return this.taskData?.project;
    },

    taskTypeText() {
      return this.taskData.shared_task ? this.$lan("task_type_label_common"): this.$lan("task_type_label_individual");
    }
  },

  data() { return {
    taskData: null,

    isDataMutated: false,
    savingData: false,
    waitResponse: true
  }},

  mounted() 
  {
    this.RequestTask();
  },

  methods: {
    ReloadTask()
    {
      this.taskData = null;
      this.RequestTask();
    },

    UpdateDeadline()
    {
      this.savingData = true;
      TaskService.updateTaskDeadline(this.taskId, this.taskData.deadline, this.OnUpdateDeadline);
    },

    OnUpdateDeadline()
    {
      this.savingData = false;
    },

    UpdateInformation()
    {
      if(!this.isDataMutated) {
        return;
      }

      this.savingData = true;
      TaskService.updateTaskInformation(this.taskId, this.taskData.info, this.OnUpdateInformation);
    },

    OnUpdateInformation()
    {
      this.isDataMutated = false;
      this.savingData = false;
    },

    RequestTask() 
    {
      this.waitResponse = true;

      TaskService.getTask(this.taskId, this.OnRequestTask);
    },

    OnRequestTask(taskData, success)
    {
      this.waitResponse = false;

      if(!success || !taskData) 
      {
        this.Close();
        return;
      }

      this.taskData = taskData;
    },

    OnStatusChanged(newStatus)
    {
      this.taskData.status = newStatus;
    },

    OpenAttachedProject()
    {
      const projectId = this.taskData.project_id;

      if(!projectId) {
        return;
      }

      this.$router.push({ name: 'project_page', params: { id: projectId } });
      this.Close();
    },

    OnTaskOptionCommand(command)
    {
      switch(command)
      {
        case TaskOptionCommand.DELETE:
        {
          this.taskEvents?.onDeleteTask?.();

          this.Close();
          break;
        }

        case TaskOptionCommand.ARCHIVE:
        {
          this.taskData.archived = true;
          break;
        }

        default:
          break;
      }
    },

    OpenAttachProjectModal()
    {
      this.$modal.Open("TaskAttachProject", { context: this, params: { taskId: this.taskId } });
    },

    Close()
    {
      this.$modal.Hide();
    }
  }

}
</script>

<template>
<div class="box task-preview">

  <div class="task-header">

    <div class="task-sync">
      <icon-btn class="click-area no-bg" v-tooltip="$lan('task_next_btn_tooltip')" @click="OpenNextTask()" v-if="nextTask">
        arrow_forward_ios
      </icon-btn>

      <loading size="smallest" v-if="savingData || waitResponse">
      </loading>
      
      <icon class="click-area red" v-tooltip="$lan('task_tooltip_cant_modify')" v-else-if="isLocked">
        lock
      </icon>
      <icon-btn class="click-area no-bg" v-tooltip="$lan('saved_text')" @click="ReloadTask()" v-else>
        cloud_done
      </icon-btn>
    </div>

    <task-notification-button class="no-bg" :task-id="taskId">
    </task-notification-button>

    <TaskOptions :task-id="taskId" :is-locked="isLocked" @command="OnTaskOptionCommand">
      <icon-btn class="no-bg">more_horiz</icon-btn>
    </TaskOptions>

    <icon-btn class="no-bg" @click="Close()">
      clear
    </icon-btn>
  </div>

  <loading-box v-if="waitResponse">
  </loading-box>

  <div v-else-if="!waitResponse && !taskData">
    no task
  </div>

  <div class="task-content" v-else>

    <div class="row notice-box archived-msg" v-if="task.archived">
      <icon>inventory_2</icon> {{ $lan("task_modal_archived_text") }}
    </div>
    <div class="row notice-box expired-msg" v-else-if="task.isExpired()">
      <icon>timer</icon> {{ $lan("task_modal_expired_text") }}
    </div>


    <!-- Task Author -->
    <div class="row">
      <div class="col">
        <icon>person</icon>
        <span>{{ $lan("task_modal_author_label") }}:</span>
      </div>

      <div class="col highlight-div task-author" v-if="author">
        <profile-preview :userTarget="author.id">
          <avatar :f-name="author.f_name" :s-name="author.s_name" :url="author.picture"></avatar>
        </profile-preview>
        
        {{ author.f_name }} {{ author.s_name }}
      </div>

      <div class="col highlight-div task-author" v-else>
        {{ $lan("user_deleted") }}
      </div>
    </div>

    <!-- Task members -->
    <div class="row" v-if="taskData.shared_task">
      <div class="col">
        <icon>group</icon>
        <span>{{ $lan("task_modal_members_label") }}:</span>
      </div>

      <div class="col highlight-div task-author">
        <TaskMembersDropdown :task-id="taskId"></TaskMembersDropdown>
      </div>
    </div>

    <!-- Task owner -->
    <div class="row" v-else>
      <div class="col">
        <icon>person</icon>
        <span>{{ $lan("task_modal_owner_label") }}:</span>
      </div>

      <div class="col highlight-div task-author" v-if="owner">
        <profile-preview :userTarget="owner.id">
          <avatar :f-name="owner.f_name" :s-name="owner.s_name" :url="owner.picture"></avatar>
        </profile-preview>

        {{ owner.f_name }} {{ owner.s_name }}
      </div>

      <div class="col highlight-div task-author" v-else>
        {{ $lan("user_deleted") }}
      </div>
    </div>

    <!-- Task type -->
    <div class="row">
      <div class="col">
        <icon>workspaces</icon>
        <span>{{ $lan("task_modal_type_label") }}:</span>
      </div>

      <div class="col highlight-div">
        {{ taskTypeText }}
      </div>
    </div>

    <!-- Task Creation Timestamp -->
    <div class="row">
      <div class="col">
        <icon>schedule</icon>{{ $lan("task_modal_created_label") }}:
      </div>
      <div class="col highlight-div">
          {{$utils.TimeStampToString(taskData.created)}}
      </div>
    </div>

    <!-- Task Deadline -->
    <div class="row">
      <div class="col">
        <icon>alarm</icon>{{ $lan("task_modal_deadline_label") }}:
      </div>
      <div class="col highlight-div">
        <date-input class="task-deadline" v-model:value="taskData.deadline" @blur="UpdateDeadline" :disabled="isLocked">
        </date-input>

        <div class="red" v-if="task.isExpired() && !task.isCompleted()">
          {{ $lan("task_modal_expired_label") }} {{ Math.floor(task.timeRemained() / 86400) * -1 }}  {{ $lan("days_text") }}
        </div>
      </div>
    </div>

    <!-- Task Project -->
    <div class="row click-area" @click="OpenAttachedProject">
      <div class="col">
        <icon>calendar_month</icon>
        {{ $lan("task_modal_project_label") }}:
      </div>

      <div class="col highlight-div project-link" v-if="project">
        {{ project.title }}

        <icon>open_in_new</icon>
      </div>
      <div class="col highlight-div project-link" @click="OpenAttachProjectModal" v-else>
        {{ $lan("task_no_project_label") }}
      </div>
    </div>

    <!-- Task Status -->
    <div class="row">
      <div class="col">
        <icon>info</icon>{{ $lan("task_modal_status_label") }}:
      </div>
      <div class="col highlight-div">
        <TaskStatus :task-id="taskId" :status="taskData.status" @update="OnStatusChanged" :disabled="isLocked">
        </TaskStatus>
      </div>
    </div>

    <!-- Task Info -->
    <div class="row">
      <rich-text-area 
        maxlength="50000" 
        :placeholder="$lan('task_header_info_title')" 
        class="info-textarea" 

        v-model:value="taskData.info" 
        @input="isDataMutated = true" 
        @blur="UpdateInformation" 

        :disabled="isLocked">
      </rich-text-area>
    </div>

    <!-- Task Activity -->
    <div class="row">
      <TaskActivity :task-id="taskId"></TaskActivity>
    </div>

  </div>

  <div class="task-footer" v-if="!waitResponse">
    <div>
      Task ID: {{ taskData.id }}
    </div>
    <div>
      Shared ID: {{ taskData.shared_id }}
    </div>
  </div>

</div>
</template>


<style scoped>
.task-preview {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: 600px;
  overflow: hidden;
  margin-top: 50px;
  padding: 20px;
  animation: pulse 0.3s;
}

.task-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 700px;
}

.task-content > .row {
  display: flex;
  gap: 15px;
  width: 100%;
  font-size: var(--normal-text);
}

.task-content > .row > .col {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
}

.task-content > .row > .col:hover {
  background-color: var(--box-color-blue);
}

.task-content > .row > .col:nth-child(1) {
  width: 160px;
  gap: 10px;
}

.task-content > .row > .col:nth-child(2) {
  flex: 1;
}

.task-content > .row > .col:nth-child(1) > .icon {
  font-size: var(--normal-icon);
}


.task-header {
  display: flex;
  justify-content: right;
  align-items: center;
}

.task-header .icon-button {
  font-size: larger;
}

.task-sync .icon {
  font-size: var(--normal-icon);
  padding: 5px;
}

.task-sync {
  display: flex;
  align-content: center;
}

.task-sync .icon {
  font-size: 20px;
  animation: slide-up .6;
}


.task-footer {
  width: 100%;
  padding: 10px;
  opacity: .4;
  font-size: var(--x-small-text);
}


.info-textarea {
  width: 100%;
  min-height: 100px;
  margin-block: 20px;
  font-size: var(--normal-text);
  border-color: var(--border-color);
}

.info-textarea .placeholder {
  font-size: var(--large-text);
  font-weight: bold;
}

.task-author {
  gap: 5px;
}

.task-author .avatar-container {
  width: 30px;
  height: 30px;
}

.task-author .avatar-container .avatar-img {
  border: none;
  padding: 0;
}

.task-deadline {
  color: inherit;
  border: none;
  background: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.project-link {
  gap: 5px;
}

.project-link .icon {
  font-size: var(--normal-icon);
}

.notice-box {
  align-items: center;
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  color: white;
}

.notice-box .icon {
  font-size: var(--normal-icon);
}


.archived-msg {
  background-color: coral;
}

.expired-msg {
  background-color: indianred;
}

@media screen and (max-width: 600px) {
  .task-preview {
    width: 100%;
    max-height: 100%;
    margin-top: 0;
  }

  .task-content {
    height: fit-content;
  }
}
</style>