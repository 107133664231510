
<script>
import GroupEdit from './GroupEdit.vue';
import GroupList from './GroupList.vue';



export default {
  name: 'GroupManager',

  components: { GroupList, GroupEdit },

  data() { return {
    inputValue: "",
    selectedGroupId: -1
  }},

  computed: {
    isGroupSelected() {
      return this.selectedGroupId != -1;
    }
  },

  mounted() {
    this.CheckUrlGroupIdQuery();
  },

  methods: {
    CheckUrlGroupIdQuery()
    {
      const groupId = this.$route.query["group-id"];
      if(groupId == null) {
        return;
      }

      this.SelectGroup(groupId, false);
    },

    CreateGroup()
    {
      if(!this.inputValue) {
        this.$refs.groupInput.Focus();
        return;
      }

      const params = {
        "group-name": this.inputValue
      };

      this.$api.post({ path: "groups/", params }, this.OnCreateResponse);
    },

    OnCreateResponse({response})
    {
      if(response.status != "success") {
        return;
      }

      this.inputValue = "";
      const { group_id } = response.data;
      this.SelectGroup(group_id);
    },

    SelectGroup(groupId, updateQuery = true)
    {
      this.selectedGroupId = groupId;

      if(updateQuery) {
        this.UpdateUrlQuery();
      }
    },

    CloseEditMode()
    {
      this.selectedGroupId = -1;
      this.ClearUrlQuery();
    },

    UpdateUrlQuery()
    {
      const query = { 
        "group-id": this.selectedGroupId
      };

      this.$router.push({ query });
    },

    ClearUrlQuery()
    {
      this.$router.push({ query: null });
    }
  }
}
</script>


<template>
  <div class="group-manager">


    <div class="body">
      <group-list @select-group="SelectGroup" v-if="!isGroupSelected">
      </group-list>

      <group-edit @close="CloseEditMode" :group-id="selectedGroupId" v-else>
      </group-edit>
    </div>

  </div>

</template>

<style scoped>

.group-manager {
  position: relative;
  display: flex;
  flex-flow: column;
  min-width: 350px;
  max-width: 500px;
  flex-grow: 1;
  overflow: hidden;
}



.group-manager .body {
  width: 100%;
  padding: 5px;
}



@media only screen and (max-width: 1200px) 
{
  .group-box {
    width: 100%;
    margin-bottom: 20px;
    min-width: 0;
  }
}
</style>