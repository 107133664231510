<script>
export default {
  name: "Loading",

  props: {
    size: {
      default: 'small',
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <div :size="size" class="lds-ring loading"><div></div></div>
</template>

<style scoped>

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: lds-ring 0.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--theme-color) var(--border-color) var(--border-color) var(--border-color);
}

.lds-ring[size='x-small'] {
  width: 18px;
  height: 18px;
}

.lds-ring[size='smallest'] {
  width: 22px;
  height: 22px;
}

.lds-ring[size='small'] {
  width: 32px;
  height: 32px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>