import { apiManager } from "../script/ApiManager";
import { popupMessageManager } from "../script/Popup/PopupMessageManager";
import { HttpStatus } from "@/enums";

export default {
  getUsersByName(query, callback)
  {
    const params = {
      q: query
    };

    const path = "/users/";

    apiManager.get({ path, params }, ({response}) => {
      const { status, message, data } = response;

      if (status != HttpStatus.OK) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data?.user_list || []);
    });
  }
}


export const UserManagerService = {

  deleteUser(userId, callback)
  {
    const path = `/users/${userId}`;

    apiManager.delete({ path }, ({response}) => {
      const { status, message } = response;

      const success = status == HttpStatus.OK;
      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  },

  getRestrictionStatus(userId, callback)
  {
    const path = `/users/${userId}/restricted`;

    apiManager.get({ path }, ({response}) => {
      const { status, message, data } = response;

      const success = status == HttpStatus.OK;
      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data.restricted);
    });
  },

  restrictUser(userId, callback)
  {
    const path = `/users/${userId}:restrict`;

    apiManager.post({ path }, ({response}) => {
      const { status, message } = response;

      const success = status == HttpStatus.OK;
      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  },

  removeUserRestriction(userId, callback)
  {
    const path = `/users/${userId}:remove-restriction`;

    apiManager.post({ path }, ({response}) => {
      const { status, message } = response;

      const success = status == HttpStatus.OK;
      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  }
}

