
<script>
  export default {
    name: "BetaVersion",
  }
</script>
  
<template>
  <div v-if="$const.beta" v-tooltip="'Aceasta versiune poate fii instabila'" class="beta-ver">
    Beta<icon>bug_report</icon>
  </div>
</template>

<style scoped>
.beta-ver {
  width: fit-content;
  background-color: var(--theme-color);
  color: white;
  font-weight: bold;
  padding: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  user-select: none;
  padding-inline: 15px;
}
</style>