<script>
import DateInput from '../Common/DateInput.vue';

export default {
  components: { DateInput },
  name: 'RecurringTasksView',

  props: {
    selected: {
      type: Boolean,
      required: true
    },

    userTarget: {
      type: [Number, String],
      required: true
    }
  },

  data() { return {
    selectedCategory: null,
    waitResponse: false,
    taskCategories: ['daily','weekly','monthly','quarterly','anual'],

    tasks: [],
    timeStamp: 0
  }},

  computed: {
    canCheckTasks()
    {
      return this.userTarget == this.$session.user.id && this.isTodaySelected;
    },

    isTodaySelected()
    {
      return this.timeStamp == this.todayTimeStamp;
    },

    todayTimeStamp()
    {
      return Math.floor(this.$utils.TimeStamp() / 86400) * 86400;
    },
    
    tasksCount()
    {
      return this.tasks.length;
    }
  },

  mounted()
  {
    this.SetTodayDateTime();
  },

  methods: {
    SetTodayDateTime(refreshTasks = false)
    {
      if(this.isTodaySelected) {
        return;
      }

      this.timeStamp = this.todayTimeStamp;

      if(refreshTasks) {
        this.RefreshTasks();
      }
    },

    GoOneDayBefore()
    {
      if(!this.timeStamp) {
        return;
      }

      this.timeStamp -= 86400;
      this.RefreshTasks();
    },

    GoOneDayAfter()
    {
      if(this.timeStamp == this.todayTimeStamp) {
        return;
      }

      this.timeStamp += 86400;
      this.RefreshTasks();
    },


    SelectCategory(categoryName)
    {
      if(this.IsCategorySelected(categoryName)) {
        this.selectedCategory = null;
        return;
      }

      this.selectedCategory = categoryName;
      this.RefreshTasks();
    },

    IsCategorySelected(categoryName)
    {
      return this.selectedCategory == categoryName;
    },

    RefreshTasks()
    {
      this.tasks = [];
      this.RequestTasks();
    },

    RequestTasks()
    {
      if (!this.selectedCategory) {
        return;
      }

      this.waitResponse = true;

      const params = {
        timestamp: this.timeStamp
      }

      this.$api.get({ path: `users/${this.userTarget}/recurring-tasks/${this.selectedCategory}/state`, params }, this.OnTasksResponse);
    },

    OnTasksResponse({response})
    {
      const { task_list } = response.data;
      this.tasks = task_list;
      this.waitResponse = false;
    },

    CheckTask(index)
    {
      let task = this.tasks[index];

      this.$api.post({ path: `user/recurring-tasks/${task.task_id}:check ` }, ({response}) => { 
        const { task_state } = response.data;
        this.tasks[index] = task_state;
      });
    },

    IsTaskChecked(index)
    {
      if (!this.tasks[index]) 
        return false;

      return Boolean(this.tasks[index].checked_timestamp);
    },

    TaskPopupText(index)
    {
      const checked = this.tasks[index].checked_timestamp;
      if(!checked) {
        return this.$lan("rtask_tip_uncheck");
      }

      return `${this.$lan('rtask_tip')} ${this.$utils.TimeStampToString(checked)}`;
    },

    OnDateTimeChange()
    {
      this.RefreshTasks();
    },

    GetCategoryTitle(categoryName)
    {
      return this.$lan(`task_category_title_${categoryName.toLowerCase()}`);
    }
  }
}
</script>


<template>
  <div class="flex-box body" v-if="selected">
    <div class="navigation-box bordered-box">
      
      <div class="column flex-box date-controls">
        <icon-button class="no-bg" @click="GoOneDayBefore">
          chevron_left
        </icon-button>
        <icon-button class="no-bg" :disabled="isTodaySelected" @click="SetTodayDateTime(true)">
          today
        </icon-button>
        <icon-button class="no-bg" :disabled="isTodaySelected" @click="GoOneDayAfter">
          chevron_right
        </icon-button>
        
      </div>

      <div class="column">
        <date-input class="no-bg date-input" @change="OnDateTimeChange" v-model:value="timeStamp"  />
      </div>
    </div>

    <div class="task-list">
      <div v-for="category in taskCategories" :key="category">

        <div @click="SelectCategory(category)" class="task-category click-area">
          {{GetCategoryTitle(category)}}
          <loading class="loading-task" size="smallest" v-if="waitResponse && IsCategorySelected(category)" />
        </div>

        <div v-if="IsCategorySelected(category) && !waitResponse">
          <div v-if="!tasksCount" class="task-row empty-tasks border">
            {{ $lan("no_routine_tasks") }}
          </div>

          <div v-else>
            <div v-for="(task, index) in tasks" :key="index" :class="['task-row border', { 'checked': IsTaskChecked(index) }]" v-tooltip="TaskPopupText(index)">

              <check-box class="task-check-btn" :disabled="!canCheckTasks" @check="CheckTask(index)" :checked="IsTaskChecked(index)">
              </check-box>

              {{task.task_text}}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style scoped>
.body {
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-block: 10px;
  height: fit-content;
}

.task-list {
  width: 100%;
  border: none;
  max-height: 500px;
  overflow: auto;
}

.task-category {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: var(--text-color-strong);
  background-color: var(--box-color);
  border-radius: 30px;
  margin: 5px;
  box-shadow: var(--box-shadow);
}

.task-category:active {
  border-color: var(--theme-color);
}

.task-row {
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  animation: slide-right 0.2s;
  background-color: var(--box-color);
  border-radius: 20px;
  margin-block: 2px;
  width: 99%;
  margin-left: 1%;
  word-break: break-all;
  transition-duration: 0.1s;
  gap: 10px;
}

.checked {
  color: var(--theme-color) !important;
}

.empty-tasks {
  padding: 10px;
}

.task-check-btn {
  margin: 5px;
}

.loading-task {
  position: absolute;
  right: 15px;
}

.navigation-box {
  display: flex;
  gap: 5px;
  border-radius: 30px;
}

.date-controls {
  width: fit-content;
  gap: 10px;
}

.task-check-btn[disabled] {
  opacity: 1;
}

.date-input {
  border: none;
  border-radius: 5px;
}


@media  only screen and (max-width: 700px) {
  .body {
    height: 100%;
  }
  .task-list {
    max-height: none;
  }
  .navigation-box {
    width: 100%;
  }
  .navigation-box input {
    width: 100%;
  }
}
</style>

