
<script>
export default {
  name: "GroupFilter",
  emits: ['update'],

  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data() { return {
    groupsList: [],
    selectedGroups: []
  }},

  created() {
    this.selectedGroups = this.value;
  },


  methods: {
    RequestGroups()
    {
      this.$api.get({ path: "groups/" }, ({response}) => { 
        const { groups } = response.data;
        this.groupsList = groups;
      });
    },

    IsGroupChecked(groupId)
    {
      return Boolean(this.selectedGroups.find(id => groupId == id) != null);
    },

    CheckGroup(groupId)
    {
      this.selectedGroups.push(groupId);
      this.EmitUpdate();
    },

    UncheckGroup(groupId)
    {
      const index = this.selectedGroups.indexOf(groupId);
      if(index == -1) {
        return;
      }

      this.selectedGroups.splice(index, 1);
      this.EmitUpdate();
    },

    EmitUpdate()
    {
      this.$emit('update', this.selectedGroups);
    }
  }
}
</script>

<template>
  <drop-down @expand="RequestGroups">
    <button class="dropdow-btn no-bg ripple-effect">
      <icon> groups </icon> Grupuri
    </button>

    <template #content>
      <div class="box filter-dropwdown">
        <check-box class="filter-row" v-for="(group, index) in groupsList" :key="index" 
          :checked="IsGroupChecked(group.id)"
          @check="CheckGroup(group.id)"
          @unCheck="UncheckGroup(group.id)"
        >
          {{group.name}}
        </check-box>
      </div>
    </template>
  </drop-down>
</template>


<style scoped>
.filter-dropwdown {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.filter-row {
  padding: 10px;
}

.filter-row:hover {
  background-color: var(--box-color-blue);
}

.dropdow-btn {
  border-radius: 0;
}

@media (max-width: 700px) {
  .filter-dropwdown {
    max-height: none;
  }
}
</style>