<script>
import TaskCommentList from './TaskCommentList.vue';
import TaskLogList from './TaskLogList.vue';

const ACTIVITY_NAV = [
  {
    btn_text: "Comentarii",
    icon: "comment",
    componentName: "TaskCommentList"
  },
  {
    btn_text: "Istoric",
    icon: "history",
    componentName: "TaskLogList"
  }
];


export default {
  name: "TaskActivity",

  components: {TaskCommentList, TaskLogList},

  props: {
      taskId: {
        required: true,
        type: [Number, String]
      },
  },


  data() {return {
    activeComponent: "TaskCommentList",
    activityNav: ACTIVITY_NAV

  }},

  methods: {
    SetComponent(componentName)
    {
      this.activeComponent = componentName;
    }
  }

}
</script>

<template>
<div class="task-activity">
  <div class="navigation-bar group-buttons">
    <button class="no-bg" @click="SetComponent(nav.componentName)" v-for="nav in activityNav" :key="nav" :active="activeComponent == nav.componentName">
      <icon v-if="nav.icon">
        {{ nav.icon }}
      </icon>
      <span v-if="nav.btn_text">
        {{ nav.btn_text }}
      </span>
    </button>
  </div>

  <div class="navigation-body">
    <component :is="activeComponent" :task-id="taskId"></component>
  </div>
</div>
</template>


<style scoped>
.task-activity {
  width: 100%;
  height: 300px;
}
.navigation-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation-bar button {
  font-weight: normal;
  font-size: var(--normal-text);
  border-radius: 10px;
}

.navigation-bar button .icon {
  font-size: var(--large-text);
}


.navigation-bar button[active="true"] {
  background-color: var(--box-color-blue);
}

.navigation-body {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
}
</style>