<script>
import UserService from '../../Services/UserService';

export default {
  name: "UserSearchModal",

  data() {return {
    inputQuery: "",
    users: []
  }},

  computed: {
    params() {
      return this.$modal.params;
    },

  },

  mounted() {
    this.RequestUsers();
  },

  methods: {
    RequestUsers()
    {
      this.users = [];
      UserService.getUsersByName(this.inputQuery, this.OnRequestUsers);
    },

    OnRequestUsers(usersList)
    {
      this.users = usersList;
    },

    OnClickUser(user)
    {
      var onUserClick = this.UserIsPicked(user.id) ? this.params.onUserUnPick : this.params.onUserPick;

      if(!onUserClick) {
        console.warn(`UserSearchModal:OnClickUser: onUserPick has no bind!`);
        return;
      }

      onUserClick(user);
    },

    UserIsPicked(userId)
    {
      return Boolean(this.params.pickedUsers?.find((val) => val == userId));
    }
  }
}
</script>


<template>
  <div class="user-search-modal box">
    <input-box placeholder="Username" @input="RequestUsers" v-model:value="inputQuery"></input-box>

    <div class="context-details" v-if="params.detailsText">
      {{params.detailsText}}
    </div>
    <hr />
    <div class="user-list">
      <div class="user click-area" @click="OnClickUser(user)" v-for="user in users" :key="user" :checked="UserIsPicked(user.id)">
        <avatar :url="user.picture" :f-name="user.f_name" :s-name="user.s_name">
        </avatar>

        {{ user.f_name }} {{ user.s_name }}

        <icon class="done-icon">
          done
        </icon>
      </div>
    </div>
  </div>
</template> 


<style scoped>
.user-search-modal {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 400px;
  padding: 15px;
  margin-top: 50px;
  gap: 5px;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 500px;
  overflow: auto;
}

.user {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  gap: 5px;
  padding: 5px;
  border-radius: 20px;
}

.user[checked="true"] {
  background-color: var(--box-color-blue);
}

.user[checked="true"] .done-icon {
  display: flex;
}

.user:hover {
  background-color: var(--box-color-blue);
}

.done-icon {
  display: none;
  position:absolute;
  right: 10px;
  animation: pop .3s;
}

.user .avatar-container {
  width: 40px;
  height: 40px;
}

.context-details {
  padding: 10px;
  /* border: solid 1px var(--border-color); */
  background-color: var(--dark-box-color);
  border-radius: 20px;
  word-break: break-all;
  font-weight: bold;
  font-size: small;
}
</style>