<script>
import TextFormatter from './TextFormatter.vue';
// import MarkDownContainer from '../MarkDownContainer.vue';

export default {
  name: "RichTextArea",
  components: { TextFormatter },

  emits: ['input', 'update:value', 'blur', 'focus', 'paste'],

  props: {
    value: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: null
    },

    maxlength: {
      type: [String, Number],
      default: null,
    },

    canFormat: {
      type: [Boolean, String],
      default: true
    },

    spellcheck: {
      type: [Boolean, String],
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: [Boolean, String],
      default: false
    },

    canResize: {
      type: [Boolean, String],
      default: false
    },

    required: {
      type: [Boolean, String, Number],
      default: null
    }
  },

  data() { return {
    initialValue: "",
    focused: false,
  }},

  computed: {
    focusedAttr() {
      return this.focused ? "": null;
    },

    readOnlyAttr() {
      return this.readonly ? "": null;
    },

    resizableAttr() {
      return this.canResize ? "": null;
    },

    showFormatter() {
      return this.canFormat && this.canEditContent;
    },

    canEditContent() {
      return !this.disabled && !this.readonly;
    },
  },

  mounted() {
    this.SetContent(this.value);
  },

  methods: {
    SetContent(content)
    {
      this.initialValue = content;
    },

    OnFocus(e)
    {
      this.$emit('focus', e);
      this.focused = true;
    },

    OnBlur(e)
    {
      this.$emit('blur', e);
      this.focused = false;
    },

    OnInput(e)
    {
      this.EmitValueUpdate();
      this.$emit('input', e);
    },

    EmitValueUpdate()
    {
      this.$emit('update:value', this.htmlContent());
    },

    OnPaste(e)
    {
      let paste = (e.clipboardData || window.clipboardData).getData('text');
      if(paste.length + this.length() >= this.maxlength) {
        e.preventDefault();
        return;
      }

      this.$emit("paste", e);
    },

    OnCopy(e)
    {
      console.log(e);
    },

    OnKeyDown(e)
    {
      if(!this.canEditContent) {
        e.preventDefault();
      }

      if(this.maxLengthReached() && (!e.ctrlKey && e.code != "Backspace")) {
        e.preventDefault();
      }
    },

    maxLengthReached() {
      return this.maxlength != null && this.length() >= this.maxlength;
    },

    length()
    {
      return this.textContent().length;
    },

    textContent()
    {
      return (this.inputElement()?.innerText || "");
    },

    htmlContent()
    {

      return (this.inputElement()?.innerHTML || "");
    },

    clear()
    {
      this.initialValue = "";
      this.inputElement().innerHTML = "";
      this.EmitValueUpdate();
    },

    focus()
    {
      this.inputElement()?.focus();
    },

    inputElement() {
      return this.$refs.textArea;
    },

  }
}
</script>

<template>
  <div class="rich-text-area" 
    :focused="focusedAttr" 
    :readonly="readOnlyAttr" 
    :disabled="disabled"
  >

    <span class="placeholder" @click="focus">
      {{placeholder}}
    </span>

    <div class="textarea" v-html="initialValue"
      :spellcheck="spellcheck"
      :resizable="false"
      :required="required"

      @focus="OnFocus" 
      @blur="OnBlur" 
      @input="OnInput"
      @paste="OnPaste"
      @copy="OnCopy"
      @keydown="OnKeyDown"

      ref="textArea"

      contenteditable
    ></div>

    <text-formatter v-if="showFormatter" 
      :can-format="canFormat"
      :input-element="inputElement()"
      :length="length()"
    />

    <slot></slot>
  </div>
</template>

<style scoped>
.rich-text-area {
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: var(--input-bg-color);
  border: solid 1px var(--input-border-color);
  border-radius: var(--border-radius);
  overflow: auto;
  padding: 10px;
}

.rich-text-area:not([readonly])[focused] {
  border-color: var(--input-border-color-focus);
}

.rich-text-area .textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  resize: none;
  padding-bottom: 20px;
  border: none;
  background: none;
  color: var(--text-color);
  font-size: inherit;
  outline: none;
  word-wrap: break-word;
  word-break: break-all;
}

.rich-text-area[disabled][readonly] .textarea {
  caret-color: transparent;
}

.rich-text-area[focused] .textarea {
  overflow: auto;
}

.rich-text-area .textarea img {
  max-width: 100%;
}

.placeholder {
  opacity: .5;
  font-size: var(--text-normal);
  transition-duration: 0.1s;
  margin-bottom: 5px;
}

.rich-text-area[focused] .placeholder {
  opacity: .9;
}

.rich-text-area .textarea[resizable=true] {
  resize: both;
}

.textarea ul {
  margin: 0;
  padding: 0;
}
</style>