<script>
import { bodyScroll } from '../../utils';

export default {
  name: "DropDown",

  emits: ['expand'],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() { return {
    expanded: false
  }},

  computed: {
    expandedAttr()
    {
      return this.expanded ? "": null;
    }
  },

  methods: {
    ShowDropDown()
    {
      if(this.disabled) {
        return;
      }

      this.expanded = true;
      this.ToggleBodyScrollIfMobile();
      this.$emit('expand');
    },

    HideDropDown()
    {
      this.expanded = false;
      this.ToggleBodyScrollIfMobile();
    },

    ToggleBodyScrollIfMobile()
    {
      if(!this.$utils.isMobile()) {
        return;
      }

      bodyScroll.Set(this.expanded ? 'disabled': 'enabled');
    }
  }
}
</script>

<template>
  <div class="dropdown-container" :disabled="disabled" :expanded="expandedAttr">
    <div @mouseup="ShowDropDown" class="click-area">
      <slot></slot>
    </div>

    <div class="dropdown-body" v-off-bound:mousedown="HideDropDown" v-floating-child:no-responsive v-if="expanded">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped>
.dropdown-container {
  position: relative;
  /* width: fit-content;
  height: fit-content; */
}

.dropdown-body {
  position: fixed;
  z-index: 101;
  /* width: fit-content;
  height: fit-content; */
  /* animation: slide-down 0.1s ease-in; */
}
</style>