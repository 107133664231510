<script>
export default {
  name: "TheCookiesDialog",
  data() { return {
    show: true
  }},

  methods: {
    Accept()
    {
      this.$cookies.set("cookies", true, '15y');
      this.show = false;
    }
  }
}
</script>


<template>
  <div v-if="show" class="box cookie-dialog" ref="cookies-dialog">
    <div class="title">
      <icon>cookie</icon>
      Cookies
    </div>
    {{$lan("cookie_dialog_text")}}

    <button class="green" @click="Accept()">
      {{$lan("accept")}}
    </button>
  </div>
</template>

<style scoped>
.cookie-dialog {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 109;
  padding: 20px;
  animation: slide-up 0.3s;
  animation-delay: 1s;
  width: 400px;
  white-space:pre-wrap;
  line-height: 25px;
  font-weight: 520;
}

button {
  width: 100%;
  margin-top: 20px;
}

.title {
  font-size: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: var(--text-color);
}

.title .icon {
  font-size: 50px;
}

@media only screen and (max-width: 500px) {
  .cookie-dialog {
    width: auto;
    left: 20px;
    right: 20px;
  }
}
</style>