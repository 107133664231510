
<script>


export default {
  name: 'GroupCreateForm',
  emits: ['groupCreated'],

  data() { return {
    inputValue: "",
  }},

  methods: {
    CreateGroup()
    {
      if(!this.inputValue) {
        this.$refs.groupInput.Focus();
        return;
      }

      const params = {
        "group-name": this.inputValue
      };

      this.$api.post({ path: "groups/", params }, this.OnCreateResponse);
    },

    OnCreateResponse({response})
    {
      if(response.status != "success") {
        return;
      }

      this.inputValue = "";
      const { group_id } = response.data;

      this.OnGroupCreated(group_id);
      // this.SelectGroup(group_id);
    },

    OnGroupCreated(group_id)
    {
      this.$emit('groupCreated', group_id);
    }
  }
}
</script>
<template>
  <form @submit.prevent="CreateGroup" class="box-list-header header create-form">
    <title-bar>
      <h2>Grup nou</h2>
    </title-bar>
    <input-box ref="groupInput" class="name-input" v-model:value="inputValue" placeholder="Nume grup.." pattern="^[A-Za-z ]+$" maxlength="30">
      <icon-btn class="create-button no-bg" type="submit">add</icon-btn>
    </input-box>
  </form>  
</template>


<style scoped>
.header {
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 20px;
  /* padding: 5px; */
  box-shadow: none;
  /* border: var(--solid-border); */
}

.create-form {
  flex-direction: column;
  padding: 20px;
  gap: 20px;

}

.name-input {
  width: 100%;
  background-color: var(--dark-box-color);
  padding: 5px;
}

.create-button {
  font-size: medium;
  margin-right: 5px;
}
</style>