
import { globalState } from "./GlobalState";


export default {
  CheckDarkMode()
  {
    if(!this.IsDarkModeEnabled()) {
      return;
    }

    this.ToggleDarkMode();
  },

  IsDarkModeEnabled()
  {
    return localStorage.getItem("dark_mode") == "true";
  },

  SetDarkMode(flag, save = true)
  {
    let element = document.querySelector("html");
    if(!flag) {
      element.removeAttribute("dark");
    } 
    else {
      element.setAttribute("dark", true);
    }

    if(save) {
      localStorage.setItem("dark_mode", flag);
    }

    globalState.darkMode = flag;
  },

  ToggleDarkMode() 
  {
    const isDarkMode = globalState.darkMode;
    this.SetDarkMode(!isDarkMode);
  },
}


