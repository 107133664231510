

<script>
export default {
  name: "ConnectionError",

  computed: {
    visible() {
      return this.$global.connectionTimeout;
    }
  }
}
</script>

<template>
  <div class="connection-error" v-if="visible">
    <div class="error-container">
      Trying to reconnect
      <span class="material-icons loading-icon" style="font-size: 30px;">
        wifi
      </span>
    </div>
  </div>

</template>

<style scoped>
.connection-error {
  top: 0;
  left: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.596);
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.error-container {
  display: flex;
  position: relative;
  top: 50px;
  align-items: center;
  padding: 10px;
  background-color: indianred;
  color: white;
  font-weight: bold;
  animation: slide-down 0.3s;
  border-radius: 25px;
  gap: 10px;
}

.loading-icon {
  animation: push 2s infinite;
}
</style>