<script>
export default {
  name: "CheckBox",

  props: {
    checked: { 
      type: Boolean,
      required: true
    },

    disabled: {
      type: [String, Boolean],
      default: false,
    }
  },

  emits: ['unCheck', 'check'],

  methods: {
    OnClick()
    {
      if(this.disabled) {
        return;
      }

      let emitName = this.checked ? 'unCheck': 'check';
      this.$emit(emitName);
    }
  }
}
</script>

<template>
  <div class="checkbox-body" @click="OnClick" :disabled="disabled">
    <div class="checkbox" :ticked="checked">
      <span v-show="checked" class="material-icons">
        check
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<style scope>
.checkbox-body {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}

.checkbox-body[disabled='true'] {
  cursor: not-allowed;
}

.checkbox-body[disabled='true'] .checkbox:active {
  transform: none;
}

.checkbox {
  width: 5px;
  height: 5px;
  padding: 12px;
  border-radius: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--border-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.checkbox .material-icons {
  font-size: 16px;
  animation: zoom-in 0.1s;
}
/* 
.checkbox:hover {
  background-color: var(--button-color);
} */

.checkbox[ticked='true'] {
  background-color: var(--theme-color);
}

.checkbox:active, .checkbox-body:active .checkbox {
  transform: scale(0.95);
}
</style>