<script>

export default {
  name: "PriorityTasksHeader",

  emits: ["on-sort"],

  props: 
  {
    "sort-by": 
    {
      type: String,
      default: null,
    },

    "reverse-sort": 
    {
      type: [String, Boolean, Number],
      default: false,
    }
  },

  computed: 
  {
    arrowIcon() 
    {
      return this.reverseSort ? "expand_less": "expand_more";
    }
  },

  data() { return {
    fields: ["info", "deadline", "project_id", "author_id", "members", "status"]
  }},

  methods: 
  {
    SortBy(fieldName)
    {
      this.$emit("on-sort", fieldName);
    }
  
  }
}
</script>


<template>
<div class="box task-list-header">

  <div v-for="(field, index) in fields" :key="index" :class="['header-col', field]">
    <button class="no-bg sort-btn" @click="SortBy(field)" :active="sortBy == field">
      {{ $lan(`task_header_${field}_title`) }} 
      
      <icon class="arrow-icon">{{ arrowIcon }}</icon>
    </button> 
  </div>

</div>
</template>


<style scoped>
.task-list-header {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  flex-shrink: 0;
  gap: 25px;
  padding: 5px;
  border-radius: 15px;
  overflow: hidden;
}


.task-list-header > .header-col {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-basis: auto;

  flex-shrink: 0;
  flex-grow: 0;
}

.task-list-header > .info {
  width: 500px;
  min-width: 300px;
}

.task-list-header > .project_id {
  width: 300px;
}

.task-list-header > .members, .author_id {
  width: 200px;
}

.task-list-header > .deadline {
  width: 150px;
}


.sort-btn {
  margin: 0;
  padding: 10px;
  padding-inline: 15px;
  font-size: var(--normal-text);
}

.sort-btn[active="true"] {
  background-color: var(--box-color-blue);
}

.sort-btn .arrow-icon {
  padding: 0;
  margin: 0;
  display: none;
  font-size: var(--large-text);
}

.sort-btn[active="true"] .arrow-icon {
  display: initial;
}

@media screen and (max-width: 600px) {
  .task-list-header {
    display: flex;
    overflow-x: auto;
    gap: 5px;
  }

  .task-list-header > .info {
    width: fit-content;
    min-width: 0;
  }

  .task-list-header > .project_id {
    width: auto;
  }

  .task-list-header > .members, .author_id {
    width: auto;
  }

  .task-list-header > .deadline {
    width: auto;
  }

}

</style>