<script>
export default {
  name: "UserOptions",

  data() {return {
    showOptions: false
  }},

  computed: {
    id() {
      return this.$session.user.id;
    }
  },

  methods: {
    ToggleOptions()
    {
      this.showOptions = !this.showOptions;
    },

    HideOptions()
    {
      this.showOptions = false;
    },

    GoTo(location)
    {
      this.$router.push(location);
      this.HideOptions();
    },

    LogOut()
    {
      this.$session.Logout();
      this.HideOptions();
    },

    OpenModal(componentName, params = {})
    {
      this.$modal.Open(componentName, { context: this, params })
      this.HideOptions();
    }
  }
}
</script>

<template>
  <div class="user-options">
    <avatar :f-name="$session.user.name" :s-name="$session.user.sname" :url="$session.user.picture" class="click-area" @mouseup="ToggleOptions">
    </avatar>

    <div class="box flex-box options-list mobile-popup" v-off-bound:mousedown="HideOptions" v-floating-child v-if="showOptions">

      <div class="user-row">

        <button class="user-btn no-bg" @click="GoTo(`/user/${this.id}/edit`)">
          <avatar :user-id="$session.id" :f-name="$session.user.name" :s-name="$session.user.sname" :url="$session.user.picture" /> 

          <div class="user-info">
            <span class="user-name">
              {{$session.user.full_name}}
            </span>
            <span class="user-group">
              {{$session.user.groupData.name}}
             </span>
            <span class="user-group">
              {{$session.user.email}}
             </span>
          </div>
        </button>

      </div>
      <hr />

      <button class="no-bg" @click="GoTo(`/user/${this.id}/notifications_settings`)">
        <icon>edit_notifications</icon> {{$lan('user_option_notifications')}}
      </button>

      
      <button class="no-bg" @click="GoTo(`/user/${this.id}/overview`)">
        <icon>analytics</icon> Overview
      </button>

      <!-- <button class="no-bg" @click="GoTo(`/user/${this.id}/live_location`)">
        <icon>location_on</icon> {{$lan('user_option_location')}}
      </button> -->

      <!-- <button class="no-bg" @click="OpenModal('FurloughRequest')">
        <icon>free_cancellation</icon> {{$lan('user_option_furlough')}}
      </button> -->

      <hr />

      <button class="no-bg red" @click="LogOut">
        <icon>logout</icon> {{$lan('user_option_logoff')}}
      </button>

    </div>

  </div>
</template>


<style scoped>
.options-list {
  position: fixed;
  padding: 5px;
  animation: slide-down 0.1s;
  /* gap: 5px; */
  flex-direction: column;
  /* border-radius: 10px; */
}

.options-list button {
  min-width: 200px;
  justify-content: flex-start;
}

.user-row {
  /* margin-bottom: 10px; */
  padding: 2px;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.250); */
}

.user-btn {
  gap: 5px;
  font-size: large;
  padding: 5px;
  width: 100%;
}

.user-btn .avatar-container {
  width: 65px;
  height: 65px;
}

.user-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.user-name {
  font-size: large;
  color: var(--text-color-strong);
}

.user-group {
  font-size: small;
  /* line-height: 18px; */
  font-weight: normal;
}
</style>