<script>
import { TaskMemberService } from '@/Services/TaskService';
import UserService from '@/Services/UserService';

export default {
  name: "TaskMembersDropdown",

  props: {
    taskId: [Number, String]
  },

  data() { return {

    waitMembersRequest: true,
    searchQuery: "",
    taskMembers: [],
    searchResult: []
  }},

  computed: {
    members()
    {
      if (this.searchQuery)
      {
        return this.searchResult;
      }

      return this.taskMembers;
    }
  },

  mounted()
  {
    this.RequestTaskMembers();
  },

  methods: {
    OnExpand()
    {
      this.searchQuery = "";
    },

    RequestTaskMembers()
    {
      this.waitMembersRequest = true;
      TaskMemberService.getTaskMembers(this.taskId, (members) => {
        this.taskMembers = members;
        this.waitMembersRequest = false;
      });
    },

    IsMember(user)
    {
      return this.taskMembers.find((member) => user.id == member.id);
    },

    OnSearchInput()
    {
      UserService.getUsersByName(this.searchQuery, (userList) => {
        this.searchResult = userList;
      });
    },

    AddMember(user)
    {
      TaskMemberService.addTaskMember(this.taskId, user.id, () => {
        this.taskMembers.push(user);
      });
    },

    RemoveMember(user)
    {
      TaskMemberService.removeTaskMember(this.taskId, user.id, () => {
        const index = this.taskMembers.indexOf(user);
        if (index < 0) 
        {
          return;
        }
  
        this.taskMembers.splice(index, 1);
      });
    }
  }
}
</script>

<template>
<drop-down @expand="OnExpand">
  <div class="task-members-preview">
    <loading v-if="waitMembersRequest"></loading>

    <avatar click-effect="false"  :is-active="member.is_active" :url="member.picture" :f-name="member.f_name" :s-name="member.s_name" v-for="member, index in taskMembers.slice(0, 4)" :key="index">
    </avatar>
    
    <icon-btn class="no-bg bordered">
      unfold_more
    </icon-btn>
  </div>

    <template #content>
      <div class="box task-members">
        <input-box :placeholder="$lan('input_user_search_placeholder')" v-model:value="searchQuery" @input="OnSearchInput">
          <template #left>
            <icon>person</icon>
          </template>
        </input-box>

        <!-- <hr /> -->


        <div class="task-members-container">
  
          <div class="member-row" v-for="member, index in members" :key="index">
            <div class="user-container">
              <avatar :is-active="member.is_active" :url="member.picture" :f-name="member.f_name" :s-name="member.s_name">
              </avatar>

              {{ member.f_name }} {{ member.s_name }}
            </div>
            
            <div class="button-container">
              <icon-btn class="no-bg red" v-if="IsMember(member)" @click="RemoveMember(member)">
                delete
              </icon-btn>
              <icon-btn class="no-bg" v-else @click="AddMember(member)">
                person_add
              </icon-btn>
            </div>

          </div>

          <div class="member-row" v-if="searchQuery && !members.length">
            <div class="user-container">
              {{ $lan("user_search_no_result") }}
            </div>
          </div>

        </div>
      </div>
    </template>
  </drop-down>
</template>


<style scoped>
.task-members-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.task-members-preview > .avatar-container {
  width: 35px;
  height: 35px;
}


.task-members {
  padding: 5px;
  border: solid 1px var(--border-color);
  border-radius: 10px;
}

.task-members-container {
  max-height: 200px;
  overflow: auto;
}


.member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
}

.member-row > .user-container > .avatar-container {
  width: 35px;
  height: 35px;
}

.member-row > .user-container, .button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border-radius: 10px;
}

.member-row > .user-container {
  flex: 1;
}

.member-row:hover > .user-container {
  background-color: var(--dark-box-color);
}


.button-container:hover {
  background-color: var(--dark-box-color);

}

.member-row > .button-container > .icon-button {
  padding: 5px;
  height: 100%;
}

.task-members > .input-box {
  border-radius: 10px;
  margin-bottom: 5px;
}

.input-box .icon {
  margin-left: 7px;
}


</style>