<script>
import GroupFilter from './GroupFilter.vue';

const USER_SEARCH_FLAGS = ['online-only', 'with-active-tasks', 'with-task-from-me'];


export default {
  name: "UserSearchInput",

  components: { GroupFilter },
  emits: ['result', 'search', 'input', 'filterUpdate'],

  props: {
    canFilterByGroup: {
      default: true,
      type: Boolean
    },

    canFilter: {
      default: true,
      type: Boolean
    },

    value: {
      default: "",
      type: String,
      required: false
    },

    searchOnMount: {
      default: false,
      type: Boolean,
      required: false 
    },

    start: {
      default: 0,
      type: [Number,String]
    }
  },

  // watch: {
  //   start()
  //   {
  //     this.Search();
  //   }
  // },

  data() { return {
    inputString: "",
    focused: false,

    groups: [],
    flags: {}
  }},

  computed:
  {
    showFilters()
    {
      return this.$utils.isMobile() && !this.focused;
    },

    searchFlags()
    {
      return USER_SEARCH_FLAGS;
    },

    textQuery()
    {
      return this.inputString.replace(" ",'').trim();
    }
  },

  created() {
    this.LoadSearchFilters();
  },

  mounted() {
    if(this.searchOnMount) {
      this.Search();
    }
  },

  methods: {
    OnInput()
    {
      this.$emit('input', this.inputString);
      this.Search();
    },

    OnClearInput()
    {
      this.Search();
    },

    Search(param = {})
    {
      const params = Object.assign(this.GetSearchParams(), param);
      this.$emit('search');


      this.$api.get({ path: `users/`, params }, ({response}) => {
        const { user_list } = response.data;
        const { metadata } = response;

        this.$emit('result', { users: user_list, metadata });
      });
    },

    GetSearchParams()
    {
      let params = {
        'q': this.textQuery || null,
        'groups': this.groups || null,
        'start': this.start,
      };

      Object.assign(params, this.flags);

      return params;
    },

    LoadSearchFilters()
    {
      this.LoadFlagsFilter();
      this.LoadGroupsFilter();
    },

    OnGroupFilterUpdate()
    {
      this.SaveGroupsFilter();
      this.Search();
    },

    SaveGroupsFilter()
    {
      localStorage.setItem('user_search_groups', JSON.stringify(this.groups));
    },

    LoadGroupsFilter()
    {
      const storageGroups = localStorage.getItem("user_search_groups");
      this.groups = storageGroups ? JSON.parse(storageGroups) : [];
    },

    IsFlag(flagName)
    {
      return this.flags[flagName] != null;
    },

    AddFlag(flagName)
    {
      this.flags[flagName] = true;
      this.SaveFlags();
      this.Search();
    },

    RemoveFlag(flagName)
    {
      delete this.flags[flagName];
      this.SaveFlags();
      this.Search();
    },

    LoadFlagsFilter()
    {
      const storageFlags = localStorage.getItem("user_search_flags");
      this.flags = storageFlags ? JSON.parse(storageFlags) : {};
    },

    SaveFlags()
    {
      localStorage.setItem("user_search_flags", JSON.stringify(this.flags))
    }
  }
}
</script>

<template>
<div class="user-search" @click="Focus" :focused="focused">

  <div class="flex-box search-filters">
    <div v-if="canFilter" class="search-collumn">
      <drop-down>
        
        <button class="dropdow-btn no-bg ripple-effect" @mouseup="ShowDropDown">
          <icon> filter_list </icon> {{$lan("btn_filter")}}
        </button>

        <template #content>
          <div class="box filter-dropwdown">
            <check-box v-for="flag in searchFlags" :key="flag" class="filter-row" 
              :checked="IsFlag(flag)" 
              @check="AddFlag(flag)" 
              @un-check="RemoveFlag(flag)"
            >
              {{$lan(flag)}}
            </check-box>
          </div>
        </template>
      </drop-down>
    </div>

    <div v-if="canFilterByGroup" class="search-collumn">
      <group-filter :value="groups" @update="OnGroupFilterUpdate"></group-filter>
    </div>
  </div>
  
  <search-input class="search-input"
    v-model:value="inputString" 
    @input="OnInput" 
    @clear="OnClearInput"
    @focus="focused = true"
    @blur="focused = false"
    placeholder="Search users..." 
    maxlength="30"
  />
</div>
</template>

<style scoped>
.user-search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.filter-dropwdown {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* width: 400px; */
  max-height: 200px;
  overflow: auto;
}

.search-input {
  border: none;
  background-color: var(--dark-box-color);
  margin-right: 10px;
}

.search-collumn {
  border-right: solid 1px var(--border-color);
  /* padding-inline: 10px; */
}

.filter-row {
  padding: 10px;
  width: 300px;
}

.filter-row:hover {
  background-color: var(--box-color-blue);
}

.search-filters {
  width: fit-content;
}

.dropdow-btn {
  border-radius: 0;
}

@media (max-width: 700px) {
  .search-filters {
    transition-duration: 0.2s;
  }
  .user-search[focused=true] .search-filters {
    width: 0px;
    overflow: hidden;
  }

  .filter-row {
    width: 100%;
  }
}
</style>