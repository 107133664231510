<script>
import CopyBtn from './CopyBtn.vue';

export default {
  name: "InputBox",

  components: { CopyBtn },
  emits: ['update:value', 'focus', 'blur', 'input'],

  props: {

    name: {
      type: String,
      default: null
    },

    placeholder: {
      default: "",
      type: String
    },

    value: {
      default: "",
      type: String
    },

    maxlength: {
      default: null,
      type: [Number, String]
    },

    autoFocus: {
      default: false,
      type: [Boolean, String, Number]
    },

    spellcheck: {
      default: false,
      type: [Boolean, String, Number]
    },

    pattern: {
      type: [ RegExp, String ],
      default: null
    },

    type: {
      type: String,
      default: null
    },

    required: {
      type: [String, Boolean, Number],
      default: null
    },

    copyContent: {
      default: null,
      type: String
    }
  },

  watch: {
    value(newVal) {
      this.inputString = newVal;
    },
  },

  data() { return {
    inputString: "",
    focused: false,
  }},

  computed: {
    focusedAttr()
    {
      return this.focused ? "": null;
    },

    inputLength() {
      return this.inputString.length;
    }
  },

  mounted()
  {
    this.CheckAutoFocus();
  },

  methods: {
    CheckAutoFocus()
    {
      if(this.autoFocus) {
        this.Focus();
      }
    },

    Focus() 
    {
      this.$refs.input.focus();
    },

    OnFocus(e)
    {
      this.focused = true;
      this.$emit('focus', e);
    },

    OnBlur(e)
    {
      this.focused = false;
      this.$emit('blur', e);
    },

    OnUpdate(e) 
    {
      this.$emit('update:value', this.inputString);
      this.$emit('input', e);
    },

    input() 
    {
      return this.$refs.input;
    }
  },

}
</script>

<template>
  <div class="input-box" :focused="focusedAttr">
    <div class="content left" v-if="$slots.left">
      <slot name="left"></slot>
    </div>

    <input :spellcheck="spellcheck" class="input" ref="input" 
      @focus="OnFocus"
      @blur="OnBlur"
      @input="OnUpdate"
      v-model="inputString" 
      :placeholder="placeholder" 
      :maxlength="maxlength"
      :pattern="pattern"
      :type="type"
      :name="name"
      :required="required"
    />

    <div class="content">
      <slot></slot>

      <copy-btn v-if="copyContent" :value="copyContent">
      </copy-btn>
    </div>
  </div>

</template>

<style scoped>
.input-box {
  display: flex;
  position: relative;
  border: solid 1px var(--border-color);
  border-radius: 20px;
  align-items: center;
  /* padding-inline: 5px; */
  user-select: none;
}

.input-box[focused] {
  border-color: var(--theme-color);
}

.input-box input {
  border: none;
  background: none;
  width: 100%;
  flex-grow: 1;
  height: inherit;
}

.content {
  display: flex;
  align-items: center;
  width: fit-content;
}

.copy-btn {
  display: none;
  font-size: medium;
  margin-right: 5px;
}

.input-box:hover .copy-btn {
  display: flex;
}
</style>