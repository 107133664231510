
<script>
import { TaskFilter } from '@/enums';

import PriorityTasksHeader from './PriorityTasksHeader.vue';
import TaskRow from '@/components/Task/TaskRow.vue';

import { TaskService } from '@/Services/TaskService';



export default {
  name: 'PriorityTasks',

  components: { PriorityTasksHeader, TaskRow },

  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    userTarget: {
      type: [Number, String],
      required: true
    },
    
  },

  data() { return {
    taskList: [],

    waitResponse: false,
    showLoadTasksBtn: false,

    filter: TaskFilter.ALL,

    isReverseOrder: false,
    sortBy: null,

    query: "",

    reloadingTasks: false
  }},

  computed: {
    tasksCount()
    {
      return this.taskList.length;
    },

    showLoadMoreBtn()
    {
      return !this.waitResponse && this.showLoadTasksBtn;
    },


    page()
    {
      return Math.floor(this.tasksCount / 10);
    },

    taskFilters()
    {
      return TaskFilter;
    }
  },

  created()
  {
    this.LoadStoredSort();
    this.LoadStoredFilters();
    this.RequestTasks();
    this.OpenTaskUrl();
  },

  methods: {
    OpenTaskUrl()
    {
      const taskId = this.$route.query.task_id;
      if(!taskId) {
        return;
      }

      const params = {
        taskId
      };

      this.$modal.Open("TaskPreviewModal", { context: this, params });
    },

    SortBy(flag)
    {
      if(flag == this.sortBy) 
      {
        this.isReverseOrder = !this.isReverseOrder;
      } 
      else 
      {
        this.isReverseOrder = false;
      }

      this.sortBy = flag;
      this.OnSortByChanged();
    },

    OnSortByChanged()
    {
      window.localStorage.setItem(`tasks:${this.userTarget}:sort-by`, this.sortBy);
      window.localStorage.setItem(`tasks:${this.userTarget}:reversed-sort`, this.isReverseOrder);

      this.ReloadTaskList();
    },

    LoadStoredSort()
    {
      this.sortBy = window.localStorage.getItem(`tasks:${this.userTarget}:sort-by`);
      this.isReverseOrder = window.localStorage.getItem(`tasks:${this.userTarget}:reversed-sort`);
    },

    LoadStoredFilters()
    {
      if(this.userTarget != this.$session.user.id) {
        return;
      }

      const filter = window.localStorage.getItem('filter');
      if(!filter) {
        return;
      }

      this.filter = filter;
    },

    OnChangeFilters()
    {
      window.localStorage.setItem("filter", this.filter);
      this.ReloadTaskList();
    },

    ReloadTaskList()
    {
      if(this.waitResponse)
      {
        return;
      }

      this.showLoadTasksBtn = false;
      this.reloadingTasks = true;
      this.taskList = [];
      this.RequestTasks();
    },

    LoadMoreTasks()
    {
      this.reloadingTasks = false;
      this.RequestTasks();
    },

    RequestTasks()
    {
      this.waitResponse = true;

      const params = {
        query: this.query,
        userId: this.userTarget,
        page: this.page,
        filter: this.filter,
        sortAttribute: this.sortBy,
        reversedSort: this.isReverseOrder
      };

      TaskService.getTasksByUser(params, (taskList) => {
        this.waitResponse = false;

        if(this.reloadingTasks) 
        {
          this.taskList = taskList;
        }
        else
        {
          this.taskList = this.taskList.concat(taskList);
        }

        this.showLoadTasksBtn = taskList.length >= 10;
      });

    },

    OnRemoveTask(index)
    {
      this.taskList.splice(index, 1);
    }
  }

}
</script>

<template>
<div>
  <div class="task-controls box">
    <div class="col">
      <icon-button class="refresh-btn no-bg" @click="ReloadTaskList">
        refresh
      </icon-button>
    </div>

    <div class="col">
      <input-box :placeholder="$lan('task_search_input_placeholder')" class="query-input" v-model:value="query" @input="ReloadTaskList">
        <template #left>
          <icon>search</icon>
        </template>
      </input-box>
    </div>

    <div class="col">
      <select name="filter" class="input filter-pick no-bg ripple-effect" @change="OnChangeFilters()" v-model="filter">
        <option v-for="taskFilter in taskFilters" :key="taskFilter" :value="taskFilter">
          {{ $lan(`task_filter_${taskFilter}`) }}
        </option>
      </select>
    </div>

  </div>

  <PriorityTasksHeader @on-sort="SortBy" :sort-by="sortBy" :reverse-sort="isReverseOrder">
  </PriorityTasksHeader>


  <message-box v-if="!tasksCount && !waitResponse" class="message-box no-results">
    {{ $lan('no_results') }}
  </message-box>


  <div class="tasks-list" v-else>

    <TaskRow v-for="(task, i) in taskList" :key="i"
      :index="i"
      :initial-task-data="task"
    >
    </TaskRow>

    <button v-if="showLoadMoreBtn" 
      @click="LoadMoreTasks" 
      style="margin: auto; margin-block: 30px; display: block; width: 100px;" 
      class="button no-bg border"
    > 
    {{$lan('load_more_btn')}} 
    </button>

    <div v-if="waitResponse" class="center-box">
      <loading></loading>
    </div>

  </div>
</div>
</template>


<style scoped>

.tasks-list {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  height: 600px;
  padding-block: 5px;
  padding-inline: 1px;
  overflow: auto;
  gap: 5px;
  border-radius: 20px;
}

.query-input {
  border-radius: 10px;
}

.query-input .icon {
  margin-left: 7px;
}

.no-scroll {
  overflow: hidden;
  /* height: 700px; */
}

.filter-pick {
  position: relative;
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  font-size: var(--normal-text);
}

.no-results {
  width: 100%;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  font-size: var(--normal-text);
}

.task-controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  border-radius: 15px;
  padding: 5px;
  gap: 5px;
}

.task-controls .col {
  padding: 1px;
}

.toggle-container {
  min-width: 200px;
  padding-inline: 10px;
}

.message-box {
  margin-top: 5px;
}

@media screen and (max-width: 1200px) {
  .tasks-list {
    max-height: 600px;
    height: 100%;
  }
}
</style>

