<script>
import { TaskSubscriptionService } from '@/Services/TaskService';

export default {
  name: "TaskNotificationButton",

  props: {
    taskId: {
      required: true,
      type: [Number, String]
    }
  },

  computed: {
    iconBtn() {
      return this.isSubsribed ? 'notifications_active': 'notifications_off';
    }
  },

  data() { return {
    isSubsribed: false
  }},

  mounted()
  {
    this.RequestSubscriptionStatus();
  },

  methods: {
    RequestSubscriptionStatus()
    {
      TaskSubscriptionService.getTaskSubscriptionStatus(this.taskId, this.OnTaskSubscriptionStatus);
    },

    OnTaskSubscriptionStatus(subscribed)
    {
      this.isSubsribed = subscribed;
    },

    ToggleSubscriptionStatus()
    {
      TaskSubscriptionService.toggleTaskSubscriptionStatus(this.taskId, this.OnToggleSubscription);
    },

    OnToggleSubscription(subscribed)
    {
      this.isSubsribed = subscribed;
    }
  }

}
</script>

<template>
<icon-btn @click="ToggleSubscriptionStatus()">
  {{ iconBtn }}
</icon-btn>
</template>
