

<script>
export default {
  name: 'GroupPlate',
  emits: ['loaded'],

  props: {
    id: {
      type: [String, Number],
      default: null,
      required: false
    },

    data: {
      type: Object,
      default: null,
      required: false
    },

    editOnClick: {
      type: [Boolean, String, Number],
      default: false
    },

    showIcon: {
      type: [Boolean, Number],
      default: true
    }
  },

  data() { return {
    groupData: null
  }},

  watch: {
    id() {
      this.RequestGroupData();
    }
  },

  computed: {
    groupId() {
      return this.id || this.groupData.id;
    },

    groupName() {
      return this.groupData?.name || "Loading";
    },

    groupIcon() {
      return (this.groupData?.icon || 'group');
    }
  },

  created() {
    this.Init();
  },

  methods: {
    Init()
    {
      if(this.RequestGroupData()) {
        return;
      }

      this.groupData = this.data;
    },

    RequestGroupData()
    {
      if(this.id == null) {
        return false;
      }

      this.$api.get({ path: `groups/${this.id}` }, this.OnResponseGroupData)
      return true;
    },

    OnResponseGroupData({response})
    {
      const { group } = response.data;
      this.groupData = group;
      this.$emit('loaded');
    },

    EditGroup()
    {
      if(!this.editOnClick) {
        return;
      }

      this.$router.push({ name: "users_page", query: { "group-id": this.groupId } });
    }
  }
}
</script>

<template>
  <div :class="[ 'group-plate click-area', { 'red-plate': groupData?.root_access } ]" @click="EditGroup">
    <icon v-if="showIcon">{{groupIcon}}</icon>
    <label>{{groupName}}</label>
  </div>

</template>


<style scoped>
.group-plate {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  color: var(--theme-color);
  padding: 3px 15px;
  user-select: none;
  font-weight: bold;
  font-size: medium;
  border: solid 1px var(--border-color);
  background-color: var(--box-color2);
}

.group-plate:hover {
  background-color: var(--theme-color);
  color: white;
}

.group-plate .icon {
  padding-right: 5px;
}

.red-plate {
  color: red;
}

.red-plate:hover {
  background-color: red;
  /* color: white; */
}
</style>