<script>
export default {
  name: "ButtonGroups",
}
</script>

<template>
<div class="button-groups">
  <slot></slot>
</div>
</template>



<style>
.button-groups {
  display: flex;
  gap: 1px;
}

.button-groups button:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.button-groups button:last-child {
  border-end-start-radius: 0;
  border-start-start-radius: 0;
}

.button-groups button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

</style>