



export default {
  created(el, binding) {

    el.offBoundEventName = binding.arg;
    el.offBoundEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };

    document.documentElement.addEventListener(el.offBoundEventName, el.offBoundEvent);
  },

  beforeUnmount(el) {
    document.documentElement.removeEventListener(el.offBoundEventName, el.offBoundEvent)
  },
}

