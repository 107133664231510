<script>
import LoadingBox from '../../Common/LoadingBox.vue';
export default {
  components: { LoadingBox },
  name: "TaskMembersModal",

  data() {return {
    members: [],
    waitResponse: true,
  }},

  computed: {
    taskId() {
      return this.$modal.params.taskId;
    },
  },

  created() {
    this.loadMembers();
  },

  methods: {
    loadMembers()
    {
      this.waitResponse = true;
      const params = { advanced: true };
      this.$api.get({ path: `/tasks/${this.taskId}/members`, params }, ({response}) => {
        const { members } = response.data;
        this.members = members;
        this.waitResponse = false;
      });
    },

  }


}
</script>

<template>
  <modal title="Utilizatori">
    <div class="m-list">
      <div v-for="user, index in members" :key="index">
        <user-row :user-data="user">
          <!-- <div>class</div> -->
        </user-row>
      </div>

      <loading-box v-if="waitResponse"></loading-box>
  </div>
  </modal>
</template> 


<style scoped>
.m-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  gap: 5px;
}
</style>