
export const GroupPermission = {
  MANAGE_TASKS: "manage_tasks",
  MANAGE_USERS: "manage_users",
  MANAGE_APP: "manage_app",
  MANAGE_GROUPS: "manage_groups",
  MANAGE_PROJECTS: "manage_projects"
}

export const TaskStatus = {
  ASSIGNED: "ASSIGNED",
  COMPLETED: "COMPLETED",
  ONGOING: "ONGOING",
  PROGRAMMED: "PROGRAMMED"
}


export const HttpStatus = {
  OK: "success",
  FAIL: "fail",
  ERROR: "error"
}

export const TaskOptionCommand = {
  DELETE: "delete",
  ARCHIVE: "archive",
  DELEGATE: "delegate"
}

export const TaskUserAction = {
  COMMENT: "comment_task",
  DELEGATE: "delegate_task",
  CREATE: "create_task",
  ARCHIVE: "archive_task",
  DELETE: "delete_task",
  UPDATE_INFO: "update_task_info",
  UPDATE_DEADLINE: "update_task_deadline",
  UPDATE_STATUS: "update_task_status",
  ADD_TO_PROJECT: "add_task_to_project",
  ADD_MEMBER: "add_member",
  REMOVE_MEMBER: "remove_member"
};


export const TaskFilter = {
  ALL: "all",
  ACTIVE: "active",
  ARCHIVED: "archived",
  EXPIRED: "expired"
}

