<script>
export default {
  name: "Title",
}
</script>

<template>
  <div class="title-bar">
    <slot></slot>
  </div>
</template>
