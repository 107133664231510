
import { reactive } from '@vue/reactivity';
import { TranslateByKey } from '../TranslateManager';

import PopQuestion from '../../components/StaticComponents/PopQuestion';

import { bodyScroll } from '../../utils.js';

import TaskCreateForm from '../../components/TasksView/PriorityTask/Modals/TaskCreateForm.vue';
import ProjectCreateForm from '../../components/ProjectView/ProjectCreateForm';
import FurloughRequest from '../../components/Furlough/FurloughRequest.vue';

const comps = {
  "taskForm": TaskCreateForm,
  "popQuestion": PopQuestion,
  "projectCreateForm": ProjectCreateForm,
  FurloughRequest
}

class PopupManager {
  __init()
  {
    this.ctx = null;
    this.args = {};
    this.popSelected = "";
    
    this.messages = {};
  }
  
  constructor()
  {
    this.__init(); 
  }

  Open(component, args = {})
  {
    bodyScroll.Disable();
    this.args = args;
    this.popSelected = component;
  }

  Hide()
  {
    bodyScroll.Enable();
    this.popSelected = "";
    this.args = {};
  }

  get isVisible()
  {
    return Boolean(this.popSelected);
  }

  // task
  openTaskForm(targetUser)
  {
    this.Open('taskForm', { targetUser });
  }

  openTaskView(taskData)
  {
    this.Open('taskPreview', { taskData });
  }

  // Project
  openProjectForm()
  {
    this.Open('projectCreateForm');
  }

  // question
  question(title, question, acceptFunc)
  {
    this.popSelected = "popQuestion";
    this.args.title = title;
    this.args.text = question;
    this.args.acceptFunc = acceptFunc;
  }

  tquestion(title, question, acceptFunc)
  {
    this.popSelected = "popQuestion";
    this.args.title = TranslateByKey(title);
    this.args.text = TranslateByKey(question);
    this.args.acceptFunc = acceptFunc;
  }

}

export let popupManager = reactive(new PopupManager());

export default {
  install(app)
  {
    app.config.globalProperties.$pop = popupManager;

    for(var key in comps)
    {
      app.component(key, comps[key]);
    }
  }
}

