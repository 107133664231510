<script>
import { TaskLogService } from '@/Services/TaskService';
import TaskLog from './TaskLog.vue';

export default {
  name: "TaskLogList",

  components: {TaskLog},

  props: {
    taskId: {
      required: true,
      type: [Number, String]
    }
  },

  computed: {
    showLoadMore()
    {
      return !this.waitResponse && !(this.logs.length % 10);
    }
  },

  data() {return {
    waitResponse: true,
    page: 0,

    logs: []
  }},

  mounted()
  {
    this.RequestLogs();
  },

  methods: {
    RequestLogs()
    {
      this.waitResponse = true;
      TaskLogService.getTaskLogs(this.taskId, this.page, this.OnTaskLogs);
    },

    OnTaskLogs(taskLogs, success)
    {
      this.waitResponse = false;
      if(!success) {
        return;
      }

      this.page++;
      this.logs = this.logs.concat(taskLogs);
    }
  }
}
</script>

<template>
<div>
  <div class="log-list">
    <TaskLog :id="log.id" :author="log.author" :created="log.created" :action="log.action" v-for="log in logs" :key="log.id">
    </TaskLog>

    <loading-box v-if="waitResponse">
    </loading-box>

    <button class="no-bg load-more-btn" @click="RequestLogs" v-if="showLoadMore">
      {{ $lan('load_more_btn') }}
      <icon>expand_more</icon>
    </button>
  </div>

</div>
</template>



<style scoped>
.log-list {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  padding: 5px;
}

.load-more-btn {
  margin-inline: auto;
  border-radius: 10px;
}
</style>