<script>

export default {
  name: 'PoupQuestion',

  methods: {
    accept()
    {
      this.$pop.args.acceptFunc();
      this.$pop.Hide();
    }
  },
}
</script>

<template>
  <div class="popup-box mobile-popup">
    <div class="body">
      <h2>{{$pop.args.title}}</h2>
      <span v-html="$pop.args.text"></span>
    </div>

    <div class="question-footer">
      <button class="green-btn accept-btn" @click="accept">
        <icon>check</icon> {{ $lan("accept") }}
      </button>
      <button class="red no-bg" @click="$pop.Hide">
        <icon>close</icon> {{ $lan("close") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.body {
  padding: 20px;
}

.body h2 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
}

.question-footer {
  display: flex;
  padding: 20px;
  gap: 20px;
  /* justify-content: space-around; */
  /* background-color: var(--dark-box-color); */
  /* border-top: solid 1px var(--border-color); */
}

.question-footer button {
  padding: 10px;
  padding-right: 15px;
  border-radius: 10px;
  font-weight: bold;
  gap: 5px;
  font-size: var(--normal-text);
}

.question-footer button .icon {
  padding: 0;
  margin: 0;
  font-size: var(--normal-icon);
}
</style>

