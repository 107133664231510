<script>
export default {
  name: 'LoadButton',

  props: {
    active: {
      default: false,
      type: [Boolean, Number],
      required: true
    }
  },

  computed: {
    loadingHiddenAttr() {
      return this.active ? null: "";
    }
  }
}
</script>


<template>
  <button>
    <div v-if="!active">
      <slot></slot>
    </div>

    <loading size="smallest" v-if="active" />
  </button>
</template>

