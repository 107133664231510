
import CheckBox from './CheckBox.vue';
import DropDown from './DropDown.vue';
import SearchInput from './SearchInput.vue';
import UserSearchInput from './UserSearchInput.vue';
import Loading from './Loading.vue';
import InputBox from './InputBox.vue';
import IconButton from './IconButton.vue';
import IconBtn from './IconButton.vue';
import Icon from './Icon.vue';
import ResultBox from './ResultBox.vue';
import RichTextArea from './RichTextArea.vue';
import RichTextarea from './RichTextArea.vue';

import Avatar from './Avatar.vue';
import CollapseBox from './CollapseBox.vue';
import UserActions from './UserActions.vue';
import ToggleButton from './ToggleButton.vue';
import UserRow from './UserRow.vue';
import LoadingBox from './LoadingBox.vue';
import Comment from './Comment.vue';
import GroupPlate from './GroupPlate.vue';
import Modal from './Modal.vue';
import MessageBox from './MessageBox.vue';
import DisabledFeature from './DisabledFeature.vue';
import Title from './Title.vue';
import DateInput from './DateInput.vue';
import ButtonGroups from './ButtonGroups.vue';


const commonComponents = { CheckBox, DropDown, SearchInput, UserSearchInput,
  Loading, InputBox, IconButton, IconBtn, Icon, ResultBox, RichTextArea, RichTextarea, Avatar, CollapseBox,
  UserActions, ToggleButton, UserRow, LoadingBox, Comment, GroupPlate, Modal, MessageBox, DisabledFeature, Title,
  DateInput, ButtonGroups
};


export default 
{
  install(app)
  {
    for(let k in commonComponents) {
      app.component(k, commonComponents[k]);
    }
  }
}





