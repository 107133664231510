
<script>


export default {
  name: 'GroupPicker',

  props: ['userGroup', 'userTarget'],
  emits: ['groupUpdated'],

  data() { return {
    showPop: false,
    groups: [],
  }},

  computed: {
    hasAccess() {
      return this.$session.HasAccess(['manage_users', 'manage_groups']);
    }
  },

  methods: {
    LoadGroups()
    {
      this.$api.get({ path: "groups/" }, this.OnGroupsResponse);
    },

    OnGroupsResponse({response})
    {
      const { groups } = response.data;
      this.groups = groups;
    },

    CanPickGroup(index)
    {
      return this.groups[index].position > this.$session.user.privilege;
    },

    UpdateUserGroup(groupId)
    {
      const params = {
        "group-id": groupId
      };

      this.$api.post({ path: `users/${this.userTarget}:update-group`, params }, this.OnUpdateGroupResponse);
    },

    OnUpdateGroupResponse({response})
    {
      const { group } = response.data;
      this.$emit("groupUpdate", group);
    }
  },

}
</script>

<template>
<drop-down @expand="LoadGroups" :disabled="!hasAccess">
  <slot></slot>

  <template #content>
    <div class="box group-list">
      <div v-for="(group, index) in groups" :key="index" 
        @click="UpdateUserGroup(group.id)"
        :class="['row-group click-area', { 'group-checked' : userGroup == group.id }, { 'disabled-group' : !CanPickGroup(index) }]" :picked="userGroup == group.id" 
      >
        {{group.name}}
      </div>
    </div>
  </template>
</drop-down>
</template>


<style scoped>

.group-list {
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-width: 200px;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.row-group {
  width: 100%;
  border-radius: var(--border-radius);
  padding: 15px;
  padding-right: 30px;
  font-weight: 600;
  position: relative;
}

.row-group::before {
  display: none;
  content: "";
  width: 10px; height: 10px;
  border-radius: 50%;
  position: absolute;
  border: solid 4px var(--border-color);
  /* background-color: var(--border-color); */
  right: 20px;
}

.row-group[picked='true']::before {
  display: initial;
  border-color: var(--theme-color);
}

.row-group:hover::before {
  display: initial;
}

.row-group:hover, .group-checked {
  background-color: var(--box-color-blue);
}

.disabled-group {
  cursor: not-allowed;
  opacity: .5;
}
</style>

