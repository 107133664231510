<script>
import { TaskCommentService } from '@/Services/TaskService';


import TaskComment from './TaskComment.vue';


export default {
  name: "TaskCommentList",

  components: {TaskComment},

  props: {
    taskId: {
      required: true,
      type: [Number, String]
    },

  },

  computed: {
    commentsLength() {
      return this.comments.length;
    }
  },

  data() { return {
    showLoadMore: false,
    inputValue: "",
    waitResponse: true,
    page: 0,

    comments: []
  }},

  mounted() {
    this.RequestTasks();
  },

  methods: {
    RequestTasks()
    {
      this.waitResponse = true;

      TaskCommentService.getTaskComments(this.taskId, this.page, this.OnReceiveComments);
    },

    OnReceiveComments(comments, success)
    {
      this.waitResponse = false;
      if(!success)
      {
        return;
      }

      this.page++;
      this.comments = this.comments.concat(comments);
      this.showLoadMore = comments.length && comments.length % 5 == 0;
    },

    PostComment(event)
    {
      if(event.shiftKey) {
        return;
      }
      else if(!this.inputValue)
      {
        this.$refs.inputElement?.focus();
        return;
      }

      event.preventDefault();
      const inputValue = this.GetValidatedInputValue();
      if(!inputValue)
      {
        return;
      }
      TaskCommentService.postTaskComment(this.taskId, inputValue, this.OnCommentPosted);
    },

    OnCommentPosted(newComment, success)
    {
      if(!success)
      {
        return;
      }

      this.comments.unshift(newComment);
      this.ResetInput();
    },

    ResetInput()
    {
      this.$refs.inputElement?.clear();
    },

    GetValidatedInputValue()
    {
      return this.inputValue.trim();
    },
  },
}
</script>

<template>
  <div class="task-comments-container">
    <div class="comment-box">
      <rich-text-area class="comment-textarea" type="text" maxlength="10000" ref="inputElement"
        :placeholder="$lan('task_comment_textarea_placeholder')" 
        @keydown.enter="PostComment" 
        v-model:value="inputValue" 

        :canFormat="false"
        spellcheck="false"
      ></rich-text-area>

      <icon-button @click.left="PostComment" class="send-btn no-bg">
        send
      </icon-button>
    </div>

    <div class="comments-list">
      <TaskComment :id="comment.id" :author="comment.author" :data="comment" v-for="comment in comments" :key="comment">
      </TaskComment>

      <loading-box v-if="waitResponse">
      </loading-box>

      <!-- <button v-if="showLoadMore" @click="RequestTasks" class="no-bg border load-more-btn">
        {{ $lan("load_more_btn") }}
      </button> -->

      <button @click="RequestTasks" class="no-bg load-more-btn" v-if="showLoadMore">
      {{ $lan('load_more_btn') }}
      <icon>expand_more</icon>
    </button>

    </div>
  </div>
</template>



<style scoped>
.task-comments-container {
  display: flex;
  flex-direction: column;
}

.comment-box {
  width: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  margin-block: 10px;
}

.comment-box > div {
  flex: 1 1 0;
}

.comment-textarea {
  border-color: var(--border-color);
  max-height: 600px;
  width: 2px;
}

.comments-list {
  overflow: auto;
  min-height: 200px;
  width: 100%;
}

.send-comment-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.load-more-btn {
  margin-inline: auto;
  border-radius: 10px;
}

.send-btn {
  width: 50px;
  height: 50px;
}
</style>