
<script>
export default {
  name: "DevMessage"
}
</script>

<template>
  <div v-if="$global.config.dev" v-tooltip="'Development Mode is activated <br> on the server side!'" class="dev-container">
    Dev<icon>developer_mode</icon>
  </div>
</template>

<style scoped>
.dev-container {
  width: fit-content;
  background-color: rgb(255, 107, 107);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-bottom: red 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  user-select: none;
  padding-inline: 15px;
}
</style>