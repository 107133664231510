<script>
const PLATFORM_ICON = {
  windows: "desktop_windows",
  android: "phone_android",
  ios: "phone_iphone",
  iphone: "phone_iphone",
  linux: "laptop",
};


export default {
  name: "Avatar",
  emits: ['click'],
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    userId: {
      type: [String, Number],
      default: null,
    },

    url: {
      type: String,
      default: "",
    },

    fName: {
      type: String,
      required: true
    },

    sName: {
      type: String,
      default: "",
    },
    
    size: {
      type: String,
      default: "normal"
    },

    platform: {
      type: String,
      default: null
    },

    clickEvent: {
      type: String,
      default: null
    }
  },

  data() {return {
    waitResponse: true,
  }},

  computed: {
    pictureSrc()
    {
      return this.url ? this.url: this.$utils.ProfilePic(this.fName, this.sName)
    },

    isDataValid()
    {
      return this.fName;
    },

    platformIcon()
    {
      return PLATFORM_ICON[this.platform];
    },

    platformDesc()
    {
      return this.$lan(this.platformIcon);
    },

    showPlatformIcon()
    {
      return this.platform && this.active;
    },

    activeAttr()
    {
      return this.active ? "": null;
    }
  },

  methods: {
    OnClick(e)
    {
      this.$emit('click', e);

      if(!this.clickEvent) {
        return;
      }

      if(!this.userId) {
        console.warn("Avatar: Cannot use clickEvent prop without userId prop.");
        return;
      }

      this.ProcessAvatarClickEvent();
    },

    ProcessAvatarClickEvent()
    {
      switch(this.clickEvent) {
        case 'edit':
          this.EditAccount();
          break;

        default:
          break;
      }
    },

    EditAccount()
    {
      if(!this.$session.user.can("manage_users")) {
        return;
      }

      this.$router.push(`/users/${this.userId}/edit`);
    }
  }
}
</script>

<template>
  <div class="avatar-container" click-effect="true">
    <div class="spinner" v-if="waitResponse">
    </div>

    <img class="avatar-img" v-show="!waitResponse"
      :src="pictureSrc" 
      :active="activeAttr"
      :size="size"
      @click="OnClick"
      @load="waitResponse = false"
      alt="avatar"
    />

    <icon class="platform-icon" v-tooltip="platformDesc" v-if="showPlatformIcon">
      {{platformIcon}}
    </icon> 
  </div>
</template>

<style scoped>
.avatar-container {
  position: relative;
  background: none;
  user-select: none;
  font-size: 15px;
  border-radius: 50%;
}

.avatar-container[click-effect="true"]:active {
  transform: translateY(1px);
}

.avatar-img {
  border-radius: inherit;
  width: inherit;
  height: inherit;
  border: solid 1px var(--border-color);
  padding: 3px;
  position: relative;
  transition-duration: 0.3s;
  object-fit: cover;
  /* background: var(--box-color); */
}


.avatar-img[active] {
  border-color: rgb(0, 155, 0);
  border-width: 1px;
  outline: solid 1px rgba(0, 128, 0, 0.3);
}

.spinner {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  top: 0; left: 0;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(0, 155, 0) var(--border-color) var(--border-color) var(--border-color);
  animation: spin 0.5s infinite;
}

.avatar-img[size='small'] {
  width: 35px;
  height: 35px;
  font-size: 10px;
}

.avatar-img[size='mediu'] {
  width: 55px;
  height: 55px;
}

.avatar-img[size='big'] {
  width: 75px;
  height: 75px;
  padding: 5px;
}

.avatar-container .platform-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background-color: rgb(0, 155, 0);
  border: solid 3px var(--box-color);
  border-radius: 50%;
  padding: 6px;
  font-size: 1em;
  font-weight: bold;
  animation: zoom-in 0.1s;
  user-select: none;
}
</style>