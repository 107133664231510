<script>
import IconButton from './IconButton.vue'
export default {
  components: { IconButton },
  name: "CopyBtn",

  props: {
    value: {
      type: [String, Number, Boolean],
      required: true
    },

    inputRef: {
      type: Object,
      default: null
    }
  },

  data() { return {
    copied: false
  }},

  computed: {
    buttonIcon() 
    {
      return this.copied ? 'check': 'content_copy';
    }
  },

  methods: {
    ClipboardCopy()
    {
      if(this.copied) {
        return;
      }

      // this.input.select();
      // this.input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(this.value);

      this.ToggleCoolDown();
    },

    ToggleCoolDown()
    {
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 1000);
    }
  }
}
</script>

<template>
  <icon-button class="no-bg copy-btn" @click="ClipboardCopy">
    {{buttonIcon}}
  </icon-button>
</template>

