import { apiManager } from "../ApiManager";

class User
{
  constructor()
  {
    this.InitProps();
  }

  InitProps()
  {
    this.userData = {
      id: 0,
      ugroup: 0,
      f_name: "First",
      s_name: "Sure",
      email: "email@mail.com",
      picture: "",
      lastactive: 0
    };

    this.groupData = { 
      name: "Group Name",
      position: 0,
      permissions: {}
    };
  }

  can(permissionName)
  {
    if(!permissionName) {
      return true;
    }

    return this.permissions[permissionName];
  }

  Load(id)
  {
    this.InitProps();
    this.LoadUserData(id);
    this.LoadGroupData(id);
  }

  LoadUserData(id)
  {
    apiManager.get({ path: `user/${id}` }, ({response}) => {
      const { user } = response.data;
      this.SetUserData(user);
    });
  }

  SetUserData(userData)
  {
    this.userData = userData;
  }

  LoadGroupData()
  {
    apiManager.get({ path: `user/group` }, ({response}) => {
      const { group } = response.data;
      this.group = group;
    });
  }

  SetGroupData(groupData)
  {
    this.groupData = groupData;
  }

  get id() {
    return this.userData.id;
  }

  get picture() {
    return (this.userData.picture) ? this.userData.picture : `https://eu.ui-avatars.com/api/?name=${this.name}+${this.sname}&background=random&bold=true`;
  }

  SetPictureUrl(url) {
    this.userData.picture = url;
  }

  get name() {
    return this.userData.f_name;
  }

  get sname() {
    return this.userData.s_name;
  }
  
  get full_name() {
    return `${this.userData.f_name} ${this.userData.s_name}`;
  }

  get email() {
    return this.userData.email;
  }

  get groupID() {
    return this.userData.ugroup;
  }

  get groupName() {
    return this.groupData.name;
  }

  get privilege() {
    return this.groupData.position;
  }

  get permissions() {
    return this.groupData.permissions;
  }

  get data() {
    return this.userData;
  }

  get rootAccess() {
    return this.groupData.root_access;
  }

}


export function GetUserById(id) 
{
  let newUser = new User();
  newUser.Load(id);
  return newUser;
}

export function CreateUser(userData, groupData)
{
  let newUser = new User();
  newUser.SetUserData(userData);
  newUser.SetGroupData(groupData);
  return newUser;
}

