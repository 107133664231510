
<script>
import Avatar from './Avatar.vue';
export default {
  components: { Avatar },
  name: "UserRow",

  props: {
    userId: {
      type: [Number, String]
    },

    userData: {
      type: Object,
      default: null
    }
  },

  computed: {
    id() {
      return this.userData.id;
    }
  },

  methods: {
    OpenTaskList()
    {
      this.$router.push(`/admin/user/${this.id}/tasklist`);
    },
  }
}
</script>

<template>
  <div class="flex-box user-row" v-if="userData">
    <div class="left-side">
      <profile-preview :user-target="id">
        <avatar :url="userData.picture" :f-name="userData.f_name" :s-name="userData.s_name" :active="userData.is_active"></avatar>
      </profile-preview>
    </div>

    <div class="flex-box content">
      <a @click="OpenTaskList" href="#" class="name">
        {{ userData.f_name }} {{ userData.s_name }}
      </a>

      <div class="email">
        {{userData.email}}
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<style scoped>
.user-row {
  display: flex;
  position: relative;
  height: fit-content;
  align-items: center;
  /* box-shadow: var(--box-shadow); */
  background-color: var(--dark-box-color);
  /* border: solid 1px var(--border-color); */
  gap: 10px;
  padding: 5px;
  border-radius: 30px;
}


.user-row:hover {
  background-color: var(--box-color-blue);
}

.content {
  flex-direction: column;
  gap: 2px;
}

.name {
  font-weight: bold;
  position: relative;
  color: var(--text-color-strong);
  font-size: larger;
}

.email {
  display: block;
  font-weight: bold;
  color: var(--text-color);
  font-size: smaller;
  opacity: .9;
}
</style>