
<script>
import InputUserTag from '../../../InputUserTag.vue';
export default {
  name: "AddProjectMember",

  components: { InputUserTag },

  data() {return {
    members: []
  }},

  computed: {
    membersLength() {
      return this.members.length;
    },

    projectId() {
      return this.$modal.params.projectId;
    }
  },

  mounted() {
    this.members = this.$modal.params.members;
  },

  methods: {
    Delete(userId) {
      this.$api.delete({ path: `projects/${this.projectId}/members/${userId}` }, this.OnDelete);
    },

    OnDelete({response}) 
    {
      if(response.status != "success") {
        this.$msg.push("Acest utilizator nu a putut fi sters.", "error");
        return;
      }

      const { user_id } = response.data;

      for(var index in this.members) {
        const member = this.members[index];

        if(member.id == user_id) {
          this.members.splice(index, 1);
          break;
        }

      }
    },

    AddUser(user)
    {
      this.$api.put({ path: `projects/${this.projectId}/members/${user.id}` }, this.OnAddUser);
    },

    OnAddUser({response}) {
      if(response.status != "success") {
        this.$msg.push("Acest utilizator nu a putut fi adaugat.", "error");
        return;
      }

      const { user } = response.data;
      this.members.push(user);
    }
  }

}
</script>

<template>

  <modal :title="'Membrii'">
    <input-user-tag @append-tag="AddUser" :tags="[]" :can-pick-myself="false"></input-user-tag>

    <div class="no-users" v-if="membersLength == 0">
      <h3>Nu exista membrii</h3>
    </div>

    <div class="members-list" v-else>
      <div v-for="member, index in members" :key="index" class="member">
        {{member.f_name}} {{member.s_name}}

        <icon-button class="red no-bg" @click="Delete(member.id)">
          delete
        </icon-button>
      </div>
    </div>
  </modal>

</template>

<style class="project-members">

.members-list {
  display: flex;
  flex-direction: column;
  max-height:400px;
  min-height: 200px;
  overflow: auto;
  gap: 5px;
}

.member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-box-color);
  border-radius: 5px;
  padding-inline: 10px;
}

.no-users {
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background-color: var(--dark-box-color);
  border-radius: 20px;
}
</style>