<script>
import RichTextArea from '../Common/RichTextArea.vue';
import ToggleButton from '../Common/ToggleButton.vue';
import GroupPermission from './GroupPermission.vue';

export default {
  name: "EditGroup",
  components: { RichTextArea, GroupPermission, ToggleButton },

  emits: ['close'],

  props: {
    groupId: {
      required: true,
      type: [String, Number]
    }
  },

  data() { return {
    descriptionMutated: false,
    editName: false,
    nameInput: "",

    groupData: {}
  }},

  computed: {
    editable() {
      return !this.groupData.predefined && this.$session.accessLevel < this.groupData.position;
    }
  },

  created() {
    this.RequestGroup();
  },

  methods: {
    ToggleRootAccess()
    {
      const data = { "dummy-data": 0 };
      this.$api.post({ path: `groups/${this.groupId}:update-admin-access`, data }, this.OnGroupUpdateRootAccess)
    },

    OnGroupUpdateRootAccess({response})
    {
      if (response.status != "success") {
        return;
      }

      this.groupData.root_access = !this.groupData.root_access;
    },

    RequestGroup()
    {
      const params = {
        "private-data": true
      };

      this.$api.get({ path: `groups/${this.groupId}`, params }, this.OnRequestGroupResponse)
    },

    OnRequestGroupResponse({response})
    {
      const { group } = response.data;
      this.groupData = group;
      this.RefreshDescInput();
    },

    Delete()
    {
      this.$pop.question("Șterge Grup", 
        `Ești sigur că vrei să ștergi <b>${this.groupData.name}</b>?`, () => this.ConfirmDeleteGroup());
    },

    ConfirmDeleteGroup()
    {
      this.$api.delete({ path: `groups/${this.groupId}` }, this.OnDeleteResponse);
    },

    OnDeleteResponse({response})
    {
      if (response.status != "success") {
        return;
      }

      this.Close();
    },

    OpenGroupTasks()
    {
      this.$modal.Open('GroupTaskEdit', { params: { groupId: this.groupId } });
    },

    SaveDescription()
    {
      const data = {
        group_description: this.groupData.description
      };

      this.$api.post({ path: `groups/${this.groupId}:update-description`, data }, this.OnDescriptionUpdateResponse)
    },

    OnDescriptionUpdateResponse()
    {
      this.descriptionMutated = false;
    },

    EditName()
    {
      if(!this.editable) {
        return;
      }

      this.editName = true;
    },

    SaveName()
    {
      if(!this.nameInput) {
        return;
      }

      const data = {
        group_name: this.nameInput
      };

      this.$api.post({ path: `groups/${this.groupId}:update-name`, data }, this.OnNameUpdateResponse)
    },

    OnNameUpdateResponse({response})
    {
      const { group_name } = response.data;

      this.nameInput = "";
      this.editName = false;
      this.groupData.name = group_name;
    },

    Close()
    {
      this.$emit('close');
    },

    RefreshDescInput()
    {
      this.$refs.descInput?.SetContent(this.groupData.description);
    },

    OnTogglePermission(permissionName, newValue)
    {
      this.groupData.permissions[permissionName] = newValue;
    }

  }
}
</script>

<template>
  <div class="edit-group">
    <header class="shadow-row" v-if="editName">
      <icon-button class="red no-bg" @click="editName = false">
        close
      </icon-button>
      <input-box class="name-input" 
        pattern="[A-z]+" 
        maxlength="30" 
        v-model:value="nameInput" 
        :placeholder="groupData.name"
        auto-focus="true"
        @keydown.enter="SaveName"
      >
        <icon-button class="no-bg save-name-btn" @click="SaveName">
          done
        </icon-button>
      </input-box>

    </header>

    <header class="shadow-row" v-else>
      <icon-button class="close no-bg" @click="Close">
        arrow_back_ios_new
      </icon-button>

      <label class="group-name click-area" :disabled="!editable" @click="EditName">
        {{groupData.name}}
      </label>

      <div class="control-buttons">
        <!-- <icon-button class="no-bg" @click="OpenGroupTasks">
          event_note
        </icon-button> -->
        <icon-button class="red no-bg" :disabled="!editable" @click="Delete">
          delete
        </icon-button>
      </div>

    </header>

    <div class="bordered-box admin-btn-field">
      <toggle-button :toggled="groupData.root_access" @toggle="ToggleRootAccess">
        <h4>Administrator</h4>
      </toggle-button>
    </div>

    <div class="bordered-box overflow-hidden">
      <div class="title-bar">
        <h4>{{$lan('description')}}</h4>
      </div>
      <rich-text-area class="description-input" 
        v-model:value="groupData.description"

        :maxlength="250" 
        :readonly="!editable"
        :placeholder="$lan('description')" 
        @input="descriptionMutated = true"
        ref="descInput"
      >
        <button class="save-desc-btn theme-btn" @click="SaveDescription" v-if="descriptionMutated">
          {{$lan('btn_save')}}
        </button>
      </rich-text-area>
    </div>

    <div class="bordered-box permission-list" :disabled="groupData.root_access">
      <div class="title-bar">
        <h4>Permisiuni</h4>
      </div>

      <GroupPermission v-for="(value, permission_name) in groupData.permissions" 
        :key="permission_name"
        :group-id="groupId"
        :value="value"
        :name="permission_name"
        
        :editable="editable"

        @toggle="OnTogglePermission"
      >
      </GroupPermission>

    </div>

  </div>
</template>



<style scoped>
.edit-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  animation: slide-right 0.2s;
}

header {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background-color: var(--box-color);
}

.icon-button {
  font-size: larger;
  font-weight: normal;
}

.group-name {
  font-weight: bold;
  font-size: large;
  padding: 5px;
  padding-inline: 10px;
  border-radius: 50px;
}

.group-name:hover:not([disabled=true]) {
  background-color: var(--box-color-blue);
}

.control-buttons {
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.permission-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* gap: 5px; */
  /* padding: 5px; */
  border-radius: 20px;
  /* max-height: 500px; */
  margin-bottom: 20px;
  background-color: var(--box-color);
}

.description-input {
  height: 200px;
  border: none;
}

.name-input {
  border: none;
  width: 100%;
  background-color: var(--dark-box-color);
}

.name-input .icon-button {
  font-size: large;
  font-weight: bold;
  margin-right: 5px;
}

.name-input .input {
  font-size: large;
  font-weight: bold;
}

.save-desc-btn {
  z-index: 5;
  position: absolute;
  bottom: 30px;
  align-self: center;
  animation: slide-up .3s;
}

.admin-btn-field {
  display: flex;
  padding: 15px;
}

.permission-list[disabled="true"] {
  display: none;
  /* opacity: 0.6; */
  /* pointer-events: none; */
}
</style>