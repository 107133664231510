
<script>
export default {
  name: "IconButton",
  emits: ['click'],

  props: {
    type: {
      type: String,
      default: "button",
      required: false
    },
    
    loading: {
      type: [String, Boolean, Number],
      default: false
    }
  },

  methods: {
    OnClick(e) {
      if(this.loading) {
        return;
      }

      this.$emit('click', e);
    }
  }
}
</script>

<template>
  <button @click="OnClick" class="icon-button material-icons" :type="type">
    <loading size="smallest" v-if="loading" />
    <slot v-else></slot>
  </button>
</template>

<style scoped>
.icon-button .lds-ring {
  margin: 0;
  width: 25px;
  height: 25px;
}
</style>