
import { apiManager } from './ApiManager.js';
import { reactive } from '@vue/reactivity';
import { constants } from './constants.js';
import { session } from './User/Session.js';

const MS_SYNC_INTERVAL = 5000;


class NotificationsApi {
  constructor()
  {
    this.hasConsent = false;
    this.RequestConsent();
  }

  RequestConsent()
  {
    if(!this.IsCompatible()) {
      console.log('Notification API is not supported.')
      return;
    }

    Notification.requestPermission((status) => {
      console.log('Notification permission status:', status);
      this.hasConsent = status == "granted";
    });
  }

  RefreshPushNotifications(notificationsLength) 
  {
    if(!this.hasConsent) {
      return;
    }

    this.PushNotification(`Ai ${notificationsLength} notificari noi`);
  }

  PushNotification(body)
  {
    const options = { body,
      vibrate: [100, 50, 100, 200],
      icon: 'img/icons/906334.png',
      data: {
        dateOfArrival: Date.now(),
        primaryKey: 1
      },
    };

    navigator.serviceWorker.getRegistration().then((reg) => {
      reg.showNotification(constants.title, options);
    });
  }

  IsCompatible()
  {
    return window.Notification != undefined && window.Notification != null;
  }
}


export const notificationApi = new NotificationsApi();


class NotificationManager {
  constructor()
  {
    this.Initialise();
  }

  Initialise()
  {
    this.notifications = {};
    this.newNotifications = {};
    this.syncTimer = null;
  }

  RequestNotifications(callback = null)
  {
    apiManager.get({ path: `user/notifications/${this.notificationsLength}` }, ({response}) => {

      const { notifications } = response.data;
      Object.assign(this.notifications, notifications);

      if(callback) {
        callback(notifications);
      }
    });
  }

  InitSyncTimer()
  {
    if(this.syncTimer) {
      console.warn('Notifications Sync Timer already created.');
      return;
    }

    this.syncTimer = setInterval(() => this.RequestNewNotifications(), MS_SYNC_INTERVAL);
  }

  RequestNewNotifications()
  {
    if(!session.active) {
      return;
    }

    apiManager.get({ path: `user/notifications/new` }, ({response}) => { 
      const { notifications, length } = response.data;
      this.OnNewNotificationsResponse(notifications, length) 
    });
  }

  OnNewNotificationsResponse(notifications, length)
  {
    if(!notifications || !length) {
      return;
    }
    
    notificationApi.RefreshPushNotifications(length);
    Object.assign(this.newNotifications, notifications);
  }

  OpenNotification(notificationTimestamp)
  {
    const notification = this.allNotifications[notificationTimestamp];

    apiManager.patch({ path: `user/notifications/${notificationTimestamp}` }, () => {
      window.location.href = notification.link;
    });
  }

  MarkAllAsRead()
  {
    apiManager.post({ path: "user/notifications:mark-all-read" }, () => {
      for(let id in this.notifications) {
        this.notifications[id].checked = true;
      }
    });
  }

  NewNotificationsToArchive()
  {
    Object.assign(this.notifications, this.newNotifications);
    this.newNotifications = {};
  }

  get hasUnreadNotifications()
  {
    return Object.keys(this.allNotifications).find(key => this.allNotifications[key].checked == false);
  }

  get allNotifications()
  {
    return Object.assign({}, this.newNotifications, this.notifications);
  }

  get allNotificationsLength()
  {
    return this.notificationsLength + this.newNotificationsLength;
  }

  get notificationsLength() 
  {
    return Object.keys(this.notifications).length;
  }

  get newNotificationsLength() 
  {
    return Object.keys(this.newNotifications).length;
  }

}

export const notificationManager = reactive(new NotificationManager());





