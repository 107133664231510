<script>
import ContentFormatter from '../../script/lib/ContentFormatter';

export default {
  name: "TextFormatter",

  props: {
    inputElement: {
      required: true
    },

    canFormat: {
      type: [Boolean, Number],
      default: false
    },

    length: {
      type: [Number, String],
      default: null
    }
  },

  data() { return {
    expanded: false,
  }},


  computed: {
    expandedAttr() {
      return this.expanded ? '': null;
    },

    controlsVisible() {
      return this.canFormat && this.expanded;
    }
  },

  methods: {
    InsertTitleText()
    {
      this.FocusTextarea();
      ContentFormatter.InsertHtmlAtCartel("<h1>Titlu</h1>");
    },

    InsertItalicText()
    {
      this.FocusTextarea();
      ContentFormatter.InsertHtmlAtCartel("<i>Italic</i>");
    },

    InsertBoldText()
    {
      this.FocusTextarea();
      ContentFormatter.InsertHtmlAtCartel("<b>Bold</b>");
    },

    InsertListText()
    {
      this.FocusTextarea();
      ContentFormatter.InsertHtmlAtCartel("<ul><li>Element</li></ul>");
    },

    FocusTextarea()
    {
      if(!this.inputElement) {
        return;
      }

      this.inputElement.focus();
    }
  }

}
</script>

<template>

<div class="text-formatter bordered-box" :expanded="expandedAttr">

  <div v-if="controlsVisible" class="controls">
    <icon-button class="no-bg" v-tooltip="'Marime Text'" @click="InsertTitleText">
      format_size
    </icon-button>

    <icon-button class="no-bg" v-tooltip="'Bold'" @click="InsertBoldText">
      format_bold
    </icon-button>

    <icon-button class="no-bg" v-tooltip="'Italic'" @click="InsertItalicText">
      format_italic
    </icon-button>

    <icon-button class="no-bg" v-tooltip="'Lista'" @click="InsertListText">
      format_list_bulleted
    </icon-button>
  </div>

  <div class="left-side">
    <span class="length-span" v-if="length">
      {{length}}
    </span>

    <icon-button class="no-bg expand-btn" @click="expanded = !expanded" v-if="canFormat">
      navigate_next
    </icon-button>
  </div>

</div>
</template>


<style scoped>
.text-formatter {
  display: none;
  position: absolute;
  align-items: center;
  gap: 5px;
  padding: 2px;
  top: 2px; right: 2px;
  font-size: 15px;
  animation: pop 0.1s;
  width: fit-content;
  opacity: .8;
  border-radius: 0 15px 0 15px;
}

.text-formatter:hover {
  opacity: 1;
}

.expand-btn {
  transition: transform 0.3s;
}

.text-formatter[expanded] .expand-btn {
  transform: rotate(180deg);
}

.text-formatter > div {
  border-right: var(--solid-border);
}

.text-formatter > div:last-child {
  border-right: none;
}

.text-formatter .icon-button {
  padding: 3px;
  font-size: larger;
}

.left-side {
  display: flex;
  align-items: center;
}

.length-span {
  padding-left: 10px;
  padding-right: 5px;
  font-size: small;
}

.rich-text-area:hover .text-formatter {
  display: flex;
}

.controls {
  overflow: auto;
}
</style>


