
import { reactive } from '@vue/reactivity';
import { TranslateByKey } from '../TranslateManager';

class PopupMessageManager {
  constructor()
  {
    this.InitProps(); 
  }

  InitProps()
  {
    this.messages = {};
  }

  CreateMessage(text, type)
  {
    const timestamp = new Date().getTime();
    return { class: type, text: text, timestamp };
  }

  RemoveMessage(index) 
  {
    delete this.messages[index];
  }

  ScheduleRemoveMessage(index)
  {
    setTimeout(() => { 
        this.RemoveMessage(index);
      },
      2300
    );
  }

  push(message, type = "")
  {
    let newMessage = this.CreateMessage(message, type);
    let index = newMessage.timestamp;
    this.messages[index] = newMessage;
    this.ScheduleRemoveMessage(index);
  }

  tpush(strKey, type = "")
  {
    this.push(TranslateByKey(strKey), type);
  }

  // Push API messages
  // Automatically adds a prefix api_ to act as a namespace
  apush(apiMessage, type = "")
  {
    this.push(TranslateByKey(`api_${apiMessage}`), type);
  }
}

export const popupMessageManager = reactive(new PopupMessageManager());

export default {
  install(app)
  {
    app.config.globalProperties.$msg = popupMessageManager;
  }
}

