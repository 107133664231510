
<script>
import Avatar from './Common/Avatar.vue';
import UserActions from './Common/UserActions.vue';
import GroupPlate from './Common/GroupPlate.vue';


export default {
  name: 'ProfilePreview',

  components: { Avatar, UserActions, GroupPlate },

  props: {
    userTarget: {
      type: [Number, String],
      required: true
    },

    avatar: {
      default: false,
      type: [Boolean, String],
      required: false
    },
  },

  data() { return {
    isPreviewOpen: false,
    userData: {
      s_name: "Loading...",
      f_name: "",
      email: "name@mail.com",
      picture: "",
      group_name: "Group Name",
      is_active: false,
    },
  }},

  computed: {
    active()
    {
      return this.userData.is_active;
    },

    isPreviewVisible()
    {
      return this.isPreviewOpen && this.userData;
    }
  },

  mounted()
  {
    if(this.avatar) {
      this.RequestUserData();
    }
  },

  methods: {
    HideProfilePeview()
    {
      this.isPreviewOpen = false;
    },

    ShowProfilePeview()
    {
      this.RequestUserData();
      this.isPreviewOpen = true;
    },

    RequestUserData()
    {
      if(!this.userTarget) {
        return;
      }

      this.$api.get({ path: `users/${this.userTarget}/profile` }, ({response}) => {
        const { user } = response.data;
        this.userData = user;
      });
    },

  },
}
</script>

<template>
  <div @mouseup="ShowProfilePeview" class="click-area preview-click">
    <slot></slot>

    <avatar v-if="avatar && userData" 
      :active="active"
      :url="userData.picture"
      :f-name="userData.f_name"
      :s-name="userData.s_name"
    />
  </div>

  <div class="profile-card" v-off-bound:mousedown="HideProfilePeview" v-floating-child v-if="isPreviewVisible">
    <avatar 
      :url="userData.picture" 
      :f-name="userData.f_name" 
      :s-name="userData.s_name" 
      :active="active"
      :platform="userData.platform"
    />

    <div class="profile-card-body">

      <div class="name">
        {{userData.f_name}} {{userData.s_name}}
      </div>

      <div class="text-row">
        {{userData.email}}
      </div>
      <div class="text-row">
        Activ: {{$utils.TimeStampToString(userData.last_active)}}
      </div>

      <group-plate :id="userData.ugroup" :edit-on-click="$session.HasAccess(['manage_groups'])"></group-plate>
      <user-actions @action="HideProfilePeview" :userData="userData"></user-actions>
    </div>
  </div>

</template>


<style scoped>

.profile-card {
  position: fixed;
  background-color: var(--box-color);
  border-radius: 25px;
  height: fit-content;
  width: 250px;
  animation: slide-down 0.2s;
  border: var(--border-color) 1px solid;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); 
  z-index: 101;
}


.profile-card .avatar-container {
  top: -30px;
  position: relative;
  width: 100px;
  height: 100px;
  background-color: var(--box-color);
  margin: auto;
}

.profile-card-body {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  top: -20px;
  gap: 10px;
}

.name {
  color: var(--text-color-strong);
  font-weight: bold;
  font-size: larger;
  width: 100%;
}

.group-plate {
  margin: 10px;
}

.text-row {
  font-size: small;
}

.preview-click {
  margin: 0;
  padding: 0;
}

.preview-click:active {
  transform: translateY(1px);
}

@media only screen and (max-width: 500px) 
{
  .preview-profile-container {
    width: 100%;
    animation: slide-up 0.2s;
    left: 0;
    bottom: 0;
    border-radius: 20px 20px 0px 0px;
  }
}

</style>

