import { createApp  } from 'vue';
import App from './App.vue';
import router from './script/router';

// custom plugins
import constants from './script/constants';
import GlobalUtils from './script/GlobalUtils';
import GlobalState from './script/GlobalState';
import HTTPRequest from './script/HTTPRequest';
import ApiManager from './script/ApiManager';
import Session from './script/User/Session';

import PopupMessageManager from './script/Popup/PopupMessageManager';
import ModalManager from './script/Popup/ModalManager';
import TranslateManager from './script/TranslateManager';
import CommonComponents from './components/Common/CommonComponents';
import CommonDirectives from './directives/CommonDirectives';
import components from './components';

import './style/main.css';

import './script/ServiceWorkerManager';
import './script/LiveLocationManager';


// app
var app = createApp(App);
app.use(constants);
app.use(router);

app.use(ApiManager);
app.use(Session);
app.use(GlobalState);
app.use(TranslateManager);
app.use(CommonDirectives);
app.use(PopupMessageManager);
app.use(ModalManager);
app.use(HTTPRequest);
app.use(GlobalUtils);

app.use(components);
app.use(CommonComponents);

app.mount('#app');

