<script>
import { TaskStatus } from '@/enums';
import { TaskService } from '@/Services/TaskService';

export default {
  name: "TaskStatus",
  emits: ["update"],

  props: {
    taskId: { 
      required: true,
      type: [String, Number]
    },

    status: {
      required: true,
      type: [String, null, undefined]
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() { return {
    avialableStates: [TaskStatus.ONGOING, TaskStatus.COMPLETED],

    waitResponse: null
  }},

  computed: {
    statusText() {
      return this.$lan(`task_status_${this.status}`);
    }
  },

  methods: {
    ChangeStatus(state) 
    {
      if(state == this.status)
      {
        return;
      }

      this.waitResponse = state;
      TaskService.updateTaskStatus(this.taskId, state, this.OnChangeResponse);
    },

    OnChangeResponse(newStatus)
    {
      this.waitResponse = null;

      if(!newStatus)
      {
        return;
      }

      this.$emit("update", newStatus);

    },

    ChipStyleByState(state)
    {
      switch(state)
      {
        case TaskStatus.ASSIGNED:
          return "red-chip";

        case TaskStatus.ONGOING:
          return "orange-chip";

        case TaskStatus.COMPLETED:
          return "green-chip";

        case TaskStatus.PROGRAMMED:
          return "blue-chip";

        default:
          return "";
      }

    }
  }
}
</script>

<template>
  <drop-down>
    <div :class="['chip', ChipStyleByState(status)]">
      {{ statusText }}
    </div>

    <template #content>
      <div class="box status-list">
        <div @click="ChangeStatus(tmpStatus)" class="click-area status-row" v-for="tmpStatus in avialableStates" :key="tmpStatus">
          <button :class="['chip', ChipStyleByState(tmpStatus) ]" :active="status == tmpStatus">
            <icon>done</icon>
            {{$lan(`task_status_${tmpStatus}`)}}
            
          </button>

          <loading size="x-small" v-if="waitResponse == tmpStatus">
          </loading>
        </div>

      </div>
    </template>
  </drop-down>
</template>


<style scoped>
div[disabled=true] .chip {
  cursor: not-allowed;
}

.chip {
  color: white;
  padding: 3px;
  padding-inline: 10px;
  font-weight: bold;
  border-radius: 15px;
  font-size: var(--normal-text);
}

.orange-chip {
  background-color: orange;
}

.red-chip {
  background-color: indianred;
}

.green-chip {
  background-color: #94b33d;
}

.blue-chip {
  background-color: steelblue;
}

.status-list {
  width: 150px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  border: solid 1px var(--border-color);
  border-radius: 10px;
}

.status-list button {
  flex: 1;
}

.status-list button .icon {
  display: none;
  font-size: var(--large-text);
}

.status-list button[active="true"] .icon {
  display: initial;
}
.status-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  padding: 5px;
  border-radius: 10px;
}
.status-row:hover {
  background-color: var(--box-color-blue);
}
</style>