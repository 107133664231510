import { createRouter, createWebHistory } from 'vue-router'
import { session } from './User/Session';
import { popupMessageManager } from './Popup/PopupMessageManager';

const routes = [
  // public
  {
    name: 'login_page',
    path: '/login',
    component: () => import('../views/public/login.vue'),
  }, 
  {
    name: 'register_page',
    path: '/register',
    component: () => import('../views/public/register.vue'),
  },

  // user
  {
    name: 'index_page',
    path: '/',
    component: () => import('../views/user'),
    meta: {
      requiredAuth: true,
    }
  },
  {
    name: 'profile_page',
    path: '/user/:id/',
    component: () => import('../views/user/profile/index.vue'),
    meta: {
      requiredAuth: true,
    },

    children: [
      {
        name: 'edit_user_page',
        path: 'edit',
        component: () => import('../views/user/profile/EditProfile.vue'),
      },
      {
        name: 'change_password_page',
        path: 'change_password',
        component: () => import('../views/user/profile/PasswordChange.vue'),
      },
      {
        name: 'notifications_page',
        path: 'notifications_settings',
        component: () => import('../views/user/profile/Notifications.vue'),
      },
      {
        name: 'manage_user_page',
        path: 'manage',
        component: () => import('../views/user/profile/Settings.vue'),
      },
      {
        name: 'security_user_page',
        path: 'security',
        component: () => import('../views/user/profile/Security.vue'),
      },
      {
        name: 'live_location_page',
        path: 'live_location',
        component: () => import('../views/user/profile/LiveLocation.vue'),
      },
      {
        name: 'recurring_task_edit_page',
        path: 'recurring_task_edit',
        component: () => import('../views/user/profile/RecurringTaskEdit.vue'),
      },
      {
        name: 'overview_user_page',
        path: 'overview',
        component: () => import('../views/user/profile/Overview.vue'),
      },
    ]
  }, 
  {
    name: 'projects_page',
    path: '/projects',
    component: () => import('../views/user/ProjectsPage.vue'),
    meta: {
      requiredAuth: true
    }
  },
  {
    name: 'project_page',
    path: '/project/:id/',
    component: () => import('../views/user/ProjectPage.vue'),
    meta: {
      requiredAuth: true
    }
  },

  // admin
  {
    name: 'admin_page',
    path: '/admin/index',
    component: () => import('../views/admin'),
    meta: {
      requiredAuth: true,
      require: ["manage_tasks"]
    }
  }, 
  {
    name: 'users_page',
    path: '/admin/users',
    component: () => import('../views/admin/UsersManager.vue'),
    meta: {
      requiredAuth: true
    }
  },
  {
    name: 'groups_page',
    path: '/admin/groups',
    component: () => import('../views/admin/GroupsView.vue'),
    meta: {
      requiredAuth: true
    }
  }, 
  {
    name: 'user_tasks_page',
    path: '/admin/user/:id/tasklist',
    component: () => import('../views/admin/UserTasks.vue'),
    meta: {
      requiredAuth: true,
      require: ["manage_tasks"]
    }
  },

  {
    name: 'system_page',
    path: '/admin/system',
    component: () => import('../views/admin/System.vue'),
    meta: {
      requiredAuth: true,
      require: ["manage_app"]
    }
  },

  {
    name: 'furlough_page',
    path: '/admin/furloughs',
    component: () => import('../views/admin/FurloughView.vue'),
    meta: {
      requiredAuth: true,
      require: ["manage_furlough"]
    }
  },

  // 404
  {
    name: '404_page',
    path: "/:catchAll(.*)",
    component: () => import('../views/public/404.vue') 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export const routerGuarder = {
  checkRouteMetaTags(newRoute)
  {
    const meta = newRoute.meta;
    if(!meta) {
      return;
    }

    this.checkRoutePermission(meta)
  },

  checkRoutePermission(meta)
  {
    if(!this.checkRouteSessionAccess(meta)) {
      router.push("/login");
      return;
    }

    if(!this.checkRouteAccess(meta)) {
      popupMessageManager.tpush('access_denied', 'error');
      router.push("/");
    }
  },

  checkRouteSessionAccess(meta)
  {
    return !(meta.requiredAuth && !session.GetSessionToken());
  },

  checkRouteAccess(meta)
  {
    return true;
    // const requiredPerms = meta.require;
    // if(!requiredPerms) {
    //   return true;
    // }

    // if(!session.active) {
    //   return false;
    // }

    // return session.HasAccess(requiredPerms);
  },
}

export default router;
