<script>
export default {
  name: "ToggleButton",
  
  emits: ['toggle'],

  props: {
    toggled: {
      type: [Boolean, Number],
      required: true
    },

    disabled: {
      type: [Boolean, Number, String],
      default: false
    }
  },

  computed: {
    toggledAttr()
    {
      return this.toggled ? "": null;
    },

    disabledAttr()
    {
      return this.disabled ? "": null;
    }
  },

  methods: {
    OnToggle(event)
    {
      if(this.disabled) {
        return;
      }

      this.$emit('toggle', event, !this.toggled);
    }
  }
}
</script>

<template>
  <div class="toggle-container" @click="OnToggle" :disabled="disabledAttr">
    <div class="toggle-button" :toggled="toggledAttr"></div>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<style scoped>
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  height: fit-content;
}

.toggle-container[disabled] {
  cursor: not-allowed;
}

.toggle-button {
  display: flex;
  position: relative;
  align-items: center;
  min-width: 50px;
  width: 50px;
  height: 25px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  background-color: var(--btn-color);
  border-radius: 20px;
  overflow: hidden;
  transition-duration: 0.2s;
}

.toggle-button[toggled] {
    background-color: var(--theme-color);
}

.toggle-button::before {
  content: "close";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 5px;
  transition-duration: 0.2s;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  background-color: var(--dark-box-color);
  font-family: "Material Icons";
  color: var(--btn-color);
  z-index: 1;
}

.toggle-button:active {
  transform: scale(.98);
}

.toggle-button[toggled]::before {
  content: "check";
  transform: translateX(100%);
  color: var(--theme-color);
}
</style>