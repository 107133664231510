
import { reactive } from "vue";
import { session } from "../User/Session";


const STORAGE_KEY_NAME = 'pinnedTasks';

class PinnedTasks {
  constructor()
  {
    this.InitProps();
    this.InitTasks();
  }

  InitProps()
  {
    this.initialised = false;
    this.pinnedTasks = [];
  }

  InitTasks()
  {
    if(this.initialised) {
      return;
    }

    const storedPinnedTasks = localStorage.getItem(this.storageKey) || '[]';
    this.pinnedTasks = JSON.parse(storedPinnedTasks);
  }

  SaveTasks()
  {
    localStorage.setItem(this.storageKey, JSON.stringify(this.pinnedTasks));
  }

  IsTaskPinned(taskId)
  {
    return this.pinnedTasks.find(id => taskId == id) != undefined;
  }

  AppendTask(taskId, save = true)
  {
    if(!taskId || this.IsTaskPinned(taskId)) {
      return false;
    }

    this.pinnedTasks.push(taskId);

    if(save) {
      this.SaveTasks();
    }

    return true;
  }

  RemoveTaskById(taskId, save = true)
  {
    const index = this.pinnedTasks.indexOf(taskId);
    if(index == -1) {
      return false;
    }

    this.pinnedTasks.splice(index, 1);

    if(save) {
      this.SaveTasks();
    }

    return true;
  }

  RemoveTaskByIndex(index, save = true)
  {
    if(index < 0 || index >= this.legth) {
      return false;
    }

    this.pinnedTasks.splice(index, 1);

    if(save) {
      this.SaveTasks();
    }

    return true;
  }

  ChangeTaskPosition(taskId, newPosition)
  {
    this.RemoveTaskById(taskId, false);
    this.pinnedTasks.splice(newPosition, 0, taskId);
    this.SaveTasks();
  }

  ClearCache()
  {
    this.pinnedTasks = [];
  }

  get legth()
  {
    return this.pinnedTasks.legth;
  }

  get storageKey()
  {
    if(!session.active) {
      return 'user:public';
    }

    return `user:${session.user.id}:${STORAGE_KEY_NAME}`;
  }
}


let pinnedTasks = reactive(new PinnedTasks());
export default pinnedTasks;
