<script>
import IconButton from './Common/IconButton.vue';
import Modal from './Common/Modal.vue';

export default {
  components: { Modal, IconButton },
  name: 'RoutineTasksEdit',

  data() { return {

    selected: 0,
    waitResponse: false, 
    categories: ['daily','weekly','monthly','quarterly','anual'],
    tasks: [],
    inputValue: "",

  }},

  computed: {
    params() {
      return this.$modal.params;
    },

    groupId() {
      return this.params.groupId;
    },

    selectedCategory() {
      return this.categories[this.selected];
    },

    tasksLength() {
      return this.tasks.length;
    }
  },

  mounted()
  {
    this.RequestTasks();
  },

  methods: {
    OnChangeCategory()
    {
      this.RequestTasks();
    },

    RequestTasks()
    {
      this.waitResponse = true;
      this.$api.get({ path: `groups/${this.groupId}/tasks/${this.selectedCategory}` }, this.OnRequestTasksResponse);
    },

    OnRequestTasksResponse({response})
    {
      const { task_list } = response.data;
      this.tasks = task_list;
      this.waitResponse = false;
    },

    PostTask()
    {
      const inputValue = this.inputValue;

      if(!inputValue.length) {
        return;
      }

      const data = {
        task_data: inputValue
      };

      this.$api.post({ path: `groups/${this.groupId}/tasks/${this.selectedCategory}`, data}, this.OnPostResponse)
    },

    OnPostResponse({response})
    {
      const { new_task } = response.data;
      this.tasks.unshift(new_task);
      this.inputValue = "";
    },

    DeleteTask(taskId)
    {
      this.$api.delete({ path: `groups/${this.groupId}/tasks/${taskId}` }, this.OnDeleteResponse);
    },

    OnDeleteResponse({response})
    {
      const { task_id } = response.data;
      const index = this.tasks.findIndex(task => task.id == task_id);
      if(index == -1) {
        return;
      }

      this.tasks.splice(index, 1);
    },

    GetCategoryTitle(categoryName)
    {
      return this.$lan(`task_category_title_${categoryName.toLowerCase()}`);
    },

    Hide()
    {
      this.$pop.Hide();
    }
  },

}
</script>

<template>

  <modal :title="'Task-uri programate'">
    <div class="bordered-box control-container">
      <select name="filter" class="input type-pick shadow-row" @change="OnChangeCategory()" v-model="selected">
        <option v-for="(categoryName, i) in categories" :key="i" :value="i">
          {{GetCategoryTitle(categoryName)}}
        </option>
      </select>

      <input-box placeholder="Nume task.." v-model:value="inputValue" maxlength="255">
        <icon-button class="no-bg create-btn" @click="PostTask">
          add
        </icon-button>
      </input-box>
    </div>

    <loading-box v-if="waitResponse"></loading-box>
    <div class="task-list" v-else>
      <div class="feedback-text" v-if="!tasksLength">
        <icon>calendar_today</icon>
        <span>{{$lan('no_tasks')}}</span>
      </div>

      <div v-for="task in tasks" :key="task" class="task-row shadow-row">
        <span>{{task.text}}</span>

        <icon-button @click="DeleteTask(task.id)" class="no-bg red remove-button">
          delete
        </icon-button>
      </div>
    </div>

  </modal>

</template>

<style scoped>
.task-list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  overflow: auto;
  border-radius: 20px;
  border: var(--solid-border);
}

.task-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: var(--box-color);
  padding: 10px;
  padding-left: 10px;
  word-break: break-all;
}

.task-row .icon-button {
  visibility: hidden;
}

.task-row:hover .icon-button {
  visibility: initial;
}

.remove-button {
  font-size: larger;
}


.control-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow: hidden;
}

.control-container .input-box {
  border: none;
}

.type-pick {
  width: 100%;
  border: none;
  border-radius: 0;
}

.feedback-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  font-size: large;
  gap: 10px;
  opacity: .7;
}

.feedback-text .icon {
  font-size: xx-large;
}

.input-box {
  width: 100%;
}

.create-btn {
  font-size: medium;
  margin-right: 2px;
}


</style>
