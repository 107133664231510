<script>
export default {
  name: "FurloughRequestView",


  computed: {
    furloughData() {
      return this.$modal.params.furloughData;
    },

    userData() {
      return this.$modal.params.userData;
    }
  },

  methods: {
    RequestUserData()
    {

    }
  }


}
</script>

<template>
  <modal :title="$lan('furlough_view_modal')">
    <div class="basic-info">
      <div class="user" v-if="userData">
        <avatar :f-name="userData.f_name" :s-name="userData.s_name" :url="userData.picture">
        </avatar>

        <div class="info">
          <span>{{userData.f_name}} {{userData.s_name}}</span>
          <group-plate :id="userData.ugroup" :show-icon="false"></group-plate>
        </div>
      </div>

      <div class="time-created">
        {{$utils.TimeStampToString(furloughData.timestamp)}}
      </div>
    </div>
    <div class="time-box">
        <div class="field">
          <b>Start</b>
          <date-input v-model:value="furloughData.start_time" disabled></date-input>
          <input v-model="furloughData.start_clock" type="time" disabled>
        </div>

        <div class="field">
          <b>End</b>
          <date-input v-model:value="furloughData.end_time"  disabled></date-input>
          <input v-model="furloughData.end_clock" type="time" disabled>
      </div>
    </div>

    <div class="input details-box" v-html="furloughData.details">

    </div>

    <div class="actions-box">
      <button class="border-btn">
        Close
      </button>

      <button class="border-btn green">
        Accept
      </button>

      <button class="border-btn red">
        Decline
      </button>
    </div>

  </modal>
</template> 


<style scoped>
.basic-info {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}

.basic-info .user {
  padding: 15px;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 20px;
  /* border: var(--solid-border); */
  background-color: var(--dark-box-color);
}

.basic-info .user .info {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-weight: bold;
}

.basic-info .user .group-plate {
  border: none;
  background: none;
  padding: 0;
  justify-content: left;
}

.basic-info .user .group-plate:hover {
  background: none;
}

.time-box {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.time-box .field {
  display: flex;
  flex: 1;
  gap: 2px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark-box-color);
  border-radius: 20px;
  text-align: center;
}

.time-box .field input {
  border: none;
  border-bottom: solid 1px var(--border-color);
  border-radius: 0;
  background-color: inherit;
}

.time-box .field input:last-child {
  border: none;
}

.details-box {
  height: 200px;
}

.time-created {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  /* border: var(--solid-border); */
  background-color: var(--dark-box-color);
  flex: 1;
}

.actions-box {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}

.actions-box button {
  padding: 15px;
  flex: 1;

}
</style>