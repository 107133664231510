<script>
import { notificationManager } from '@/script/NotificationManager';

export default {
  name: "NotificationRow",
  emits: ['check'],

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    index() {
      return this.value.timestamp;
    },

    author() {
      return this.value.author;
    },

    dateTimeString() {
      return this.$utils.TimeStampToString(this.value.timestamp);
    },

    checkedAttr() {
      return this.value.checked ? "": null;
    },

    isRecentAttr() {
      return this.$utils.TimeStamp() - this.value.timestamp < 300 ? "": null;
    }

  },

  methods: {
    OnClickNotification()
    {
      notificationManager.OpenNotification(this.index);
      this.$emit('check', this.index);
    }
  }
}
</script>

<template>
  <div class="notification" :checked="checkedAttr" :recent="isRecentAttr">
    <div class="new-dot" v-if="!value.checked">
    </div>

    <avatar 
      :f-name="author.f_name" 
      :s-name="author.s_name" 
      :url="author.picture" 
      :user-id="author.id"
      click-event="edit"
    />

    <div class="content" @click="OnClickNotification">
      <div class="desciption">
        <b v-if="author">{{author.f_name}} {{author.s_name}}&nbsp;</b> 
        <b v-else>
          {{ $lan("user_deleted") }}&nbsp;
        </b> 
        <span v-html="$lan(`notification_${value.action}`)" />
      </div>

      <div class="time"> 
        {{dateTimeString}}
      </div>
    </div>
  </div>
</template>


<style style>
.notification {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  /* border-bottom: solid 1px var(--border-color); */
  /* background-color: var(--box-color-blue); */
  border-radius: var(--border-radius);
  height: 70px;
  user-select: none;
  cursor: pointer;
}

.notification:hover {
  background-color: var(--box-color-blue);
}

.notification .content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notification .desciption {
  float: right;
  word-break: break-all;
  /* width: calc(100% - 60px); */
  font-size: 14px;
}

.notification .time {
  bottom: 10px;
  color: var(--text-color2);
  font-size: 13px;
  opacity: .8;
  font-weight: bold;
}

.notification .time {
  bottom: 10px;
  color: var(--text-color2);
  font-size: smaller;
  opacity: .8;
}

.notification[recent] .time {
  color: var(--theme-color);
}

.avatar-container {
  width: 50px;
  height: 50px;
}

.new-dot {
  top: 13px;
  left: 13px;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  background-color: red;
  z-index: 5;
}
</style>