import { bodyScroll } from "../utils";


function SetMobileStyle(el) 
{
  let elStyle = el.style;
  elStyle.width = '100%';
  elStyle.bottom = '0';
  elStyle.top = 'initial';
  elStyle.height = 'fit-content';
  elStyle.maxHeight = '90%';
  elStyle.borderRadius = '0';
  elStyle.left = '0';
}


function UpdateElementPosition(el)
{
  let elRect = el.getBoundingClientRect();
  let parentRect = el.parentElement.getBoundingClientRect();

  let elStyle = el.style;

  if(elRect.right > window.innerWidth) {
    elStyle.right = 0 + 'px';
  }
  
  if(parentRect.bottom + elRect.height > window.innerHeight) {
    elStyle.top = parentRect.top - elRect.height - 5 + 'px';
  }
  else
  {
    elStyle.top = parentRect.bottom + 5 + 'px';
  }
}

export default {
  mounted(el, binding) 
  {
    let elStyle = el.style;

    elStyle.position = "fixed";
    elStyle.zIndex = '999';

    bodyScroll.Disable();

    if(window.innerWidth <= 700 && binding.arg != "no-responsive") 
    {
      SetMobileStyle(el);
      return;
    }

    UpdateElementPosition(el);
  },

  updated(el)
  {
    if(window.innerWidth <= 700) {
      return;
    }

    UpdateElementPosition(el);
  },

  beforeUnmount()
  {
    bodyScroll.Enable();
  }
}

