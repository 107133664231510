<script>
import IconButton from '../Common/IconButton.vue';
import ResultBox from '../Common/ResultBox.vue';
import RichTextArea from '../Common/RichTextArea.vue';

let staticData = {
  waitResponse: false,
  newProjectId: 0,

  tags: [],
  selectedField: 0,
  
  formData: { "info": "", "title":"" }
};

function OnProjectReceive({context, response})
{
  const { project_id } = response.data;
  context.ResetData();
  context.newProjectId = project_id;
}


export default {
  name: 'ProjectCreateForm',

  components: { IconButton, ResultBox, RichTextArea },

  data() { return staticData },

  computed: {
    canCreate() {
      return this.formData.info && this.formData.title;
    },

    usersList() {
      let users = [];

      for(let i in this.tags) {
        users.push(this.tags[i].id);
      }

      return users;
    },

    showResultBox() {
      return this.waitResponse || this.newProjectId;
    }
  },

  methods: {
    RedirectToProject()
    {
      this.$router.push(`/project/${this.newProjectId}`);
      this.ResetData();
      this.Close();
    },

    ResetData()
    {
      this.tags = [];
      this.formData = { "info": "", "title":"" };
      this.waitResponse = false;
      this.newProjectId = 0;
    },

    CreatProject()
    {
      const data = {
        'project_data': this.formData,
        'users': this.usersList,
      }

      this.waitResponse = true;
      this.$api.post({ context: this, path: "projects", data }, OnProjectReceive);
    },

    AppendUserTag(user)
    {
      this.tags.push(user);
    },

    RemoveUserTag(index)
    {
      this.tags.splice(index, 1);
    },

    Close()
    {
      this.$pop.Hide();
    }
  }
}
</script>

<template>
  <div class="popup-box">
    <div class="title-bar header"> 
      <h2>Proiect nou</h2>

      <icon-button class="red" @click="Close">
        close
      </icon-button>
    </div>

    <result-box @close="ResetData" @confirm="RedirectToProject" :success="Boolean(newProjectId)" :loading="waitResponse" v-if="showResultBox">
    </result-box>

    <div class="body" v-else>
      <div class="row">
        <input-user-tag
          :tags="tags" 
          @append-tag="AppendUserTag" 
          @remove-tag="RemoveUserTag" 
        />
      </div>

      <div class="row">
        <input placeholder="Titlu.." class="project-title" maxlength="70" v-model="formData.title" />
      </div>

      <div class="row">
        <rich-text-area class="text-area" maxlength="10000" placeholder="Info.." label="Info"  v-model:value="formData.info" />
      </div>
    </div>

    <div class="footer" v-if="!showResultBox">
      <div class="buttons">
        <button class="red no-bg border" @click="ResetData">
          <icon>clear</icon> Clear
        </button>
        <button class="blue no-bg border" :disabled="!canCreate" @click="CreatProject">
          <icon>send</icon> Creeaza
        </button>
      </div>
    </div>
  </div>

</template>


<style scoped>
@import url('../../style/Popup.css');

.text-area {
  height: 300px;
}

.text-area[focused] {
  height: 300px;
}

.project-title {
  width: 100%;
  font-size: 20px;
}
</style>
