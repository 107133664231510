
<script>
import { TaskFilter } from '@/enums';
import { TaskService } from '@/Services/TaskService';
import pinnedTasks from '@/script/task/PinnedTasks';

import TaskRow from '@/components/Task/TaskRow.vue';
import ToggleButton from '@/components/Common/ToggleButton.vue';
import PriorityTasksHeader from './PriorityTasksHeader.vue';
import InputUserTag from '../../InputUserTag.vue';



export default {
  name: 'PriorityAssignedTasks',

  components: { TaskRow,  ToggleButton, PriorityTasksHeader, InputUserTag },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    userTarget: {
      type: [Number, String],
      required: true
    }
  },

  data() { return {
    taskList: [],
    expandedTask: -1,

    waitResponse: false,
    showLoadTasksBtn: false,
    queryChecked: false,
    editPinnedTasks: false,

    filter: TaskFilter.ALL,

    isReverseOrder: false,
    sortBy: null,
    assignees: [],

    query: ""
  }},

  computed: {
    tasksCount()
    {
      return this.taskList.length;
    },

    page()
    {
      return Math.floor(this.tasksCount / 10);
    },

    canEditPinnedTasks()
    {
      return this.userTarget == this.$session.user.id && this.filter == "pinned";
    },

    showLoadMoreBtn()
    {
      return !this.waitResponse && this.showLoadTasksBtn;
    },

    asigneeId()
    {
      return this.assignees[0]?.id;
    },

    taskListClass() 
    {
      return {
        'tasks-list': true,
        'no-scroll': this.expandedTask != -1
      }
    },

    taskFilters()
    {
      return TaskFilter;
    }
  },

  created()
  {
    pinnedTasks.InitTasks();
    this.LoadStoredSort();
    // this.LoadStoredFilters();
    this.RequestTaskList();
  },

  methods: {
    SortBy(flag)
    {
      if(flag == this.sortBy) {
        this.isReverseOrder = !this.isReverseOrder;
      } else {
        this.isReverseOrder = false;
      }

      this.sortBy = flag;
      this.OnSortByChanged();
    },

    OnSortByChanged()
    {
      window.localStorage.setItem(`tasks:${this.userTarget}:sort-by`, this.sortBy);
      window.localStorage.setItem(`tasks:${this.userTarget}:reversed-sort`, this.isReverseOrder);

      this.ReloadTaskList();
    },

    LoadStoredSort()
    {
      this.sortBy = window.localStorage.getItem(`tasks:${this.userTarget}:sort-by`);
      this.isReverseOrder = window.localStorage.getItem(`tasks:${this.userTarget}:reversed-sort`);
    },

    LoadStoredFilters()
    {
      if(this.userTarget != this.$session.user.id) {
        return;
      }

      const filter = window.localStorage.getItem('filter');
      if(!filter) {
        return;
      }

      this.filter = filter;
      // this.UpdateQueryUrl();
    },

    OnChangeFilters()
    {
      this.editPinnedTasks = false;
      window.localStorage.setItem("filter", this.filter);
      this.ReloadTaskList();
      // this.UpdateQueryUrl();
    },

    ReloadTaskList()
    {
      this.showLoadTasksBtn = false;
      this.taskList = [];
      this.RequestTaskList();
    },

    RequestTaskList()
    {
      this.waitResponse = true;
      
      const params = {
        query: this.query,
        userId: this.userTarget,
        asigneeId: this.asigneeId,
        page: this.page,
        filter: this.filter,
        sortAttribute: this.sortBy,
        reversedSort: this.isReverseOrder,
      };

      TaskService.getDelegatedTasks(params, (taskList) => {
        this.waitResponse = false;
        this.taskList = this.taskList.concat(taskList);
        this.showLoadTasksBtn = taskList.length >= 10;

      });
    },

    ScrollToTop()
    {
      let tasksListRef = this.$refs.tasksList;
      if(!tasksListRef) {
        return;
      }

      tasksListRef.scrollTop = 0;
    },

    ExpandTask(index, focused) 
    {
      if (focused && this.OpenModalPreviewIfMobile(index)) {
        return;
      }

      if(this.expandedTask != index)
      {
        this.SetExpandedTaskIndex(index);
        this.ScrollToTop();
        return;
      }

      if(!focused) {
        this.SetExpandedTaskIndex(-1);
      }
    },

    OpenModalPreviewIfMobile(index)
    {
      if (!this.isSmallWindow()) {
        return false;
      }
      const params = {
        taskId: this.taskList[index].id
      };

      this.$modal.Open("PriorityTaskPreview", { context: this, params })

      return true;
    },

    OnRemoveTask(index)
    {
      this.SetExpandedTaskIndex(-1);

      this.taskList.splice(index, 1);
    },

    SetExpandedTaskIndex(index)
    {
      this.expandedTask = index;
    },

    expandTaskById(id)
    {

      const index = this.taskList.findIndex(task => task.id == id);
      if(index == -1) {
        return false;
      }

      this.expandedTask = index;
      this.ScrollToTop();

      return true;
    },

    isSmallWindow()
    {
      return this.$utils.isMobile();
    },

    ToggleEditPinnedTasks()
    {
      this.editPinnedTasks = !this.editPinnedTasks;

      if(!this.editPinnedTasks) {
        this.ReloadTaskList();
      }
    },

    AppendUserTag(user)
    {
      this.assignees[0] = user;
      this.ReloadTaskList();
    },

    RemoveUserTag()
    {
      this.assignees = [];
      this.ReloadTaskList();
    },
  }

}
</script>

<template>
<div>
  <div class="task-controls box">
    <div class="col">
      <icon-button class="refresh-btn no-bg" @click="ReloadTaskList">
        refresh
      </icon-button>
    </div>

    <div class="col">
      <input-box :placeholder="$lan('task_search_input_placeholder')" class="query-input" v-model:value="query" @input="ReloadTaskList">
        <template #left>
          <icon>search</icon>
        </template>
      </input-box>
    </div>

    <div class="col">
      <select name="filter" class="input filter-pick no-bg ripple-effect" @change="OnChangeFilters()" v-model="filter">
        <option v-for="taskFilter in taskFilters" :key="taskFilter" :value="taskFilter">
          {{ $lan(`task_filter_${taskFilter}`) }}
        </option>
      </select>
    </div>

    <div class="col">
      <input-user-tag class="user-pick"
          :tags="assignees" 
          :canPickMyself="true"
          @append-tag="AppendUserTag" 
          @remove-tag="RemoveUserTag" 
        />
    </div>

    <div class="column" v-if="canEditPinnedTasks">
      <toggle-button :toggled="editPinnedTasks" @toggle="ToggleEditPinnedTasks">
        {{$lan('edit_tasks_order')}}
      </toggle-button>
    </div>
  </div>

  <priority-tasks-header @on-sort="SortBy" :sort-by="sortBy" :reverse-sort="isReverseOrder"></priority-tasks-header>


  <div ref="tasksList" :class="taskListClass">

    <TaskRow v-for="(task, i) in taskList" :key="i"
      :index="i"
      :initial-task-data="task"
    >
    </TaskRow>

    <div v-if="waitResponse" class="center-box">
      <loading></loading>
    </div>

    <message-box v-else-if="!tasksCount" class="no-results border-radius">
      {{$lan('no_results')}}
    </message-box>

    <button v-if="showLoadMoreBtn" 
      @click="RequestTaskList" 
      style="margin: auto; margin-block: 30px; display: block; width: 100px;" 
      class="button no-bg border"
    > {{$lan('load_more_btn')}} </button>
  </div>
</div>
</template>


<style scoped>
@import './common.css';

.tasks-list {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 600px;
  padding-block: 5px;
  padding-inline: 1px;
  overflow: auto;
  gap: 10px;
  border-radius: 20px;
}

.task-controls .col {
  padding: 1px;
}


.no-scroll {
  overflow: hidden;
  /* height: 700px; */
}

.filter-pick {
  position: relative;
  flex-grow: 1;
  border: none;
  border-radius: 5px;
}

.no-results {
  width: 100%;
  text-align: center;
  padding: 20px;
  /* color: rgb(95, 95, 95); */
  /* opacity: .4; */
}

.task-controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  border-radius: 15px;
  padding: 5px;
  gap: 5px;
}

.toggle-container {
  min-width: 200px;
  padding-inline: 10px;
}

.query-input {
  border-radius: 10px;
}

.query-input .icon {
  margin-left: 7px;
}

.user-pick {
  border: none;
}

@media screen and (max-width: 1200px) {
  .tasks-list {
    max-height: none;
    height: 100%;
  }
}
</style>

