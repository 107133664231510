
<script>
import { bodyScroll } from '@/utils';
import { notificationManager } from '@/script/NotificationManager';

import IconButton from '@/components/Common/IconButton.vue';

import Notification from './Notification.vue';

export default {
  name: 'TheNotification',

  components: { IconButton, Notification },

  data() { return {
    isPopVisibile: false,
    showLoadMoreBtn: false,
    showSrollTopBtn: false
  }},

  computed: {
    isBulletVisible() {
      return this.newNotificationsLength > 0;
    },

    hasUnreadNotifications() {
      return notificationManager.hasUnreadNotifications;
    },

    notificationsDict() {
      return notificationManager.allNotifications;
    },

    notifiactionsLength() {
      return notificationManager.allNotificationsLength;
    },

    newNotificationsLength() {
      return notificationManager.newNotificationsLength;
    }
  },

  watch: {
    newNotificationsLength() {
      this.ScrollNotificationList();
    }
  },

  mounted()
  {
    this.LoadNotifications();
    notificationManager.InitSyncTimer();
  },

  methods: {
    LoadNotifications()
    {
      notificationManager.RequestNotifications((notifications) => {
        this.showLoadMoreBtn = Object.keys(notifications).length >= 5;
      });
    },

    Close()
    {
      this.TogglePageScroll();
      this.isPopVisibile = false;
    },

    Show()
    {
      if(this.isPopVisibile) {
        this.Close();
        return;
      }

      this.TogglePageScroll();
      notificationManager.NewNotificationsToArchive();
      this.isPopVisibile = true;
      this.ScrollNotificationList(false);
    },

    GoToNotificationsSettings()
    {
      this.$router.push(`/user/${this.$session.user.id}/notifications_settings`);
    },

    ClearNotifications()
    {
      notificationManager.MarkAllAsRead();
    },

    ScrollNotificationList(smooth = true, to = "top")
    {
      const listRef = this.notfificationsListRef()
      if(!listRef) {
        return;
      }

      const behavior = smooth ? 'smooth': 'auto';
      const top = to == "top" ? -listRef.scrollHeight: listRef.scrollHeight;
      
      listRef.scrollTo({top, behavior });
    },

    TogglePageScroll()
    {
      if(!this.$utils.isMobile()) {
        return;
      }

      bodyScroll.Toggle();
    },

    notfificationsListRef() {
      return this.$refs.notificationsList;
    }
  },
}
</script>

<template>
  <div @click="Show" class="notification-button">
    <slot></slot>
    <div v-if="isBulletVisible" class="count-bullet">
      {{newNotificationsLength}}
    </div> 
  </div>

  <div class="notifications-container mobile-popup" ref="popupBody" @animationstart="ScrollNotificationList(false)" v-off-bound:mousedown="Close" v-floating-child v-if="isPopVisibile">
    <div class="title-bar header bottom-shadow">
      <h3>{{$lan('popup_notifications_title')}} {{hasUnreadNotifications}}</h3>

      <div class="buttons">
        <icon-button @click="ClearNotifications" class="red" v-if="isBulletVisible">
          clear_all
        </icon-button>
        <icon-button @click="GoToNotificationsSettings">
          edit_notifications
        </icon-button>
      </div>
    </div>


    <message-box v-if="!notifiactionsLength">
      <icon>notifications</icon>
      {{$lan('no_notifications')}}
    </message-box>
    
    <div class="notification-list" ref="notificationsList">
      <button @click="LoadNotifications" class="load-more" v-if="showLoadMoreBtn">
        {{$lan('load_more_btn')}}
      </button>

      <Notification v-for="(data, index) in notificationsDict" :key="index" :value="data">
      </Notification>
    </div>

  </div>
</template>


<style scoped>
.notification-button {
  position: relative;
}

.notifications-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--box-color2);
  border-radius: var(--border-radius);
  width: 350px;
  animation: slide-down 0.2s;
  /* min-height: 200px; */
  height: fit-content;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); 
  overflow: auto !important;
  /* padding: 10px; */
}

.notification-list {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  background-color: var(--box-color2);
  max-height: 350px;
  padding: 5px;
}

.count-bullet {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -2px;
  left: 21px;
  background-color: rgb(194, 0, 0);
  outline: solid 2px var(--box-color2);
  border: solid 2px rgb(207, 0, 0);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;
  animation: zoom-in 0.3s;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.header {
  padding: 10px;
  color: var(--text-color);
  z-index: 1;
}


.load-more {
  margin: 10px;
  align-self: center;
}

.icon-button {
  font-size: medium;
}

@media only screen and (max-width: 700px) 
{
  .notifications-container {
    width: 100%;
    border-radius: 0;
  }
  .notification-list {
    max-height: 400px;
  }
}
</style>

