
<script>

export default {
  name: "TaskComment",
  emits: ['remove', 'update'],

  props: {
    data: {
      type: [Object, null],
      required: true,
      default: null
    },
  },

  computed: {
    id() {
      return this.data.id;
    },

    author() {
      return this.data.author;
    },

    timestamp() {
      return this.data.created;
    }
  }
}
</script>

<template>
  <div class="flex-box comment" v-if="data">
    <div class="details-container">

      <div class="author" v-if="author">
        <profile-preview :user-target="author.id">
          <avatar :url="author.picture" :f-name="author.f_name" :s-name="author.s_name" :active="author.is_active"></avatar>
        </profile-preview>
        {{author.f_name}} {{author.s_name}}
      </div>

      <div class="author" v-else>
        <avatar :url="null" f-name="U"></avatar>
        {{ $lan("user_deleted") }}
      </div>

      <div class="time">
        {{$utils.TimeStampToString(this.timestamp)}}
      </div>
    </div>

    <div v-html="data.text" class="comment-text">
    </div>
  </div>
</template>

<style scoped>
.comment {
  flex-direction: column;
  height: fit-content;
  width: 100%;
  padding: 10px;
  border-radius: var(--border-radius);
  animation: slide-right 0.3s;
}

.comment:hover {
  background-color: var(--dark-box-color);
}

.details-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.author {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--text-normal);
  font-weight: bold;
}

.comment:hover .time {
  visibility: visible;
}

.time {
  padding-left: 10px;
  font-size: smaller;
  visibility: hidden;
}

.comment-text {
  position: relative;
  width: fit-content;
  max-width: calc(100% - 50px);
  padding: 10px;
  border-radius:  0px 25px 25px 25px;
  border: var(--solid-border);
  color: var(--text-color-strong);
  word-wrap: break-word;
  white-space: pre-wrap;
  left: 20px;
  top: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: var(--box-color);
}

.avatar-container {
  width: 30px;
  height: 30px;
}
</style>