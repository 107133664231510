<script>
const ACTIONS = [
  {
    icon: "manage_accounts",
    permission: "manage_users",
    action: "open_profile"
  },

  {
    icon: "add_task",
    permission: "manage_tasks",
    action: "open_task_form"
  },

  {
    icon: "splitscreen",
    permission: "manage_tasks",
    action: "open_task_list"
  },
];

export default {
  name: "UserActions",
  emits: ['action'],

  props: {
    userData: {
      type: Object,
      required: true
    },

    btnType: {
      default: "normal",
      type: String
    }
  },

  computed: {
    userId()
    {
      return this.userData ? this.userData.id: null;
    },

    filteredActions()
    {
      return ACTIONS.filter(action => { 
          return this.$session.user.can(action.permission) || this.userData.id == this.$session.user.id;
        }
      );
    },

    buttonTypeClass()
    {
      switch(this.btnType)
      {
        case 'nobg':
          return 'no-bg';
        case 'bordered':
          return 'border-btn'
        default:
          break;
      }

      return "";
    }
  },

  methods: {
    EmitAction(action)
    {
      this.$emit('action', action);
    },

    OnAction(action)
    {
      switch(action)
      {
        case 'open_profile':
          this.$router.push(`/user/${this.userId}/edit`);
          break;

        case 'open_task_form':
          this.$pop.openTaskForm(this.userData);
          break;

        case 'open_task_list':
          this.$router.push(`/admin/user/${this.userId}/tasklist`);
          break;

        default:
          break;
      }

      this.EmitAction(action);
    },
  }
}
</script>


<template>
  <div class="actions-container">
    <icon-btn :class="['user-action-btn', buttonTypeClass]" v-for="action in filteredActions" @click="OnAction(action.action)" :key="action">
      {{action.icon}}
    </icon-btn>
  </div>
</template>


<style scoped>
.actions-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: fit-content;
  padding: 2px;
  border-radius: 50px;
}

.actions-container .user-action-btn {
  padding: 7px;
}

@media only screen and (max-width: 700px) {
  .actions-container .user-action-btn {
    padding: 10px;
  }
}
</style>