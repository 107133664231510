import { HttpStatus } from "@/enums";

import { apiManager } from "@/script/ApiManager";
import { popupMessageManager } from "@/script/Popup/PopupMessageManager";




export const TaskService = {
  getTask(taskId, callback)
  {
    const path = `tasks/${taskId}`;

    apiManager.get({ path }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data?.task, success);
    });
  },

  getTasksByUser({ query, userId, page, filter, sortAttribute, reversedSort }, callback)
  {
    const params = {
      "query": query,
      "user": userId,
      "page": page,
      "filter": filter,
      "sort-by": sortAttribute,
      "reversed-sort": reversedSort
    }

    const path = "tasks/"

    apiManager.get({ path, params }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data.task_list);
    });
  },

  getDelegatedTasks({ query, userId, asigneeId, page, filter, sortAttribute, reversedSort }, callback)
  {
    const params = {
      "query": query,
      "user":  userId,
      "assignee_id": asigneeId,
      "page": page,
      "filter": filter,
      "sort-by": sortAttribute,
      "reversed-sort": reversedSort,
    }

    const path = "tasks/delegated/"

    apiManager.get({ path, params }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data.task_list);
    });
  },

  postTask({ users, info, deadline, project, includeAuthor, isShared }, callback)
  {
    const data = {
      users, info, deadline, project,

      include_author: includeAuthor,
      is_shared: isShared
    };

    const path = `tasks/`;

    apiManager.post( { path, data }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(success, data.tasks);
    });
  },

  deleteTask(taskId, callback)
  {
    const path = `tasks/${taskId}`;

    apiManager.delete({ path }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;
      if (!success) 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(data.deleted_task_id);
    });
  },

  updateTaskInformation(taskId, taskInformation, callback)
  {
    const data = {
      task_info: taskInformation
    };

    const path = `tasks/${taskId}:update-information`;

    apiManager.post( { path, data }, ({response}) => {
      const { status, message } = response;
      const success = status == HttpStatus.OK;

      if (!success) {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  },

  updateTaskDeadline(taskId, deadlineTimestamp, callback)
  {
    const params = {
      timestamp: deadlineTimestamp
    };
    const path = `tasks/${taskId}:update-deadline`;

    apiManager.post( { path, params }, ({response}) => {
      const { status, message } = response;
      const success = status == HttpStatus.OK;

      if (!success) {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  },

  
  updateTaskStatus(taskId, newStatus, callback)
  {
    const params = {
      status: newStatus
    };

    const path = `tasks/${taskId}:update-status`;

    apiManager.post({ path, params }, ({response}) => {
      const { status, message, data } = response;

      if (status != HttpStatus.OK) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data?.new_status);
    });
  },

  archiveTask(taskId, callback)
  {
    const path = `tasks/${taskId}:archive`;

    apiManager.post({ path }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(data?.tasks || []);
    });
  },


  attachToProject(taskId, projectId, callback)
  {
    const path = `tasks/${taskId}:attach-to-project`;
    const params = {
      'project-id': projectId
    }

    apiManager.post({ path, params }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(success);
    });
  },

  delegateTask(taskId, userIds, taskInfo, taskDeadline, taskProject, callback)
  {
    const data = { 
      user_ids: userIds,
      task_info: taskInfo,
      task_deadline: taskDeadline, 
      task_project: taskProject
    };

    const path = `tasks/${taskId}:delegate`;

    apiManager.post({ path, data }, ({response}) => {
      const { status, message, data } = response;

      if (status != HttpStatus.OK) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data?.tasks || []);
    });
  }
}

export const TaskSubscriptionService = {
  toggleTaskSubscriptionStatus(taskId, callback)
  {
    const path = `tasks/${taskId}:toggle-subscription-status`;

    apiManager.post( { path }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(data.new_subscription_status);
    });
  },

  getTaskSubscriptionStatus(taskId, callback)
  {
    const path = `tasks/${taskId}/subscription-status`;

    apiManager.get( { path }, ({response}) => {
      const { status, message, data } = response;

      if (status != "success") 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(data.user_is_subscribed);
    });
  },
}


export const TaskMemberService = {
  getTaskMembers(taskId, callback)
  {
    const path = `tasks/${taskId}/members/`;

    apiManager.get({ path }, ({response}) => {
      const { status, message, data } = response;

      if (status != "success") 
      {
        popupMessageManager.apush(`${message}`, "error");
        return;
      }

      callback(data.task_members);
    });
  },

  addTaskMember(taskId, userId, callback)
  {
    const path = `tasks/${taskId}/members/${userId}`;

    apiManager.post({ path }, ({response}) => {
      const { status, message, data } = response;

      if (status != "success") 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(userId);
    });
  },

  removeTaskMember(taskId, userId, callback)
  {
    const path = `tasks/${taskId}/members/${userId}`;

    apiManager.delete({ path }, ({response}) => {
      const { status, message, data } = response;

      if (status != "success") 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(userId);
    });
  }
}


export const TaskLogService = {
  getTaskLogs(taskId, page, callback)
  {
    const params = {
      page
    };

    const path = `tasks/${taskId}/logs/`;

    apiManager.get({ path, params }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
        return;
      }

      callback(data.task_logs, success);
    });
  }
}


export const TaskCommentService = {
  postTaskComment(taskId, text, callback)
  {
    const data = { text };
    const path = `tasks/${taskId}/comments/`;

    apiManager.post({ path, data }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data.task_comment, success);
    });
  },

  getTaskComments(taskId, page, callback)
  {
    const params = {
      page
    };

    const path = `tasks/${taskId}/comments/`;

    apiManager.get({ path, params }, ({response}) => {
      const { status, message, data } = response;
      const success = status == HttpStatus.OK;

      if (!success) 
      {
        popupMessageManager.apush(message, "error");
      }

      callback(data.task_comments, success);
    });
  },

}

