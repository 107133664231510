
<script>
export default {
  name: "InstallButton",

  data() { return {
    installBtnVisible: false,
    deferredPrompt: null
  }},

  computed: {
    visible() {
      return this.installBtnVisible || this.$global.config.dev;
    }
  },

  created() {
    this.InitInstallButton();
  },

  methods: {
    async InstallApplication() 
    {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.installBtnVisible = false;
      }
    },

    InitInstallButton() 
    {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.installBtnVisible = true;
        this.deferredPrompt = e;
      });
    },
  }
}
</script>

<template>
  <button v-if="visible" @click="InstallApplication" class="blue border-btn install-btn">
    Install
    <icon>get_app</icon>
  </button>
</template>

<style scoped>
.install-btn {
  width: fit-content; 
  border-radius: 10px; 
  /* font-size: 20px; */
  border-radius: var(--border-radius);
}

.install-btn .icon {
  font-size: large;
}
</style>