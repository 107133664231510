
import { reactive } from '@vue/reactivity';
import { bodyScroll } from '@/utils.js';

import FurloughRequest from '@/components/Furlough/FurloughRequest.vue';
import FurloughModalView from '@/components/Furlough/FurloghModalView.vue';

import GroupTaskEdit from '@/components/GroupTaskEdit.vue';
import RichtextPreview from '@/components/RichtextPreview.vue';


import ProjectMembersModal from '@/components/ProjectView/ProjectComponent/ProjectMembers/ProjectMemebersModal.vue';
 
import TaskMembersModal from '@/components/TasksView/PriorityTask/TaskMembersModal.vue';
import TaskAttachProject from '@/components/TasksView/PriorityTask/Modals/TaskAttachProject.vue';

import TaskRedelgateModal from '@/components/Task/TaskRedelgateModal.vue';
import TaskPreviewModal from '@/components/Task/TaskPreviewModal/TaskPreviewModal.vue';
import UserSearchModal from '@/components/Common/UserSearchModal.vue';

const MODALS_COMPONENTS = {
  FurloughModalView, FurloughRequest, 
  GroupTaskEdit, RichtextPreview, ProjectMembersModal,
  TaskMembersModal, TaskAttachProject, TaskPreviewModal, TaskRedelgateModal, UserSearchModal
}


class ModalManager {

  constructor()
  {
    this.InitProps();
  }

  InitProps()
  {
    this.componentName = null;
    this.context = null;
    this.params = {};
    this.events = {};

    this.onHide = null;
    this.showShadowBgOnly = false;
  }

  Open(componentName, { context, params, events, onMounted })
  {
    if(!componentName) {
      return;
    }

    this.DisableBodyScroll();

    this.onMountedEvent = onMounted || null;
    this.params = params || {};
    this.events = events || {};
    this.context = context || null;

    this.componentName = componentName;
  }

  OpenBackground(onHide = null)
  {
    this.DisableBodyScroll();
    this.onHide = onHide;
    this.showShadowBgOnly = true;
  }

  DisableBodyScroll()
  {
    bodyScroll.Disable();
  }
  
  Hide()
  {
    if(this.onHide) {
      this.onHide();
    }

    this.EnableBodyScroll();
    this.InitProps();
  }

  EnableBodyScroll()
  {
    bodyScroll.Enable(true);
  }

  get isVisible()
  {
    return Boolean(this.componentName) || this.showShadowBgOnly;
  }
}

export let modalManager = reactive(new ModalManager());

export default {
  install(app)
  {
    app.config.globalProperties.$modal = modalManager;

    for(let key in MODALS_COMPONENTS)
    {
      app.component(key, MODALS_COMPONENTS[key]);
    }
  }
}

