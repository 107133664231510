<script>
export default {
  export: "Icon"
}
</script>

<template>
  <span class="material-icons icon">
    <slot></slot>
  </span>
</template>


<style scoped>
.icon {
  padding: 0;
}
</style>