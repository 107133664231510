
import GroupsManager    from './GroupManager/GroupManager';
import TasksView        from './TasksView';
import LoadButton       from './LoadButton';
import ThePopup         from '../script/Popup/PopupManager';
import ProfilePreview   from './ProfilePreview';
import InputUserTag     from './InputUserTag';
import GroupPicker      from './GroupPicker';
import MarkdownContainer from './MarkDownContainer';

export default 
{
  install(app)
  {
    app.component('groupsManager',  GroupsManager);
    app.component('tasksView',      TasksView);
    app.component('lButton',        LoadButton);
    app.component('profilePreview', ProfilePreview);
    app.component('inputUserTag',   InputUserTag);
    app.component('groupPicker',    GroupPicker);
    app.component('markdownContainer',    MarkdownContainer);

    app.use(ThePopup);
  }
}





