import OffBound from "./OffBoundEvents";
import FloatingChild from "./FloatingChild";
import AutoFocus from "./AutoFocus";
import Tooltip from "./Tooltip";
import NectoContext from "./VueNectoContext";

const directives = { OffBound, FloatingChild, AutoFocus, Tooltip, NectoContext };

export default {

  // methods
  install(app)
  {
    for (const [key, value] of Object.entries(directives)) {
      app.directive(key, value);
    }
  },
}

