
<script>
import TheNavBar from './components/StaticComponents/TheNavBar/TheNavbar.vue';
import TheCookiesDialog from './components/StaticComponents/TheCookiesDialog.vue';
import TheConnectionError from './components/StaticComponents/TheConnectionError.vue';
import ThePopup from './components/StaticComponents/ThePopup.vue';
import ThePopupMessage from './components/StaticComponents/ThePopupMessage/ThePopupMessage.vue';
import TheModal from './components/StaticComponents/TheModal.vue';
import { routerGuarder } from './script/router.js';

import ApplicationConfig from './script/ApplicationConfig';
import ApplicationManager from './script/ApplicationManager';
import DarkMode from './script/DarkMode.js';

export default {
  name: 'ApplicationRoot',

  components: {
    TheNavBar,
    TheCookiesDialog,
    TheConnectionError,
    ThePopup,
    ThePopupMessage,
    TheModal
},

  data() { return {
    config: {},
    showConsole: false
  }},

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.updatePageTitle(this.$lan(to.name));
        routerGuarder.checkRouteMetaTags(to);
      }
    }
  },

  created()
  {
    this.updatePageTitle();
    this.Initialise();

  },

  methods: {
    Initialise()
    {
      this.InitDarkMode();
      //this.InitClientVersionCheck();
      this.InitAppConfig();
      this.InitSession();
    },

    InitSession()
    {
      const sessionLoading = this.$session.LoadSession();
      if(sessionLoading) {
        return;
      }

      this.$router.push('/login');
    },

    InitDarkMode()
    {
      DarkMode.CheckDarkMode();
    },

    InitClientVersionCheck()
    {
      ApplicationManager.GetServerClientVersion((clientVersion) => {
        if(clientVersion == this.$const.ver){
          return;
        }
        
        window.location.reload(true);

      });
    },

    InitAppConfig()
    {
      ApplicationConfig.Init();
    },

    updatePageTitle(pageName = null)
    {
      let newTitle = this.$const.title;
      if (process.env.NODE_ENV == "development") {
        newTitle = "[DEV] " + newTitle;
      }

      if(pageName) {
        newTitle += ` - ${pageName}`;
      }

      document.title = newTitle;
    }
  },

}
</script>


<template>
  <!-- <TheLoadingScreen></TheLoadingScreen> -->

  <TheNavBar v-if="$session.active" />

  <main v-if="!$session.loading">
    <router-view id="router-view" />
  </main>

  <ThePopup />
  <TheModal></TheModal>
  <ThePopupMessage></ThePopupMessage>
  <TheConnectionError></TheConnectionError>
  <TheCookiesDialog v-if="!$cookies.get('cookies')"></TheCookiesDialog>
</template>

<style scoped>
#router-view {
  position: relative;
  float: right;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: calc(100% - 250px);
  top: 70px;
  padding-inline: 1em;
}

@media (max-width: 700px) {
  #router-view {
    flex-direction: column;
    width: 100%;
    float: right;
    padding-inline: 0;
  }
}
</style>
