import axios from "axios";
import { constants } from './constants.js';
import { globalState } from './GlobalState.js';
import router from './router';
import { session } from "./User/Session.js";
import { popupMessageManager } from "./Popup/PopupMessageManager.js";
 
// Headers sent on each packet
function headers() 
{
  return {
    "secure_id": constants.client_hash_code,
    "client_version": constants.ver,
  }
}

export function httpGET(context, route, callback)
{
  axios.get(`${constants.api}/${route}`, getRequestParams())
  .then(response => { 
    processResponse(context, response, callback) 
  })
  .catch(error => { 
    processError(context, error) 
  });
}

export function httpPOST(context, route, callback = null, data = {})
{
  axios.post(`${constants.api}/${route}`, data, getRequestParams())
  .then(response => { 
    processResponse(context, response, callback) 
  })
  .catch(error => { 
    processError(context, error) 
  });
}

function processResponse(context, response, callback)
{
  if(callback && checkResult(context, response.data)) 
  { 
    callback(context, response);

    if(globalState.error) {
      globalState.error = false; 
    }
  }
}

function getRequestParams()
{
  return { 
    headers : headers(),
    timeout: constants.api_timeout,
    withCredentials: true,
  };
}

function processError(context, error)
{
  console.error(error);
  if(!error.request) {
    return;
  }

  globalState.error = true;
  pingServer(context);
}

function pingServer(context)
{
  setTimeout(() => {
    httpGET(context, 'ping', () => {
      globalState.error = false;
    });
  }, 5000);
}


function checkResult(context, data)
{
  /*
  * Check each http result if succeed or not
  */

  // 0 or undefined result => success
  if(!data.result) {
    return true;
  }

  if(data.message == "invalid_session") {
    setTimeout(() => { session.Logout() }, 1000);
    return false;
  }

  switch(data.result)
  {
    // session expired or invalid token
    // log the user out and clear the cookies
    case -1:
    {
      popupMessageManager.tpush("session_expired", "error");
      setTimeout(() => { session.Logout() }, 1000);
      return false;
    }
    
    // access denied, reload group visual permissions
    case -2:
    {
      popupMessageManager.tpush("access_denied", "error");
      session.user.LoadGroupData();
      return false;
    }
    case -3:
    {
      popupMessageManager.tpush("error_code_403", "error");
      // setTimeout(() => { context.$session.user.logOut() }, 1000);
      return false;
    }
    // excepted errors with message data
    default:
    {
      processCommand(data.command);

      if(data.message) {
        popupMessageManager.tpush(data.message, "error");
      }

      return false;
    }
  }
}

function processCommand(command)
{
  switch(command)
  {
    case 'go_home':
    {
      router.push("/");
      break;
    }

    case 'ping':
    {
      // router.go();
      break;
    }

    default:
      break;
  }
}


export default {
  install(app)
  {
    var props = app.config.globalProperties;
    props.$httpGET = httpGET;
    props.$httpPOST = httpPOST;
  },
}


