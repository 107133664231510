/* eslint-disable no-console */

import { register } from 'register-service-worker';

class ServiceWorkerManager {

  Init()
  {
    if(!this.IsSupported()) {
      console.log('Service Worker not supported.');
      return;
    }

    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
        // registration.sync.register('get-latest-news');
      },

      registered () {
        console.log('Service worker has been registered.')
      },

      cached () {
        console.log('Content has been cached for offline use.')
      },

      updatefound () {
        console.log('New content is downloading.')
      },

      updated () {
        console.log('New content is available; please refresh.')
      },

      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },

      error(error) {
        console.error('Error during service worker registration:', error)
      }

    })
  }

  IsSupported()
  {
    return 'serviceWorker' in navigator;
  }

}


const serviceWorkerManager = new ServiceWorkerManager();
serviceWorkerManager.Init();

export default serviceWorkerManager;



