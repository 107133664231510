

<script>
export default {
  name: "Logo"
}
</script>

<template>
  <div class="app-logo" @click="$router.push('/')">
    <img src="/img/icons/906334.png" />
    <span class="logo-text">
      {{$const.title}}
    </span>
  </div>
</template>

<style scoped>
.app-logo {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-block: 20px;
}

.app-logo:active {
  transform: scale(.96);
}

.app-logo img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  animation: spin 2s;
}

.app-logo:hover img {
  transition-duration: 1s;
  transform: rotate(360deg);
}

.logo-text {
  color: #006fdd; 
  font-size: 30px;
  font-weight: bold;
  font-family: "Lucida Console", "Courier New", monospace;
}

@media screen and (max-width: 1000px)
{
  .app-logo img {
    width: 40px;
    height: 40px;
  }

  .logo-text {
    font-size: 40px;
  }
}
</style>