
let markdownit = require('markdown-it');

const utils = {
  TimeStampToString(time)
  {
    const now = new Date().getTime() / 1000;
    const dateStruct = new Date(time * 1000);
    const difference = now - time;

    if(difference < 60)
    {
      return "Acum câteva secunde"
    }
    else if(difference < 3600)
    {
      return `Acum ${ Math.floor(difference/60)} minut(e)`;
    }
    else if(difference < 86400 && dateStruct.getDate() == new Date().getDate())
    {
      return `Astăzi la ${dateStruct.getHours()}:${dateStruct.getMinutes()}`;
    }
    else if(dateStruct.getDate() == (new Date().getDate() - 1))
    {
      return `Ieri la ${dateStruct.getHours()}:${dateStruct.getMinutes()}`;
    }

    return `${dateStruct.getDate()}-${dateStruct.getMonth()+1}-${dateStruct.getFullYear()} ${dateStruct.getHours()}:${dateStruct.getMinutes()} `;
  },
  

  TimeStampToInputVal(time)
  {
    try {
      // Convert unix timestamp format for input value format
      const dateStruct = time && time != "now" ? new Date(time * 1000): new Date();
      return dateStruct.toISOString().substring(0, 10);
    }
    catch {
      throw `Couldn't convert timestamp value ${time} to input value`;
    }
  },

  InputTimeToStamp(value)
  {
    // Convert value of date input to unix timestamp (ms!)
    return (new Date(value).getTime());
  },

  TimeStamp()
  {
    return Math.floor(new Date().getTime() / 1000);
  },

  ProfilePic(name, sname)
  {
    return `https://eu.ui-avatars.com/api/?name=${name}+${sname}&background=random&bold=true`
  },

  isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1100) {
      return true
    } else {
      return false
    }
  },

  isIOS() {
    if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  },

  parseMarkdownText(text)
  {
    if(!text) {
      return "";
    }

    let markdownParser = new markdownit();
    return markdownParser.render(text);
  },
}


export default {
  install(app)
  {
    var props = app.config.globalProperties;
    props.$utils = utils;
  },
}


