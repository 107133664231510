<script>
import IconButton from '../Common/IconButton.vue';
function OnCreateTask({response, context})
{
  const { new_task } = response.data;
  context.taskList.unshift(new_task);
  context.taskText = "";
  context.waitPostResponse = false;
}

function OnTaskListReceive({context, response})
{
  const { task_list } = response.data;
  if(task_list) {
    context.taskList = task_list;
  }

  context.waitForResponse = false;
}

function OnDeleteTask({context, response})
{
  const { task_id } = response.data;
  const index = context.taskList.map((task) => { return task.id; }).indexOf(task_id);
  if(index == -1) {
    return;
  }

  context.taskList.splice(index, 1);
  context.$msg.tpush("task_removed", "warning");
}

let data = {
  previewTask: null,
  timeSelected: "",
  taskList: [],
  seenList: [],
  taskText: "",
  waitForResponse: false,
  waitPostResponse: false
}

export default {
  components: { IconButton },
  name: 'DailyTask',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },

    userTarget: {
      type: [Number, String],
      required: true
    }
  },

  data() { return data; },

  computed: {
    taskCount() 
    {
      return this.taskList.length;
    },

    timeStamp()
    {
      return this.$utils.InputTimeToStamp(this.timeSelected) / 1000;
    },
    
    dateMaxLimit()
    {
      return this.$utils.TimeStampToInputVal();
    },

    canModify()
    {
      return this.$utils.TimeStampToInputVal() == this.timeSelected && this.userTarget == this.$session.user.id;
    },
  },

  mounted() 
  {
    this.timeSelected = this.$utils.TimeStampToInputVal();
    this.requestTaskList();
    // this.requestSeenList();
  },

  methods: {

    requestSeenList()
    {
      this.seenList = [];

      const params = {
        timestamp: this.timeStamp,
        user: this.userTarget
      };

      this.$api.get({ context: this, path: `daily-tasks/seen-list`, params }, ({response}) => {
        this.seenList = response.data.users_list;
      });
    },

    requestTaskList()
    {
      this.waitForResponse = true;

      const params = {
        user: this.userTarget,
        timestamp: this.timeStamp
      };

      this.$api.get({context: this, path: 'daily-tasks', params }, OnTaskListReceive);
      // this.$httpGET(this, `daily-tasks?&user=${this.userTarget}&timestamp=${this.timeStamp}`, OnTaskListReceive);
    },

    postDailyTask()
    {
      if(!this.taskText.length || !this.taskText.replace(/\s/g, '').length) {
        return;
      }

      this.waitPostResponse = true;
      const data = { "task_data" : this.taskText };
      this.$api.post({ path: 'daily-tasks', context: this, data }, OnCreateTask);
      // this.$httpPOST(this, `task/create?daily_task=1`, OnCreateTask, { "task_data" : this.taskText });
    },

    removeDailyTask(id)
    {
      this.$api.delete({ context: this, path: `daily-tasks/${id}` }, OnDeleteTask);
    },

    timeChanged()
    {
      // this.requestSeenList();
      this.requestTaskList();
    }
  },
}

</script>

<template>
  <div class="daily-task-body"  v-if="selected">
    <div v-if="seenList.length" class="daily-task-form daily-seen-list">
      <avatar v-for="user in seenList" :key="user" v-tooltip="`Seen by ${user.f_name} ${user.s_name[0]}.`" 
        :url="user.picture" :f-name="user.f_name" :s-name="user.s_name" :active="user.is_active"
      />
    </div>

    <div class="daily-task-form bordered-box">
      <div class="column">
        <icon-button class="no-bg refresh-btn" @click="requestTaskList">
          refresh
        </icon-button>
      </div>

      <div class="column">
        <input @change="timeChanged($event)" v-model="timeSelected" class="daily-task-date no-bg" type="month" :max="dateMaxLimit" />
      </div>

      <div class="column input-column" v-if="canModify">
        <input-box
          @keydown.enter="postDailyTask" 
          maxlength="5000"
          v-model:value="taskText"
          class="daily-task-input" 
          placeholder="Descriere..." 
        > 
          <icon-button class="no-bg add-btn" @click="postDailyTask" :loading="waitPostResponse" v-tooltip="'Task Nou'">
            add
          </icon-button>
        </input-box>
      </div>

    </div>

    <div class="tasks-list" v-if="selected">
      <div v-if="!taskCount">
        <div class="box daily-task daily-task-desc">
          {{$lan('no_results')}}
        </div>
      </div>

      <div v-for="task in taskList" :key="task" class="daily-task" v-tooltip="$utils.TimeStampToString(task.created)">
        <div class="daily-task-desc" @click="previewTask = task.info">
          {{task.info}}
        </div>

        <icon-button v-if="canModify" @click="removeDailyTask(task.id)" v-tooltip="'Delete'" class="no-bg delete-btn lightcoral">
          delete
        </icon-button>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="shadow-background preview-bg" v-if="previewTask" @click.self="previewTask = null">
      <div class="box preview-task">
        {{ previewTask }}
      </div>
    </div>
 </teleport>
</template>


<style scoped>
.daily-task-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.preview-bg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-task {
  word-break: break-all;
  width: 400px;
  height: 50%;
  padding: 10px;
  animation: zoom-in 0.2s;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  border: none;
  max-height: 400px;
  gap: 5px;
  overflow: auto;
  padding: 2px;
}

.daily-task {
  padding: 20px;
  padding-right: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  height: 20px;
  overflow-wrap: break-word;
  animation: slide-down 0.3s;
  transition-duration: 0.5s;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: var(--box-color);
}

.daily-task button {
  display: none;
}

.daily-task:hover button {
  display: block;
}

.daily-task-form {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  border-radius: 30px;
  /* padding: 10px; */
}

.daily-task-desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  width: calc(100% - 30px);
}

.daily-task-desc:hover {
  cursor: pointer;
}

.daily-task-date {
  padding: 10px;
  width: 150px;
  border: none;
  border-radius: 5px;
}

.daily-task-input {
  flex-grow: 1;
  width: 100%;
  margin: 0;
  border: none;
  background-color: var(--dark-box-color);
}

.daily-task-input .icon-button {
  margin-left: 2px;
  font-size: large;
}

.daily-seen-list {
  overflow-x: auto;
  height: 70px;
}

.input-column {
  flex-grow: 1;
}

.delete-btn {
  font-size: larger;
  margin-right: 5px;
}

.add-btn {
  margin-right: 5px;
}

@media  only screen and (max-width: 700px) {
  .tasks-list {
    max-height: none;
  }

  .daily-task-input {
    flex-grow: 1;
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }

}
</style>
