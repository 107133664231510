<script>
import { TaskService } from '@/Services/TaskService';
import { TaskOptionCommand } from '@/enums';

const taskOptions = [
  {
    button_text: "btn_delegate",
    icon: "alt_route",
    command: TaskOptionCommand.DELEGATE,
    ignore_locked: true
  },
  {
    button_text: "btn_archive",
    icon: "archive",
    command: TaskOptionCommand.ARCHIVE,
  },
  {
    button_text: "btn_delete",
    icon: "delete",
    styles: "red",
    command: TaskOptionCommand.DELETE,
    ignore_locked: true
  }
];


export default {
  name: "TaskOptions",

  emits: ["command"],

  props: {
    taskId: {
      required: true,
      type: [Number, String]
    },

    isLocked: {
      type: [Boolean, Number, undefined]
    }
  },

  computed: {
    taskOptions() {
      return taskOptions;
    }
  },

  methods: {
    ExecCommand(commandStr)
    {
      switch(commandStr)
      {
        case TaskOptionCommand.DELETE:
          this.DeleteTask();
          break;

        case TaskOptionCommand.ARCHIVE:
          this.ArchiveTask();
          break;

        case TaskOptionCommand.DELEGATE:
          this.DelegateTask();
          break;

        default:
          console.warn(`TaskOptions: Unknown task command ${commandStr}`);
          break;
      }
    },

    ArchiveTask()
    {
      TaskService.archiveTask(this.taskId, () => {
        this.EmitCommand(TaskOptionCommand.ARCHIVE);
      });
    },

    DeleteTask()
    {
      TaskService.deleteTask(this.taskId, () => {
        this.EmitCommand(TaskOptionCommand.DELETE);
      });
    },

    DelegateTask()
    {
      this.$modal.Open("TaskRedelgateModal", { context: this, params: { taskId: this.taskId } });
    },

    EmitCommand(commandStr)
    {
      this.$emit("command", commandStr);
    }
  }
}
</script>

<template>
<drop-down>
  <slot></slot>

  <template #content>
    <div class="box border task-options">

      <button :class="['no-bg', option.styles]" @click="ExecCommand(option.command)" v-for="option in taskOptions" :key="option" :disabled="isLocked && !option.ignore_locked">
        <icon v-if="option.icon">
          {{ option.icon }}
        </icon>
        {{ $lan(`task_option_${option.button_text}`) }}
      </button>

    </div>
  </template>
</drop-down>
</template>

<style scoped>
.task-options {
  display: flex;
  flex-direction: column;
  width: 200px;
  max-height: 300px;
  overflow: auto;
  padding: 5px;
  border: solid 1px var(--border-color);
  border-radius: 10px;
}

.task-options > button {
  justify-content: flex-start;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  font-size: var(--normal-text);
}

.task-options > button .icon {
  font-size: var(--normal-icon);
}
</style>