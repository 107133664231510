<script>
import { TaskService } from '@/Services/TaskService';

export default {
  name: "TaskAttachProject",

  data() { return {
    projects: [],
    waitResponse: false,

    filters: {
      'only-mine': false
    },
  }},

  computed: {
    taskId() {
      return this.$modal.params.taskId;
    },
  },
  

  created() {
    this.RequestProjects();
  },

  methods: {
    RefreshProjects()
    {
      this.projects = [];
      this.RequestProjects();
    },

    RequestProjects()
    {
      this.waitResponse = true;
      const params = this.filters;
      
      this.$api.get({ path: `projects`, params }, this.OnProjectsResponse);
    },

    OnProjectsResponse({response})
    {
      const { project_list } = response.data;

      this.projects = project_list;

      this.waitResponse = false;
    },

    ToggleFilter(filter)
    {
      this.filters[filter] = !this.filters[filter];
      this.RefreshProjects();
    },

    AttachProject(projectId)
    {
      this.waitResponse = true;
      TaskService.attachToProject(this.taskId, projectId, (success) => {
        this.waitResponse = false;

        if(!success) 
        {
          return;
        }

        const params = {
          taskId: this.taskId,
        };
        
        this.$modal.Open("TaskPreviewModal", { context: this, params });
      });
    },
  }


}
</script>

<template>
  <modal :title="$lan('task_attach_project_modal')">

    <check-box class="no-bg project-row" v-for="(value, filter) in filters" :key="filter" 
                :checked="value" 
                @check="ToggleFilter(filter)" 
                @un-check="ToggleFilter(filter)" 
              >
                {{$lan(`project-filter-${filter}`)}}
   </check-box>

  <loading-box v-if="waitResponse">
  </loading-box>

  <div class="projects-list" v-else>
    <div class="no-bg project-row" v-for="project, index in projects" :key="index" @click="AttachProject(project.id)">
      <icon>calendar_month</icon>
      <span>{{ project.title }}</span>
    </div>

  </div>

  </modal>
</template> 


<style scoped>
.project-row {
  display: flex;
  align-items: center;
  font-size: var(--normal-text);
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  justify-content: left;
  gap: 5px;
  width: 100%;
}

.project-row > .icon {
  font-size: var(--normal-icon);
}

.project-row > span {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projects-list {
  max-height: 25rem;
  overflow: auto;
  width: 100%;
  padding: 3px;
}
</style>