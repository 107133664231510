

<script>
export default {
  name: "TheModal",

  computed: {
    params() {
      return this.$modal.params;
    },

    context() {
      return this.$modal.context;
    },

    componentName() {
      return this.$modal.componentName;
    },

    isModalVisible() {
      return this.$modal.isVisible;
    }
  },

  mounted() {
    if(this.$modal.onMountedEvent) {
      this.$modal.onMountedEvent();
    }
  },

  methods: {
    Hide()
    {
      this.$modal.Hide();
    }
  },
  
}
</script>


<template>
  <teleport to="body">
    <div v-show="isModalVisible" id="modal-frame" class="the-modal" @click.self="Hide">
      <component v-bind:is="componentName"></component>
    </div>
  </teleport>
</template>

<style scoped>
.the-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.747);
  overflow-y: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}
</style>


