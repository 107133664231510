import NectoJs from '../script/lib/Necto-js';

export default {
  updated(element, binding) 
  {
    const options = binding.value;
    if(!element.nectoContext) {
      NectoJs(element, options);
      return;
    }

    element.nectoContext.RefreshDraggableChilds();
  },
  
  beforeUnmount(element)
  {
    if(!element.nectoContext) {
      return;
    }

    element.nectoContext.Destroy();
  }
}



