<script>
import TaskModel from "@/Models/Task"

import TaskStatus from "./TaskStatus";
import TaskMembersDropdown from "./TaskMembersDropdown";

export default {
  name: "TaskRow",

  components: {TaskStatus, TaskMembersDropdown},

  props: {

    index: {
      type: Number,
      default: 0,
    },

    initialTaskData: {
      type: [ Object, null, undefined ],
      default: null
    },

    nextTask: {
      type: [ Number, String, null, undefined ],
      default: null
    }
  },

  data() { return  {
    taskData: null,
    deleted: false
  }},

  computed: {
    task()
    {
      return new TaskModel().applyData(this.taskData);
    },

    id()
    {
      return this.taskData?.id || 0;
    },

    taskInfo()
    {
      return this.task?.title || this.task?.info;
    }
  },

  mounted()
  {
    this.taskData = this.initialTaskData;
  },

  methods: {
    OpenTask()
    {
      const params = {
        taskId: this.id,
        nextTask: this.nextTask
      };

      const events = {
        onDeleteTask: this.OnDelete
      };

      this.$modal.Open("TaskPreviewModal", { context: this, params, events  });
    },

    OnDelete()
    {
      this.deleted = true;
    },

    OnStatusChanged(newStatus)
    {
      this.taskData.status = newStatus;
    },
  }
}


</script>


<template>
<div class="box task-row" v-if="taskData && !deleted">

  <!-- Task Info -->
  <div class="task-col task-info hover-highlight task-inline-text" @click.left="OpenTask">
    <icon>open_in_new</icon>

    {{ taskInfo }}
  </div>

  <!-- Task Deadline -->
  <div class="task-col task-deadline hover-highlight">
    <date-input :value="task.deadline" class="input"></date-input>
  </div>

  <!-- Task Project -->
  <div class="task-col task-project-title hover-highlight task-inline-text">
    <icon>calendar_month</icon> 

    {{ task.project?.title || $lan("task_no_project_label") }}
  </div>

  <!-- Task Author -->
  <div class="task-col task-author hover-highlight " v-if="task.author_data">
    <profile-preview :user-target="task.author_data.id">
      <div class="user-container">
        <avatar :url="task.author_data.picture" :f-name="task.author_data.f_name" :s-name="task.author_data.s_name"></avatar>
        {{ task.author_data.f_name }} {{ task.author_data.s_name }}
      </div>
    </profile-preview>
  </div>

  <div class="task-col task-author hover-highlight " v-else>
    {{ $lan("user_deleted") }}
  </div>

  <!-- Task Members -->
  <div class="task-col hover-highlight task-members" v-if="task.shared_task">
    <TaskMembersDropdown :task-id="id"></TaskMembersDropdown>
  </div>

  <div class="task-col hover-highlight task-members task-author" v-else-if="task.user_data">
    <profile-preview :user-target="task.user_data.id">
      <div class="user-container">
        <avatar :url="task.user_data.picture" :f-name="task.user_data.f_name" :s-name="task.user_data.s_name"></avatar>
        {{ task.user_data.f_name }} {{ task.user_data.s_name }}
      </div>
    </profile-preview>
  </div>

  <div class="task-col hover-highlight task-members task-author" v-else>
    {{ $lan("user_deleted") }}
  </div>

  <!-- Task Status -->
  <div class="task-col expandable-col hover-highlight">
    <TaskStatus :disabled="task.isLocked()" :task-id="id" :status="task.status" @update="OnStatusChanged"></TaskStatus>
  </div>
</div>
</template>


<style scoped>
.task-row {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  flex-shrink: 0;
  gap: 25px;
  padding: 5px;
  border-radius: 15px;
  overflow: hidden;
}

.task-row > .task-col {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}



.task-row > .hover-highlight {
  border-radius: 10px;
  padding: 5px;
}

.task-row > .hover-highlight:hover {
  background-color: var(--dark-box-color);
}

.task-inline-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task-row > .expandable-col {
  flex-grow: 1;
}

.task-row > .task-info {
  display: inline-block;
  font-weight: bold;
  width: 500px;
  min-width: 300px;
  padding: 10px;
}

.task-row > .task-info > .icon {
  background-color: var(--box-color);
  font-size: large;
  position: absolute;
  display: none;
  top: 0px;
  right: 0px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}

.task-row > .task-info:hover > .icon {
  display: flex;
}

.task-row > .task-project-title {
  font-weight: bold;
  width: 300px;
  gap: 5px;
  padding: 10px;
}


.task-project-title > .icon {
  font-size: large;
}

.user-container {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
}

.user-container > .avatar-container {
  width: 35px;
  height: 35px;
}

.task-deadline {
  width: 150px;
}

.task-deadline > .input {
  border: none;
  font-weight: bold;
  padding: 5px;
  background: none;
  border-radius: 0;
}

.task-members, .task-author {
  width: 200px;
}

.task-members > .dropdown-container {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .task-row > .task-info {
    width: 100%;
  }
}
</style>



