<script>
import UserService from '@/Services/UserService';


export default {
  name: 'InputUserTag',

  empits: ['appendTag', 'removeTag'],

  props: {
    tags: {
      type: Array,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    canPickMyself: {
      type: Boolean,
      default: true
    }
  },

  data() { return {
    prevBlur: false,
    canShowPop: true,
    waitResponse: false,
    usersResult: [],
    inputValue: "",
  }},

  computed: {
    canUpdateInput()
    {
      return this.inputValue.length && this.canShowPop;
    }
  },

  methods: {
    Reset()
    {
      this.inputValue = "";
      this.usersResult = [];
      this.prevBlur = true;
    },

    disablePrevBlur()
    {
      this.prevBlur = false;
    },

    OnFocus()
    {
      this.canShowPop = true;
    },

    OnBlur(event)
    {
      if(this.prevBlur) {
        event.target.focus();
        return;
      }

      this.canShowPop = false;
    },

    OnInput(event)
    {
      let value = event.target.value;

      if(!this.canUpdateInput) {
        return;
      }
      
      this.RequestUsers(value);
    },

    RequestUsers(query)
    {
      this.usersResult = [];

      UserService.getUsersByName(query, users => {
        this.waitResponse = false;
        this.usersResult = users;
      });
    },

    OnBackspace(event)
    {
      let value = event.target.value;
      console.log(event);
      if(value)
      {
        return;
      }

      this.RemoveUserTag(this.tags.length - 1);
    },

    AppendUserTag(user)
    {
      if(!this.canPickMyself && this.$session.user.id == user.id) 
      {
        return;
      }

      if(this.IsUserTagged(user)) 
      {
        return;
      }

      this.Reset();
      this.$refs.input?.focus();
      this.$emit('appendTag', user);
    },

    RemoveUserTag(i)
    {
      if(this.disabled) 
      {
        return;
      }

      this.$emit('removeTag', i);
    },

    IsUserTagged(user)
    {
      return this.tags.find(us => user.id == us.id);
    }
  },
}
</script>

<template>
  <div class="input input-user-tag">

    <div class="input-tag" v-for="(value, i) in tags" :key="i">
      <span @click="RemoveUserTag(i)" class="tag-span material-icons">
        close
      </span>

      <span class="name-span">
        {{value.f_name}} {{value.s_name }}
      </span>
    </div>

    <input 
      ref="input" 
      :placeholder="$lan('input_user_search_placeholder')" 
      type="text" 
      v-model="inputValue" 
      @input="OnInput" 
      @focus="OnFocus"
      @blur="OnBlur"
      @keydown.backspace="OnBackspace"
      :disabled="disabled" 
    />


    <div v-show="canUpdateInput" @mousedown="prevBlur = true" v-off-bound:mousedown="disablePrevBlur" class="box query-user-lists">


      <loading-box v-if="waitResponse">
      </loading-box>

      <div class="row-user" v-if="!usersResult.length && !waitResponse">
        {{ $lan('no_results') }}
      </div>
      
      <div v-for="(value, x) in usersResult" :key="x" 
        :class="['row-user click-area', { 'row-already-tag': IsUserTagged(value) }]"
        @click="AppendUserTag(value)"
      >
        <avatar :url="value.picture" :f-name="value.f_name" :s-name="value.s_name"></avatar>
        <span>{{value.f_name}} {{value.s_name}}</span>
      </div>
    </div>

      <slot></slot>
  </div>
</template>

<style scoped>
.query-user-lists {
  position: absolute;
  width: 200px;
  border-radius: 10px;
  max-height: 250px;
  height: fit-content;
  overflow: auto;
  padding: 5px;
  z-index: 99;
  top: 100%;
}

.row-user {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 5px;
  margin-bottom: 1px;
}

.row-already-tag, .row-user:hover {
  background-color: var(--box-color-blue);
}

.avatar-container {
  width: 35px;
  height: 35px;
}

.input-user-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-start;
  align-items: center;
  position: relative;
  padding: 0;
  gap: 2px;
}

.input-user-tag input {
  background: none;
  border: none;
  flex-grow: 1;
  padding-inline: 5px;
  border-radius: 0;
}

.it-loading {
  margin: 20px;
}

.input-tag {
  height: fit-content;
  border: solid 1px var(--border-color);
  font-size: var(--normal-text);
  font-weight: bold;
  padding-right: 10px;
  border-radius: 10px;
  /* margin-right: 2px; */
  user-select: none;
}


.tag-span {
  padding: 5px;
  cursor: pointer;
  font-size: 15px;
}

.tag-span:hover {
  color: indianred;
}

.name-span {
  float: right;
  line-height: 26px;
}
</style>
