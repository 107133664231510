<script>
import ResultBox from '../Common/ResultBox.vue';
import RichTextArea from '../Common/RichTextArea.vue'

const FURLOUGH_SHORT_TYPE = "short_term";
const FURLOUGH_LONG_TYPE = "long_term";

export default {
  name: "FurloughRequest",
  components: { RichTextArea, ResultBox },

  data() { return {
    waitResponse: false,
    showStatus: false,
    success: false,

    furloughType: '',
    inputValue: '',

    startPeriod: {
      date: '',
      time: '00:00'
    },

    endPeriod: {
      date: '',
      time: '00:00'
    },
  }},

  computed: {
    canPickTime() {
      return this.furloughType == FURLOUGH_SHORT_TYPE;
    },

    canPickEndDate() {
      return this.furloughType == FURLOUGH_LONG_TYPE;
    },

    showReasons() {
      return this.furloughType == this.furloughType;
    },
  },

  methods: {
    SendRequest()
    {
      this.waitResponse = true;
      this.showStatus = true;

      const data = {
        type: this.furloughType,
        details: this.inputValue,

        start: this.startPeriod,
        end: this.endPeriod
      };

      this.$api.post({ path: 'user/furloughs/', data }, this.OnResponse);
    },

    OnResponse({response})
    {
      this.waitResponse = false;
      if(response.status != "success") {
        return;
      }

      this.success = true;
    },

    OnStartPeriodChange()
    {
      this.ValidatePeriodTimes();
    },

    ValidatePeriodTimes()
    {
      const startTimestamp = this.startPeriod.date;
      const endTimestamp = this.endPeriod.date;

      if(startTimestamp < endTimestamp && endTimestamp != 'now' && this.furloughType != FURLOUGH_SHORT_TYPE) {
        return;
      }

      this.endPeriod.date = startTimestamp;
    },

    Reset()
    {
      this.waitResponse = false;
      this.showStatus = false;
      this.success = false;

      this.furloughType = '';
      this.inputValue = '';
    }
  }
}
</script>

<template>
  <modal class="furlough-request" :title="$lan('furlough_request_modal')">
    <result-box :loading="waitResponse" :success="success" @confirm="Reset" @close="Reset" v-if="showStatus">
    </result-box>

    <form @submit.prevent="SendRequest" v-else>
      <select name="furlough" class="input" v-model="furloughType" @change="ValidatePeriodTimes" required>
        <option value="" selected disabled>
          Alege tipul
        </option>

        <option value="short_term">
          Invoire
        </option>
        <option value="long_term">
          Concediu
        </option>
      </select>

      <div class="time-box">
        <div class="field">
          <b>Start</b>
          <date-input @change="OnStartPeriodChange" v-model:value="startPeriod.date" min="now" value="now" required></date-input>
          <input v-model="startPeriod.time" type="time" :disabled="!canPickTime" required>
        </div>

        <div class="field">
          <b>End</b>
          <date-input v-model:value="endPeriod.date" :min="startPeriod.date" value="now" :disabled="!canPickEndDate" required></date-input>
          <input v-model="endPeriod.time" type="time" :disabled="!canPickTime" required>
        </div>
      </div>

      <rich-text-area class="details-input" v-model:value="inputValue" placeholder="Detalii" maxlength="500" required="true">
      </rich-text-area>

      <button class="border-btn">
        {{$lan('btn_send')}}
      </button>
    </form>
  </modal>
</template> 


<style scoped>
.furlough-request .footer {
  display: block;
}

.details-input {
  height: 200px;
}

.furlough-request form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.time-box {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.time-box .field {
  display: flex;
  flex: 1;
  gap: 2px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark-box-color);
  border-radius: 20px;
  text-align: center;
}

.time-box .field input {
  border: none;
  border-bottom: solid 1px var(--border-color);
  border-radius: 0;
  background-color: inherit;
}

.time-box .field input:last-child {
  border: none;
}

.reasons-box {
  background-color: var(--dark-box-color);
  border-radius: 20px;
}
</style>