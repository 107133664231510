<script>
import Logo from '../Logo.vue';
import DevMessage from './DevMessage.vue';
import InstallButton from './InstallButton.vue';
import NavFooter from './NavFooter.vue';
import BetaVersion from './BetaVersion.vue';
import DownloadGuide from './DownloadGuide.vue';

const routes = [
  {
    label: 'Acasă',
    icon: 'home',
    to: '/',
  },
  { 
    label: 'Proiecte',  
    icon: 'calendar_month',
    to: '/projects' 
  },
  {
    label: 'Management', 
    icon: 'space_dashboard',  
    to: '/admin/index', 
    privilege: 'manage_tasks' 
  },
  {
    label: 'Utilizatori',
    icon: 'people',
    to: '/admin/users', 
    privilege: 'manage_users' 
  },
  {
    label: 'System',
    icon: 'terminal',
    to: '/admin/system', 
    privilege: 'manage_app' 
  },
  // {
  //   label: 'Concediu & Invoire',
  //   icon: 'work_history',
  //   to: '/admin/furloughs', 
  //   privilege: 'manage_furlough' 
  // },
  {
    label: 'Grupuri',
    icon: 'groups',
    to: '/admin/groups', 
    privilege: 'manage_groups' 
  },
]

export default {
  name: "TheVerticalNavbar",
  props: ['isExpanded'],
  emits: ['toggleVerticalNav'],

  components: { Logo, DevMessage, InstallButton, NavFooter, BetaVersion, DownloadGuide },

  computed: {
    sortedRoutes()
    {
      return routes.filter(route => !route.privilege || this.$session.user.can(route.privilege));
    }
  },

  methods: {
    isSelected(route)
    {
      return this.$route.path == route;
    },

    ToggleNavbar() 
    {
      if(!this.isExpanded) {
        return;
      }

      this.$emit('toggleVerticalNav');
    },
  },
}
</script>

<template>
  <div v-if="isExpanded" class="shadow-background" @click="ToggleNavbar"></div>

  <nav class="navbar vertical-navbar" :expanded="isExpanded">
    <div class="navbar-header">
      <Logo></Logo>
    </div>

    <div class="flex-box top-buttons">
      <DevMessage></DevMessage>
      <BetaVersion></BetaVersion>
      <InstallButton></InstallButton>
      <DownloadGuide></DownloadGuide>
    </div>

    <div class="navbar-body">
      <router-link v-for="link in sortedRoutes" 
        :key="link.label" :class="['btn-nav', { 'selected' : isSelected(link.to) }]" 
        @click="ToggleNavbar()" 
        :to="link.to"
      >
        <icon>{{ link.icon }}</icon>
        <label>{{ link.label }}</label>
      </router-link>
    </div>
    <NavFooter></NavFooter>
  </nav>
</template>

<style scoped>
.vertical-navbar {
  z-index: 100;
  height: calc(100% - 60px);
  width: 250px;
  top: 60px;
  left: 0px;
  margin: 0px;
  transition: 0.2s;
  background-color: var(--bg-color);
  /* border-right: solid var(--nav-border) 1px; */
}

.vertical-navbar[expanded="true"]  {
  transform: translateX(0);
  display: fixed;
}

.btn-nav {
  display: flex;
  text-decoration: none;
  width: calc(100% - 5px);
  padding-left: 10px;
  /* gap: 10px; */
  color: var(--text-color);
  font-weight: bold;
  border-radius: 0 20px 20px 0px;
  align-items: center;
  justify-content: left;
  margin-top: 1px;
  height: 50px;
  font-size: 17px;
  user-select: none;
}

.btn-nav .icon {
  padding: 7px;
  transition-duration: 0.3s;
}

.btn-nav:hover {
  background-color: var(--box-color);
}

.selected, .selected:hover {
  background-color: var(--box-color-blue);
  color: var(--theme-color);
}

.selected .icon {
  background:  var(--theme-color);
  border-radius: 50%;
  color: white;
}

.btn-nav label {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.btn-hor-nav {
  display: none;
  float: left;
  padding: 12px;
}

.navbar-header {
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.navbar-body {
  position: relative;
  top: 10px;
  display: flex;
  flex-direction: column;
}

.vertical-navbar .app-logo {
  display: none;
}

.top-buttons {
  width: 100%;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px;
  flex-wrap: wrap;
  height: fit-content;
}



@media only screen and (max-width: 700px) 
{
  .vertical-navbar {
    overflow: hidden;
    transform: translateX(-250px);
    height: 100%;
    width: 250px;
    top: 0;
  }

  .vertical-navbar .app-logo {
    display: flex;
  }

  .btn-nav {
    overflow: hidden;
  }
}
</style>
