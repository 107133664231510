<script>
export default {
  name: "MarkDownContainer",
  emits: ['input'],

  props: { 
    content: {
      type: String,
      default: ""
    }
  },

  computed: {
    htmlContent() {
      return this.$utils.parseMarkdownText(this.content);
    }
  },

  methods: {
    OnInput(e)
    {
      this.$emit('input', e);
    }
  }
}
</script>

<template>
  <div class="markdown-container" v-html="htmlContent" @input="OnInput">
  </div>
</template>

<style>
.markdown-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.markdown-container h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

.markdown-container hr {
  width: 100%;
}

p {
  margin: 0;
}

.markdown-container blockquote {
  margin: 0px;
  padding-left: 20px;
  background-color: rgb(255, 251, 188);
  border-left: solid 5px rgb(255, 238, 0);
}

.markdown-container code {
  padding: 5px;
  border: solid 1px var(--border-color);
  background-color: var(--box-color2);
  font-size: 20px;
  border-radius: 10px;
}

.markdown-container ul, ol {
  gap: 5px;
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.markdown-container a:before {
  content: "🔗";
}

.markdown-container img {
  object-fit: scale-down;
  max-width: 100%;
}
</style>