import { apiManager } from "./ApiManager";


export default {
  GetServerClientVersion(callback)
  {
    apiManager.get({ path: 'app/client-version' }, (({response}) => {
      callback(response.data.client_version);
    }));
  },


}







