<script>
export default {

}
</script>

<template>
  <div class="disabled-feature">
    <icon>extension_off</icon>
    <h3>Acest serviciu este dezactivat temporar</h3>
    <div class="details-box">
      <slot></slot>
    </div>
  </div>
</template>



<style scoped>
.disabled-feature {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--box-color);
  z-index: 5;
  opacity: .9;
  gap: 10px;
}

.icon {
  font-size: xx-large;
}
</style>