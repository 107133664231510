<script>
// import SearchInput from '../Common/SearchInput.vue';
import GroupCreateForm from './GroupCreateForm.vue';

export default {
  // components: { SearchInput },
  name: "GroupList",
  components: {GroupCreateForm},
  emits: ['selectGroup'],

  data() { return {
    groups: [],
    hoveredHandle: -1,
    waitResponse: false,

    nectoContext: {
      contextName: "draggable-groups",
      visibleClone: true,
      restrictX: true,
      onDrop: this.OnGroupDrop
    },
  }},

  created() {
    this.RequestGroups();
  },

  methods: {
    RequestGroups()
    {
      this.waitResponse = true;
      this.$api.get({ path: "groups/" }, this.OnGroupsResponse);
    },

    RefreshGroups()
    {
      this.groups = [];
      this.RequestGroups();
    },

    OnGroupsResponse({response})
    {
      const { groups } = response.data;
      this.groups = groups;
      this.waitResponse = false;
    },

    SelectGroup(groupId)
    {
      this.$emit('selectGroup', groupId);
    },

    CanModify(index)
    {
      const group = this.groups[index];
      return this.$session.accessLevel < group.position;
    },

    DisabledRowAttr(index)
    {
      return this.CanModify(index) ? null: "";
    },

    OnGroupDrop({pickedElement, targetElement})
    {
      const group = this.groups[pickedElement.data];
      this.UpdatePosition(group.id, targetElement.data)
    },

    UpdatePosition(groupId, newPosition)
    {
      this.waitResponse = true;

      const params = {
        "new-position": newPosition
      };

      this.$api.post({ path: `groups/${groupId}:update-position`, params }, this.OnPositionResponse)
    },

    OnPositionResponse()
    {
      this.waitResponse = false;
      this.RefreshGroups();
    }
  }



}
</script>

<template>
  <GroupCreateForm @group-created="SelectGroup"></GroupCreateForm>
  <div class="group-list" v-necto-context="nectoContext">
    <!-- <div class="search-container">
      <search-input></search-input>
    </div> -->

    <loading-box v-if="waitResponse">
    </loading-box>

    <div class="group-row" v-for="(group, index) in groups" :key="index" 
      :draggable="hoveredHandle == index" 
      :disabled="DisabledRowAttr(index)"
      :data-drag-data="index"
    >
      <span class="counter">
        {{index+1}}.
      </span>

      <icon class="click-area drag-handler" @mouseenter="hoveredHandle = index" @mouseleave="hoveredHandle = -1">
        {{CanModify(index) ? 'drag_handle': 'lock'}}
      </icon>

      <label class="click-area" @click="SelectGroup(group.id)">
        {{group.name}}
      </label>
    </div>
  </div>
</template>


<style scoped>
.group-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 700px;
  overflow: auto;
  gap: 5px;
  animation: slide-right 0.2s;
  padding-bottom: 20px;
}

.group-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  background: var(--box-color);
  border: var(--solid-border);
  font-weight: bold;
}

.group-row:hover {
  background-color: var(--box-color-blue);
}

.group-row[disabled] {
  opacity: .5;
}

.group-row[disabled]:hover {
  background-color: var(--box-color);
}

.group-row span {
  width: 30px; 
  height: 30px;
  line-height: 30px;
  
  text-align: center;
  border-radius: 50%;
  /* background-color: var(--box-color); */
  /* border: var(--solid-border); */
}

.group-row:hover .counter {
  display: none;
}

.drag-handler {
  display: none;
  cursor: grab;
  font-size: larger;
}

.group-row:hover .drag-handler {
  display: initial;
}

.search-container {
  top: 1px;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--box-color);
  z-index: 2;
}

.search-container .search-input {
  background-color: var(--dark-box-color);
}

div[clone-element] {
  height: 50px;
  border: var(--theme-color) dashed 2px;
}

.loading-box {
  position: absolute;
  z-index: 5;
}
</style>