<script>
import { TaskService } from '@/Services/TaskService';
import CollapseBox from '@/components/Common/CollapseBox.vue';

let staticData = {
  waitResponse: false,

  includeMyself: false,
  newTaskId: 0,
  users: [],
  isInfoTextareaSelected: true,

  projects: [],
  targetProjectId: 0,
  shared_task: true,
  taskData: { 
    deadline: new Date().getTime() / 1000 + 604800, 
    info: "", 
    status:"", 
  },
}


export default {
  components: { CollapseBox },
  name: 'TaskCreateForm',

  data() { return staticData},

  computed: {
    isDataValid() {
      return this.taskData.info && this.taskData.deadline;
    },

    isUsersValid() {
      return this.includeMyself || this.users.length;
    },

    canCreateTask() {
      return this.isUsersValid && this.isDataValid;
    },

    slectedInfoAttr() {
      return this.isInfoTextareaSelected ? "": null;
    },

    requiredUserWarningAttr() {
      return this.isUsersValid ? null: "";
    },

    requiredFieldWarningAttr() {
      return this.isDataValid ? null: "";
    }
  },

  mounted() {
    this.waitResponse = false;
    this.ResetUsers();
    this.InitTargetUser();
  },

  methods: {
    ResetData()
    {
      this.waitResponse = false;
      this.newTaskId = 0;
      this.projects = [];
      this.targetProjectId = 0;
      this.shared_task = false;

      this.taskData = { 
        deadline: new Date().getTime() / 1000 + 604800, 
        info: "", 
        status:"", 
      };
    },

    ResetUsers()
    {
      this.users = [];
      this.includeMyself = false;
    },

    InitTargetUser()
    {
      const user = this.$pop.args.targetUser;
      if(!user) {
        return;
      }

      if(user.id == this.$session.user.id) {
        //this.includeMyself = true;
        return;
      }

      if(!this.users.find(u => u.id == user.id))
      {
        this.AppendUserTag(user);
      }
    },

    InitProjectsList()
    {
      if(this.projects.length) {
        return;
      }

      this.$api.get({ path: "projects" }, ({response}) => {
        const { data } = response;

        this.projects = data.project_list;
        this.projects.sort((first, second) => {
          return second.created - first.created;
        });
      });
    },

    CreateTask()
    {
      if(!this.isDataValid) {
        this.$msg.tpush('data_required_not_filled', "warning");
        return;
      }

      if(!this.isUsersValid) {
        this.$msg.tpush('users_required_not_chose', "warning");
        return;
      }

      this.waitResponse = true;

      TaskService.postTask({ ...this.GetFormatedData() }, (success, tasks) => {
        this.waitResponse = false;
        if(!success) {
          return;
        }

        this.ResetData();
        
        this.newTaskId = tasks[0].id;
      });

      //this.$api.post({ context: this, path: `tasks/?include_myself=${this.includeMyself}&shared_task=${this.shared_task}`, data: this.GetFormatedData() }, OnTaskReceive);
    },

    GetFormatedData()
    {
      let formatedData = {
        ...this.taskData,
        'isShared': this.shared_task,
        'includeAuthor': this.includeMyself,
        'project': this.targetProjectId,
        'users': []
      }

      for(let i in this.users) {
        formatedData.users.push(this.users[i].id);
      }

      return formatedData;
    },

    GetUsers()
    {
      let userIds = [];
      this.users.forEach(user => {
        userIds.push(user.id);
      });

      return userIds;
    },

    AppendUserTag(user)
    {
      this.users.push(user);
    },

    RemoveUserTag(index)
    {
      this.users.splice(index, 1);
    },

    Confirm()
    {
      this.Close();
      window.location.href = `/?task_id=${this.newTaskId}`;
      this.ResetData();
    },

    Close()
    {
      this.$pop.Hide();
    },

    ExpandInfoTextarea()
    {
      this.isInfoTextareaSelected = true;
    },

    ShrinkInfoTextarea()
    {
      this.isInfoTextareaSelected = false;
    },
    
  },
}

</script>

<template>
  <div class="popup-box">
    <div class="title-bar header"> 
        <h2>Task nou</h2>
        
        <icon-btn class="red" @click="Close">
          close
        </icon-btn>
    </div>

    <result-box @confirm="Confirm" @close="ResetData" :success="Boolean(newTaskId)" :loading="waitResponse" v-if="waitResponse || newTaskId">
    </result-box>

    <div class="body" v-else>
      <div class="row" :required="requiredUserWarningAttr">
        <input-user-tag
          :tags="users" 
          :canPickMyself="false"
          @append-tag="AppendUserTag" 
          @remove-tag="RemoveUserTag" 

          class="user-input"
        />
      </div>

      <div class="row" v-if="$session.user.can('manage_projects')">
        <select class="input" style="width: 100%;" v-model="targetProjectId" @click="InitProjectsList">
          <option value="0" selected>Selectează un proiect</option>
          <option :value="proj.id" v-for="proj in projects" :key="proj">{{proj.title}}</option>
        </select>
      </div>

      <div class="row">
        <rich-text-area class="info-textarea" :label="$lan('task_header_info_title')" :placeholder="$lan('task_header_info_title')"
          v-model:value="taskData.info"
          maxlength="50000" 
          :selected="slectedInfoAttr"
        />
      </div>
    </div>

    <div class="footer" v-if="!waitResponse && !newTaskId">
      <div class="deadline-date" v-tooltip:top="$lan('task_header_deadline_title')">
        <date-input class="deadline-date" min="now" v-model:value="taskData.deadline"></date-input>
      </div>

      <collapse-box>
        <template #header>
          <button class="expand-options-btn no-bg border">
            {{$lan('task_create_option_expand_btn')}}
          </button>
        </template>

        <div class="task-options">
        <check-box :required="requiredUserWarningAttr" class="row self-check-btn" :checked="includeMyself" @check="includeMyself = true" @unCheck="includeMyself = false">
          {{$lan('task_create_option_assign_to_me')}}
        </check-box>

        <check-box :required="requiredUserWarningAttr" class="row self-check-btn" :checked="shared_task" @check="shared_task = true" @unCheck="shared_task = false">
          {{$lan('task_create_option_shared')}}
        </check-box>
      </div>
      </collapse-box>


      <div class="buttons">

        <button class="red no-bg" @click="ResetData">
          <icon>close</icon> {{$lan('btn_reset')}}
        </button>

        <button class="blue border-btn" @click="CreateTask" :disabled="!canCreateTask">
          <icon>send</icon> {{$lan('btn_send')}}
        </button>
      </div>

    </div>
  </div>
</template>

<style scoped>
@import url('../../../../style/Popup.css');

.self-check-btn {
  padding: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: fit-content;
  border-radius: 20px;
  height: 100%;
}

.info-textarea {
  width: 100%;
  min-height: 150px;
  margin-block: 20px;
  font-size: var(--normal-text);
  border-color: var(--border-color);
  /* border: none; */
  /* background-color: var(--dark-box-color); */
}

.info-textarea .placeholder {
  font-size: var(--large-text);
  font-weight: bold;
}

.self-check-btn:hover {
  background-color: var(--box-color-blue);
}

.collapse-box {
  width: 100%;
  padding: 0;
}

.expand-options-btn {
  width: 100%;
  text-align: left;
  justify-content: left;
  /* border-radius: 0; */
  font-weight: normal;
}

.task-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.task-options .checkbox-body {
  width: 100%;
  justify-content: left;
}

.user-input {
  border: none;
}
</style>
