
<script>
export default {
  name: "DownloadGuide",

  methods: {

    DownloadGuide() 
    {
      window.open("https://docs.google.com/document/d/103XDxSt1fNw9Y0Du1O4rkqNOYABfdXe3/edit?usp=sharing&ouid=110997526579843576055&rtpof=true&sd=true");
    },
  }
}
</script>

<template>
  <button @click="DownloadGuide" class="red border-btn download-btn">
    Ghid de utilizare
    <icon>file_present</icon>
  </button>
</template>

<style scoped>
.download-btn {
  width: fit-content; 
  border-radius: 10px; 
  /* font-size: 20px; */
  border-radius: var(--border-radius);
}

.download-btn .icon {
  font-size: large;
}
</style>