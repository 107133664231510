import { globalState } from "./GlobalState";
import { apiManager } from "./ApiManager";


export default {
  Init() 
  {
    this.RequestApplicationConfig();
  },

  ToggleConfig(fieldName)
  {
    const params = {
      field: fieldName
    };

    apiManager.patch({ path: 'app/config', params }, (({response}) => this.OnToggleResponse(response)));
  },

  OnToggleResponse(response)
  {
    console.log(response);
    const { fieldName, value } = response.data;
    globalState.config[fieldName] = value;
  },

  RefreshApplicationConfig()
  {
    globalState.config = {};
    this.RequestApplicationConfig();
  },

  RequestApplicationConfig()
  {
    apiManager.get({ path: 'app/config' }, (({response}) => this.OnConfigLoad(response)));
  },

  OnConfigLoad(response)
  {
    const { config } = response.data;
    globalState.config = config;
  }

}







