import VueCookies from "vue-cookies";

export const constants = {
  title: "OTask",

  api: 'https://task.orientceramic.ro/api',
  // api: 'http://127.0.0.1:5001',

  log: false,
  api_timeout: 15000, // ms 
  
  client_hash_code: "f22abd6773ab232869321ad4b1e47ac0c908febf4f3a2bd10c8066140f741261",
  beta: false,
  ver: "4.0.0",

  copyright: "© 2021 - Prezent John Criste",
  
  footer_links: [
    // {
    //   label: "GitHub",
    //   href: "https://github.com/johnl28"
    // },
    // {
    //   label: "Linkedin",
    //   href: "https://www.linkedin.com/in/ionut-criste-a22557212/"
    // },
    // {
    //   label: "Feedback",
    //   href: "mailto:ionutcriste28@gmail.com"
    // },
  ],

}


function log(text)
{
  if (!constants.log) { 
    return; 
  }

  console.log(`[APP LOG]: ${text}`);
}


export default {
  install(app)
  {
    var props = app.config.globalProperties;
    props.$cookies = VueCookies;
    props.$const = constants;
    props.$log = log;
  },
}





