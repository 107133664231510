
const translateMgr = {
  table: {},

  LoadFile(fileName)
  {
    fetch(fileName)
    .then((response) => response.json())
    .then((json) => {
      Object.assign(this.table, json)
    })
    .catch((error) => {
      console.error(error);
    });
  }

}

translateMgr.LoadFile('/data/translate.ro.json');

export const translateTable = new Proxy(translateMgr.table, {
  get(target, prop) 
  {
    if(prop in target) 
    {
      return target[prop];
    }

    return `Undefined translate key "${prop}"`;
  }
});


export function TranslateByKey(key)
{
  return translateTable[key];
}


export default {
  install(app)
  {
    app.config.globalProperties.$lan = TranslateByKey;
    app.config.globalProperties.$trl = translateTable;
  },
}



