<script>
export default {
  name: "SearchInput",
  emits: ['update:value', 'input', 'clear', 'blur', 'focus'],

  props: {
    placeholder: {
      type: String,
      default: "Search..",
    },

    value: {
      type: String,
      default: ""
    },

    maxlength: {
      type: [String, Number],
      default: null
    },

  },

  watch: {
    value(newVal) {
      this.inputString = newVal;
    },
  },


  data() { return {
    inputString: "",
    focused: false,
  }},

  computed: {
    inputLength() {
      return this.inputString.length;
    },

    inputReference() {
      return this.$refs.input;
    },

    focusedAttr() {
      return this.focused ? "": null;
    }
  },

  methods: {
    Focus() {
      if(!this.inputReference) {
        return;
      }

      this.inputReference.focus();
    },

    OnUpdate(e) {
      this.UpdateValueProp();
      this.$emit('input', e);
    },

    OnFocus(e)
    {
      this.focused = true;
      this.$emit('focus', e);
    },

    OnBlur(e)
    {
      this.focused = false;
      this.$emit('blur', e);
    },

    ClearInput(e) {
      this.inputString = "";
      this.UpdateValueProp();
      this.$emit('clear', e);
    },

    UpdateValueProp()
    {
      this.$emit('update:value', this.inputString);
    },
  },
}
</script>

<template>
  <div class="search-input" @click="Focus" :focused="focusedAttr">

    <icon>search</icon>

    <input spellcheck="false" 
      @focus="OnFocus"
      @blur="OnBlur"
      @input="OnUpdate"
      v-model="inputString" 
      ref="input" 
      :placeholder="placeholder" 
      :maxlength="maxlength"
    />

    <icon-button class="clear-btn red no-bg" v-show="inputLength" @click="ClearInput">
      close
    </icon-button>
  </div>

</template>

<style scoped>
.search-input {
  display: flex;
  position: relative;
  border: solid 1px var(--border-color);
  border-radius: 20px;
  align-items: center;
  padding-left: 10px;
  user-select: none;
  width: 100%;
} 

.search-input input {
  border: none;
  background: none;
  width: 100%;
}

.clear-btn {
  visibility: hidden;
  font-size: medium;
  margin-right: 5px;
}

.search-input:hover .clear-btn, .search-input[focused] .clear-btn {
  visibility: initial;
}
</style>